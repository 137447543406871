import { AutoCompleteResult } from '@/models/AddressComplete.ts';
import { InsuranceType } from '@/models/forms/backend-types/form-template/FormTemplate.ts';
import { Select, TextInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { FC, useMemo } from 'react';

import AddressAutocomplete from '@/components/AddressAutocomplete.tsx';
import OverlaySection from '@/components/OverlaySection.tsx';

import useEnrichedPractitionerData from '@/hooks/useEnrichedPractitionerData.ts';

import Form, { Props as FormProps } from './Form.tsx';
import FormSection from './FormSection.tsx';

export type PatientFormData = {
    cost_center: InsuranceType;
    first_name: string;
    last_name: string;
    date_of_birth: Date;
    street: string;
    house_number: string;
    postal_code: number;
    city: string;
    email: string;
    phone: string;
    practice: string;
};

type Props = Pick<FormProps<PatientFormData>, 'initialValues' | 'handleSubmit'>;

// TODO: use for animation
// enum Step {
//     PERSONAL_INFO = 'personal info',
//     ADDRESS = 'address',
//     CONTACT = 'contact',
//     COST_SELECTOR = 'cost selector',
// }

const insurance_options = [
    {
        value: InsuranceType.PUBLIC,
        label: 'GKV',
    },
    {
        value: InsuranceType.PRIVATE,
        label: 'PKV',
    },
    {
        value: InsuranceType.SELF_PAYER,
        label: 'SZ',
    },
];

const PatientDataForm: FC<Props> = ({ initialValues, handleSubmit }) => {
    // All structured enriched data un filtered.
    const { data: enriched_practitioner_data } = useEnrichedPractitionerData();

    const practice_options = useMemo(
        () =>
            enriched_practitioner_data?.practices.map((practice) => ({
                value: practice.doc_cirrus.id,
                label: practice.doc_cirrus.name,
            })) ?? [],
        [enriched_practitioner_data]
    );

    return (
        <Form initialValues={initialValues} handleSubmit={handleSubmit}>
            {(form) => (
                <div className="flex flex-col gap-5 pt-4">
                    <OverlaySection className="items-start gap-2">
                        <FormSection
                            classNames={{
                                title: 'text-lg font-bold',
                                // wrapper: cn(
                                //     'gap-y-3 transition duration-500',
                                //     [Step.COST_SELECTOR].includes(active_step) && 'pointer-events-none opacity-0'
                                // ),
                                wrapper: 'w-full gap-y-3',
                            }}
                            title="Persönliche Informationen"
                        >
                            <TextInput
                                label="Vorname"
                                placeholder="Vorname"
                                key={form.key('first_name')}
                                {...form.getInputProps('first_name')}
                            />
                            <TextInput
                                label="Nachname" // TODO: translation
                                placeholder="Nachname" // TODO: translation
                                key={form.key('last_name')}
                                {...form.getInputProps('last_name')}
                            />
                            <DatePickerInput
                                label="Geburtsdatum" // TODO: translation
                                valueFormat="DD.MM.YYYY"
                                maxDate={new Date()}
                                key={form.key('date_of_birth')}
                                {...form.getInputProps('date_of_birth')}
                            />
                        </FormSection>
                    </OverlaySection>
                    <OverlaySection className="items-start gap-2">
                        <FormSection
                            classNames={{
                                title: 'pb-1 text-lg font-bold',
                                // wrapper: cn(
                                //     'gap-y-3 transition duration-500',
                                //     [Step.COST_SELECTOR].includes(active_step) && 'pointer-events-none opacity-0'
                                // ),
                                wrapper: 'w-full gap-y-3',
                                firstChild: 'flex-[0_0_100%]',
                            }}
                            title="Adresse"
                        >
                            <AddressAutocomplete
                                form={form}
                                onSelect={(ac_result: AutoCompleteResult) => {
                                    // TODO: cleanup
                                    form.setFieldValue(
                                        'street',
                                        `${ac_result.address.road ?? ac_result.address.name}${ac_result.address.house_number ? ` ${ac_result.address.house_number}` : ''}`
                                    );
                                    if (ac_result.address.house_number) {
                                        form.setFieldValue('house_number', ac_result.address.house_number);
                                    }
                                    form.setFieldValue('postal_code', parseInt(ac_result.address.postcode, 10));
                                    form.setFieldValue('city', ac_result.address.city);
                                }}
                            />
                            <TextInput
                                label="Postleitzahl" // TODO: translation
                                placeholder="Postleitzahl" // TODO: translation
                                key={form.key('postal_code')}
                                {...form.getInputProps('postal_code')}
                            />
                            <TextInput
                                label="Stadt"
                                placeholder="Stadt"
                                key={form.key('city')}
                                {...form.getInputProps('city')}
                            />
                        </FormSection>
                    </OverlaySection>
                    <OverlaySection className="items-start gap-2">
                        <FormSection
                            classNames={{
                                title: 'pb-1 text-lg font-bold',
                                wrapper: 'w-full gap-y-3',
                            }}
                            title="Kontakt"
                        >
                            <TextInput
                                label="Email" // TODO: translation
                                placeholder="Email" // TODO: translation
                                key={form.key('email')}
                                {...form.getInputProps('email')}
                            />
                            <TextInput // TODO: make it telephone number
                                label="Telefonnummer"
                                placeholder="+49 0123456789"
                                key={form.key('phone')}
                                {...form.getInputProps('phone')}
                            />
                        </FormSection>
                    </OverlaySection>
                    <OverlaySection className="items-start gap-2">
                        <FormSection
                            classNames={{
                                title: 'pb-1 text-lg font-bold',
                                wrapper: 'w-full gap-y-3',
                            }}
                            title="Kostenträger"
                        >
                            <Select
                                label="Betriebsstätte" // TODO: translation
                                key={form.key('practice')}
                                data={practice_options}
                                {...form.getInputProps('practice')}
                            />
                            <Select
                                label="Kostenträger" // TODO: translation
                                key={form.key('cost_center')} // TODO: translation
                                data={insurance_options}
                                allowDeselect={false}
                                {...form.getInputProps('cost_center')}
                            />
                        </FormSection>
                    </OverlaySection>
                </div>
            )}
        </Form>
    );
};

export default PatientDataForm;
