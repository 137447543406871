import { Calendar, ConciergeBell } from 'lucide-react';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import NavLink from './NavLink.tsx';

const HeaderNavigation: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const routes = [
        {
            label: 'Termine',
            icon: ConciergeBell,
            path: '/',
        },
        {
            label: 'Kalender',
            icon: Calendar,
            path: '/calendar-view',
        },
        // {
        //     label: 'Aufgaben',
        //     icon: List,
        //     path: '/list-view',
        // },
    ];

    return (
        <div className="flex items-center gap-8">
            {routes.map(({ label, path, icon: Icon }) => (
                <NavLink
                    onClick={() => navigate(path)}
                    active={location.pathname === path}
                    key={path}
                    leftSection={<Icon className="size-5" />}
                    label={label}
                />
            ))}
        </div>
    );
};

export default HeaderNavigation;
