import { NotificationStyleType, NotificationType } from '@/models/Notification.ts';
import { NotificationData, notifications } from '@mantine/notifications';

import { useAuthStore } from '@/stores/authStore.ts';
import { useNotificationStore } from '@/stores/notificationStore.ts';

const SUCCESS_NOTIFICATION_DURATION = 5000;
const WARNING_NOTIFICATION_DURATION = 4000;

export const notification_style: Record<NotificationType, NotificationStyleType> = {
    [NotificationType.SUCCESS]: {
        autoClose: SUCCESS_NOTIFICATION_DURATION,
        withCloseButton: true,
        loading: false,
    },
    [NotificationType.WARNING]: {
        color: 'yellow',
        withCloseButton: true,
        loading: false,
        autoClose: WARNING_NOTIFICATION_DURATION,
    },
    [NotificationType.ERROR]: {
        color: 'red',
        withCloseButton: true,
        loading: false,
    },
    [NotificationType.LOADING]: {
        loading: true,
        withCloseButton: false,
        autoClose: false,
    },
};

export const showSuccessNotification = (message: string, title?: string) => {
    const notification_id: string = notifications.show({
        message: message,
        title: title,
        ...notification_style[NotificationType.SUCCESS],
    });
    logNotification(title ?? '', message, NotificationType.SUCCESS);
    return notification_id;
};

export const showWarningNotification = (message: string, title?: string) => {
    const notification_id: string = notifications.show({
        message: message,
        title: title,
        ...notification_style[NotificationType.WARNING],
    });
    logNotification(title ?? '', message, NotificationType.WARNING);
    return notification_id;
};

export const showErrorNotification = (message: string, title?: string) => {
    const notification_id: string = notifications.show({
        message: message,
        title: title,
        ...notification_style[NotificationType.ERROR],
    });
    logNotification(title ?? '', message, NotificationType.ERROR);
    return notification_id;
};

export const showLoadingNotification = (message: string, title?: string) => {
    const notification_id: string = notifications.show({
        message: message,
        title: title,
        ...notification_style[NotificationType.LOADING],
    });
    logNotification(title ?? '', message, NotificationType.LOADING);
    return notification_id;
};

export const updateNotification = (type: NotificationType, data: NotificationData) => {
    if (!data.message) return;
    notifications.update({ ...data, ...notification_style[type] });
    logNotification(data.title?.toString() ?? '', data.message.toString(), type);
};

export const hideNotification = (notification_id: string) => {
    notifications.hide(notification_id);
};

export const logNotification = (title: string, message: string, type: NotificationType) => {
    const user_email: string = useAuthStore.getState().user_email;
    if (!user_email) return;

    useNotificationStore.getState().addNotification(user_email, {
        title: title,
        message: message,
        type: type,
        timestamp: Date.now(),
    });
};
