import { api } from '@/lib/api-client.ts';
import { CreatePatient } from '@/models/Patient.ts';

import { getCustomerId, getInstanceId } from '@/utils/customerUtils.ts';

import { ServiceUrl } from './ServiceURL.ts';

export const getUserById = async (user_id: string) => {
    const config = {
        headers: {
            customer_id: getCustomerId(),
        },
        params: {
            instance_id: getInstanceId(),
        },
    };

    try {
        const response = await api.get(
            `${ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.DOC_CIRRUS.GET_USER}/${user_id}`,
            config
        );
        return response.data;
    } catch (error) {
        console.debug(error);
        return null;
    }
};

export const createUser = async (patientData: CreatePatient, token: string) => {
    const config = {
        params: {
            instance_id: getInstanceId(),
        },
        headers: {
            customer_id: getCustomerId(),
            Authorization: token,
        },
    };

    try {
        const response = await api.post(
            ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.DOC_CIRRUS.CREATE_USER,
            patientData,
            config
        );
        return response.data.user_id;
    } catch (error) {
        console.debug(error);
        throw error;
    }
};
