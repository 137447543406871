import { FC } from 'react';

import Paragraph from '@/components/text/Paragraph.tsx';

import { cn } from '@/utils/styleUtils.ts';

interface Props {
    text: string;
    classNames?: {
        container?: string;
        text?: string;
    };
}

const Unavailable: FC<Props> = ({ text, classNames }) => (
    <div className={cn('rounded bg-slate-50 p-4', classNames?.container)}>
        <Paragraph className={cn('text-center text-slate-400', classNames?.text)}>{text}</Paragraph>
    </div>
);

export default Unavailable;
