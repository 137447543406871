import { AppointmentTypeFormOption } from '@/models/appointment/BookingFormOptions.ts';
import { CloseButton, Combobox, FocusTrap, Input, InputWrapper, useCombobox } from '@mantine/core';
import { FC, useEffect, useState } from 'react';

import { cn } from '@/utils/styleUtils.ts';

interface Props {
    data: AppointmentTypeFormOption[];
    defaultAptTypeIdOrScheduleTypeId?: string;
    label: string;
    placeholder?: string;
    setSelectedAppointmentTypeId: (value: string | undefined) => void;
    disabled?: boolean;
    autoFocusActive: boolean;
}

export const CustomAppointmentTypeSelect: FC<Props> = ({
    data,
    defaultAptTypeIdOrScheduleTypeId,
    label,
    setSelectedAppointmentTypeId,
    placeholder,
    disabled,
    autoFocusActive = false,
}) => {
    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
    });

    const [value, setValue] = useState<string | undefined>('');
    const [search, setSearch] = useState<string>('');
    const [filtered_groups, setFilteredGroups] = useState<AppointmentTypeFormOption[]>([]);
    const [total_options, setTotalOptions] = useState<number>(0);
    const [is_offered, setIsOffered] = useState<boolean | undefined>();

    const getAppointmentById = (
        data: AppointmentTypeFormOption[],
        id: string
    ): AppointmentTypeFormOption | undefined => {
        return data.find((a) => a.value === id);
    };

    const getAppointmentByName = (
        data: AppointmentTypeFormOption[],
        name: string
    ): AppointmentTypeFormOption | undefined => {
        return data.find((a) => a.label === name);
    };

    // Initialize value, needs to be updated only if data or default type change.
    useEffect(() => {
        if (search) {
            // data might change and we need to ensure previously selected type still exist
            // otherwise reset and ask for a new appointment type
            const matched_appointment = getAppointmentByName(data, search);
            if (!matched_appointment) {
                // Provide feedback -> needs to change search
                setSearch('');
                setValue(undefined);
                setIsOffered(undefined);
                setSelectedAppointmentTypeId(undefined);
            }
        } else if (defaultAptTypeIdOrScheduleTypeId) {
            // Set default value in case there is a default value and nothing is selected yet.
            const matched_appointment = getAppointmentById(data, defaultAptTypeIdOrScheduleTypeId);

            if (matched_appointment) {
                setSearch(matched_appointment.label);
                setValue(matched_appointment.label);
                setIsOffered(matched_appointment.is_offered);
                setSelectedAppointmentTypeId(matched_appointment.value);
            }
        }
    }, [data]);

    // Search into calendar name or practitioner when only 1 calendar is available
    useEffect(() => {
        const new_filtered_groups = data.reduce<AppointmentTypeFormOption[]>((acc, apt_or_schedule) => {
            if (apt_or_schedule.label.toLowerCase().includes(search.toLowerCase().trim())) {
                acc.push(apt_or_schedule);
            }

            return acc; // Return the accumulator
        }, []);

        setFilteredGroups(new_filtered_groups);
        setTotalOptions(new_filtered_groups.length);
    }, [data, search, defaultAptTypeIdOrScheduleTypeId]);

    const sortGroups = (a: AppointmentTypeFormOption, b: AppointmentTypeFormOption): number => {
        if (a.is_offered === b.is_offered) return 0;
        return a.is_offered ? -1 : 1;
    };

    // TODO: memo when logic is fixed
    const groups = () => {
        return (
            filtered_groups
                // Order by offered and not offered
                .sort((a, b) => sortGroups(a, b))
                .map((a) => (
                    <Combobox.Option classNames={{ option: 'ml-2' }} value={a.value} key={a.value}>
                        <div className={cn('text-sm', { 'text-slate-500': !a.is_offered })}>{a.label}</div>
                    </Combobox.Option>
                ))
        );
    };

    return (
        <FocusTrap active={autoFocusActive}>
            <InputWrapper
                label={label}
                error={is_offered == false ? 'Not Offered' : ''}
                classNames={{
                    error: 'text-orange-600',
                }}
            >
                <Combobox
                    classNames={{
                        groupLabel: 'text-sm font-semibold text-black',
                    }}
                    store={combobox}
                    withinPortal={false}
                    onOptionSubmit={(id) => {
                        const apt_selected = filtered_groups.find((a) => a.value === id);
                        setSelectedAppointmentTypeId(id);
                        setValue(apt_selected?.label);
                        setSearch(apt_selected?.label ?? '');
                        setIsOffered(apt_selected?.is_offered);
                        combobox.closeDropdown();
                    }}
                >
                    <Combobox.Target>
                        <Input
                            classNames={{
                                input: cn(is_offered == false && 'border-orange-600'),
                            }}
                            disabled={disabled}
                            // this was causing error but in case something dont work properly, this is a backup
                            // defaultValue={value}
                            value={search}
                            onChange={(event) => {
                                combobox.openDropdown();
                                combobox.updateSelectedOptionIndex();
                                setSearch(event.currentTarget.value);
                            }}
                            onClick={() => combobox.openDropdown()}
                            onFocus={() => combobox.openDropdown()}
                            onBlur={() => {
                                combobox.closeDropdown();
                                setSearch(value || '');
                            }}
                            placeholder={placeholder}
                            rightSection={
                                value !== null ? (
                                    <CloseButton
                                        disabled={disabled}
                                        size="sm"
                                        onMouseDown={(event) => event.preventDefault()}
                                        onClick={() => {
                                            setValue(undefined);
                                            setSelectedAppointmentTypeId(undefined);
                                            setSearch('');
                                            setIsOffered(undefined);
                                            combobox.closeDropdown();
                                        }}
                                        aria-label="Clear value"
                                    />
                                ) : (
                                    <Combobox.Chevron />
                                )
                            }
                            rightSectionPointerEvents={value === null ? 'none' : 'all'}
                        />
                    </Combobox.Target>

                    <Combobox.Dropdown className="shadow-sm">
                        <Combobox.Options mah={200} style={{ overflowY: 'auto', separator: { display: 'none' } }}>
                            {total_options > 0 ? groups() : <Combobox.Empty>Nothing found</Combobox.Empty>}
                        </Combobox.Options>
                    </Combobox.Dropdown>
                </Combobox>
            </InputWrapper>
        </FocusTrap>
    );
};
