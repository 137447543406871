import { Button, Input, createTheme } from '@mantine/core';

// TODO: simplify
const eterno: [string, string, string, string, string, string, string, string, string, string] = [
    '#88E59C',
    '#88E59C',
    '#88E59C',
    '#88E59C',
    '#88E59C',
    '#88E59C',
    '#88E59C',
    '#88E59C',
    '#88E59C',
    '#88E59C',
];

export const theme = createTheme({
    fontFamily: 'MessinaSans-Regular, sans-serif',
    headings: {
        fontFamily: 'MessinaSans-Regular, sans-serif',
    },
    colors: {
        eterno: eterno,
    },
    primaryColor: 'eterno',
    primaryShade: 9,
    defaultRadius: '0.375rem',
    components: {
        Input: Input.extend({
            classNames: {
                input: 'bg-slate-100 disabled:border-transparent disabled:text-slate-400 disabled:opacity-100 disabled:bg-slate-100',
            },
        }),
        Button: Button.extend({
            classNames: {
                root: 'text-font hover:text-black py-3 px-6 h-auto',
            },
        }),
    },
});
