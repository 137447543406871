import { GetAppointmentsForTodayDto } from '@/models/appointment/GetAppointmentsForTodayDto.ts';
import { useMemo } from 'react';
import useSWR from 'swr';

import useConfig from '@/hooks/useConfig.ts';

import { fetchAppointmentsForDate } from '@/services/appointmentService.ts';

import { useFilterStore } from '@/stores/filterStore.ts';

const useAppointmentsForDate = (): { data?: GetAppointmentsForTodayDto; is_loading: boolean; mutate: () => void } => {
    const { customer_id, instance_id: doc_cirrus_instance_id } = useConfig();
    const { date: selected_date } = useFilterStore();

    const cache_key = useMemo(() => {
        return `${selected_date?.toLocaleDateString() ?? 'todays'}-appointment-cached-data-by-customer-id-${customer_id}-dc-instance-id-${doc_cirrus_instance_id}`;
    }, [selected_date, customer_id, doc_cirrus_instance_id]);

    const fetchTodaysAppointmentData = async (
        customer_id: string,
        dc_instance_id: string,
        selected_date: Date | null
    ): Promise<GetAppointmentsForTodayDto> => {
        const raw_data: GetAppointmentsForTodayDto = await fetchAppointmentsForDate(
            customer_id,
            dc_instance_id,
            selected_date
        );
        return raw_data;
    };

    const {
        data,
        isLoading: is_loading,
        mutate,
    } = useSWR<GetAppointmentsForTodayDto>(
        cache_key,
        () => fetchTodaysAppointmentData(customer_id, doc_cirrus_instance_id, selected_date),
        {
            revalidateOnFocus: false,
            refreshInterval: 30000,
        }
    );

    return { data: data, is_loading: is_loading, mutate: mutate };
};

export default useAppointmentsForDate;
