import { Appointment } from '@/models/appointment/Appointment.ts';
import { CheckinStatus, CheckoutStatus } from '@/models/call-system/models/CallSystemUser.ts';
import { Button, ComboboxData, Divider, Select, Switch } from '@mantine/core';
import { FileScan, Mail, Printer as PrinterIcon, QrCode } from 'lucide-react';
import { FC, useMemo, useState } from 'react';

import ConditionalTooltip from '@/components/ConditionalTooltip.tsx';
import IconButton from '@/components/IconButton.tsx';
import DocumentExchange from '@/components/PatientModal/DocumentExchange.tsx';

import useAppointmentsForDate from '@/hooks/useAppointmentsForDate.ts';
import useCheckInData from '@/hooks/useCheckInData.ts';
import useConfig from '@/hooks/useConfig.ts';
import useEnrichedPractitionerData from '@/hooks/useEnrichedPractitionerData.ts';
import { usePatientAppointmentRequiredForms } from '@/hooks/usePatientAppointmentRequiredForms.ts';

import {
    checkin,
    checkoutAppointment,
    prepareAndCheckinArrived,
    updateAppointmentRoom,
} from '@/services/appointmentService.ts';
import { getCodeByAppointment } from '@/services/formDataService.ts';
import { showErrorNotification } from '@/services/notificationService.ts';

import { useAppointmentCheckinStore } from '@/stores/appointmentCheckinStore.ts';

import {
    getRoomsSelection,
    isCheckedIn,
    isCheckedOut,
    isCheckinArrived,
    isCheckinPending,
} from '@/utils/appointmentUtils.ts';
import { isToday } from '@/utils/dateUtils.ts';
import { hasFormsToFill } from '@/utils/formUtils.ts';
import { openConfirmationModal, openQrCodeModal } from '@/utils/modalUtils.tsx';

import PatientAppointmentForms from './PatientAppointmentForms.tsx';

interface Props {
    appointment: Appointment;
}

const PatientAppointmentActions: FC<Props> = ({ appointment }) => {
    const { mutate: mutateAppointmentsDate } = useAppointmentsForDate();
    const [show_checkin_buttons, setShowCheckinButtons] = useState(isToday(appointment.day));
    const [selected_room, setSelectedRoom] = useState(appointment.links.room_id);
    const [is_qr_code_loading, setIsQrCodeLoading] = useState(false);
    const { selected_location } = useConfig();

    const { getAppointmentCheckinData, mutate: mutateCheckinData } = useCheckInData();
    const appt_checkin_data = getAppointmentCheckinData(appointment);
    const checkin_status = appt_checkin_data?.appointment?.checkin_status;
    const checkout_status = appt_checkin_data?.appointment?.checkout_status;
    const rooms = selected_location.config?.rooms ?? [];
    const rooms_selection: ComboboxData = useMemo(
        () => getRoomsSelection(rooms, checkin_status),
        [rooms, checkin_status, checkout_status]
    );

    const {
        appointments: appointments_checked_in,
        setConfigAutoCheckin,
        is_config_auto_checkin,
        setAppointmentCheckingIn,
    } = useAppointmentCheckinStore();

    const { is_loading: is_loading_prac, data: enriched_practitioner_data } = useEnrichedPractitionerData();

    const is_call_system_active = selected_location.config.is_patient_call_system_active;
    const { formatted_forms_for_appointment, is_loading: forms_loading } =
        usePatientAppointmentRequiredForms(appointment);

    const is_room_selection_disabled = isCheckedOut(checkout_status) || !isToday(appointment.day);

    const is_cta_disabled = !selected_room || checkout_status === CheckoutStatus.CHECKED_OUT;

    const handleCta = async () => {
        setAppointmentCheckingIn(appointment.id, true);

        if (isCheckedIn(checkin_status)) {
            if (!appt_checkin_data) return;
            await checkoutAppointment(appointment, appt_checkin_data?.number || '');
            setAppointmentCheckingIn(appointment.id, false);
            mutateAppointmentsDate();
            await mutateCheckinData();
        }

        if (isCheckinPending(checkin_status)) {
            const onSuccess = () => {
                setAppointmentCheckingIn(appointment.id, false);
                mutateAppointmentsDate();
                mutateCheckinData();
            };
            const onFail = () => {
                setAppointmentCheckingIn(appointment.id, false);
            };
            await prepareAndCheckinArrived(
                appointment,
                selected_room,
                formatted_forms_for_appointment,
                enriched_practitioner_data?.practitioners.find((prac) => prac.id === appointment.professional.id),
                onSuccess,
                onFail
            );
        }

        if (checkin_status === CheckinStatus.ARRIVED) {
            if (!appt_checkin_data) return;
            const update_room_status = await updateAppointmentRoom(appointment, selected_room);
            if (update_room_status) {
                await checkin(appointment, appt_checkin_data.number);
            }
            setAppointmentCheckingIn(appointment.id, false);
            mutateAppointmentsDate();
            await mutateCheckinData();
        }
    };

    const handleShowQr = async () => {
        if (!hasFormsToFill(formatted_forms_for_appointment)) {
            return;
        }

        setIsQrCodeLoading(true);
        const code = await getCodeByAppointment(
            appointment.id,
            appointment.user_id,
            appointment.location_key,
            appointment.sub_loc_key
        );
        if (code) {
            openQrCodeModal(code);
        } else {
            showErrorNotification('No qr code generated yet');
        }
        setIsQrCodeLoading(false);
    };

    const getCtaText = () => {
        if (isCheckedOut(checkout_status)) {
            return 'Folgetermin hinzufügen';
        }

        if (
            isCheckinArrived(checkin_status) ||
            (isCheckinPending(checkin_status) && !hasFormsToFill(formatted_forms_for_appointment))
        ) {
            return 'Patient:in bestätigen'; // Actual 'Checked in' status
        }

        if (isCheckinPending(checkin_status)) {
            return `Patient:in ${is_config_auto_checkin ? '' : 'manuell '}aufnehmen`; // Mark as arrived
        }

        if (isCheckedIn(checkin_status)) {
            return 'Patient:in entlassen'; // "Checkout
        }

        return 'Folgetermin hinzufügen';
    };

    const handleSelectRoom = async (value: string) => {
        if (appointment.links.room_id) {
            setSelectedRoom(value);
            const handleConfirm = async () => {
                setAppointmentCheckingIn(appointment.id, true);
                // TODO: use parameter to check: If there's another person in the room, remove the old person

                const update_room_status = await updateAppointmentRoom(appointment, value);
                if (update_room_status) {
                    setSelectedRoom(value); // This doesn't work, have to mutate appointment of component
                    mutateAppointmentsDate();
                } else {
                    showErrorNotification('Ein Problem ist aufgetreten, als der Patient in den Raum verlegt wurde.');
                }
                setAppointmentCheckingIn(appointment.id, false);
            };

            openConfirmationModal(
                'Möchten Sie den Patienten wirklich in ein anderes Zimmer verlegen?',
                undefined,
                handleConfirm,
                undefined
            );
        } else {
            setSelectedRoom(value);
        }
    };

    return (
        <div className="flex flex-col gap-4 transition-all duration-500 ease-in-out">
            <Select
                disabled={is_room_selection_disabled}
                label="Zimmer"
                placeholder="Zimmer wählen"
                data={rooms_selection}
                value={selected_room}
                defaultValue={selected_room}
                className="w-full"
                onChange={(room_id) => handleSelectRoom(room_id ?? '')}
            />
            <PatientAppointmentForms
                disabled={appointments_checked_in[appointment.id]?.is_checking_in || isCheckedOut(checkout_status)}
                appointment={appointment}
                isEditingFormsCallback={(is_editing_form) => setShowCheckinButtons(!is_editing_form)}
            />
            {show_checkin_buttons && (
                <div className="flex w-full flex-col gap-5">
                    {is_call_system_active && isCheckinPending(checkin_status) && (
                        <div className="flex items-center gap-2">
                            <Switch
                                disabled={appointments_checked_in[appointment.id]?.is_checking_in}
                                checked={is_config_auto_checkin}
                                onChange={() => setConfigAutoCheckin(!is_config_auto_checkin)}
                                size="md"
                                label="Auto-Check-In"
                            />
                        </div>
                    )}
                    <div className="flex w-full justify-between gap-2">
                        <ConditionalTooltip
                            label={selected_room ? undefined : 'Bitte wählen Sie einen Raum zum Einchecken aus.'}
                        >
                            <Button
                                className="w-full"
                                disabled={is_cta_disabled}
                                loading={
                                    appointments_checked_in[appointment.id]?.is_checking_in ||
                                    forms_loading ||
                                    is_loading_prac
                                }
                                onClick={handleCta}
                            >
                                {getCtaText()}
                            </Button>
                        </ConditionalTooltip>
                    </div>
                </div>
            )}
            <Divider className="w-full" />
            <section className="flex w-full justify-between">
                <IconButton icon={FileScan} text="Datei einscannen" disabled tooltip="Coming soon" />
                <IconButton icon={Mail} text="Nachricht senden" disabled tooltip="Coming soon" />
                <IconButton icon={PrinterIcon} text="Ticket ausdrucken" />
                <IconButton loading={is_qr_code_loading} onClick={handleShowQr} icon={QrCode} text="QR-Code anzeigen" />
            </section>
            <Divider className="w-full" />
            <DocumentExchange appointment={appointment} />
        </div>
    );
};

export default PatientAppointmentActions;
