import { Appointment } from '@/models/appointment/Appointment.ts';
import { UserAppointmentDto } from '@/models/call-system/dtos/user-appointment-dto.ts';
import useSWR from 'swr';

import useConfig from '@/hooks/useConfig.ts';

import { fetchAppointmentsCheckInData } from '@/services/appointmentService.ts';

import { useFilterStore } from '@/stores/filterStore.ts';

import { generateAppointmentKey } from '@/utils/appointmentUtils.ts';

const useCheckInData = () => {
    const { customer_id, instance_id } = useConfig();
    const { date: selected_date } = useFilterStore();

    const {
        data,
        isLoading: is_loading,
        mutate,
    } = useSWR(
        !selected_date
            ? null
            : {
                  key: `checkin-data-for-${customer_id}-today`,
                  customer_id: customer_id,
                  instance_id: instance_id,
                  date: selected_date,
              },
        async () => {
            const data = await fetchAppointmentsCheckInData(customer_id, instance_id, selected_date!.toString());
            return new Map(
                data?.map((cur: UserAppointmentDto) => [
                    generateAppointmentKey(cur.instance_id, cur.appointment_id),
                    cur,
                ])
            );
        },
        {
            revalidateOnFocus: false,
            refreshInterval: 15000,
        }
    );

    const getAppointmentCheckinData = (appointment: Appointment) =>
        data?.get(generateAppointmentKey(appointment.links.dc_instance_id, appointment.id));

    return {
        check_in_data: data,
        is_loading: is_loading,
        mutate: mutate,
        getAppointmentCheckinData: getAppointmentCheckinData,
    };
};

export default useCheckInData;
