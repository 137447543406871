import { ColumnType, KanbanColumn } from '@/models/RoomView.ts';
import { DailyAppointment } from '@/models/appointment/Appointment.ts';
import { CheckInDataMap } from '@/models/call-system/dtos/user-appointment-dto.ts';
import { DndContext, DragEndEvent, DragOverlay, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import { Button, Collapse, Divider, Loader, ScrollArea } from '@mantine/core';
import { ChevronDown } from 'lucide-react';
import { FC, useEffect, useMemo, useState } from 'react';

import Clock from '@/components/Clock.tsx';
import FilterHeader from '@/components/Filtering/FilterHeader.tsx';
import Page from '@/components/Page.tsx';
import WalkInModal from '@/components/WalkInModal.tsx';
import AppointmentCard from '@/components/room-view/AppointmentCard/AppointmentCard.tsx';
import KanbanColumnComponent from '@/components/room-view/KanbanColumn.tsx';
import Heading1 from '@/components/text/Heading1.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import useAppointmentsForDate from '@/hooks/useAppointmentsForDate.ts';
import useCheckInData from '@/hooks/useCheckInData.ts';
import useConfig from '@/hooks/useConfig.ts';
import useEnrichedPractitionerData from '@/hooks/useEnrichedPractitionerData.ts';

import {
    checkoutAppointment,
    moveAppointmentToExpected,
    prepareAndCheckinArrived,
    updateAppointmentRoom,
} from '@/services/appointmentService.ts';

import { useAppointmentCheckinStore } from '@/stores/appointmentCheckinStore.ts';
import { useFilterStore } from '@/stores/filterStore.ts';

import { isRoomColumn, moveKanbanAppointment, prepareKanbanMove } from '@/utils/appointmentUtils.ts';
import { filterAppointments } from '@/utils/filterUtils.ts';
import { filterRoomsBySelection } from '@/utils/filterUtils.ts';
import { deepCloneMap } from '@/utils/mapUtils.ts';
import { openAppointmentScheduler, openPatientModal } from '@/utils/modalUtils.tsx';
import { generateColumns, populateColumnsWithAppointments } from '@/utils/roomViewUtils.ts/column.ts';

const RoomViewPage: FC = () => {
    const [walk_in_modal_opened, setWalkInModalOpened] = useState<boolean>(false);
    const [dragging_appointment, setDraggingAppointment] = useState<DailyAppointment | null>(null);
    const [filled_columns, setFilledColumns] = useState<Map<string, KanbanColumn> | undefined>(undefined);
    const { setAppointmentCheckingIn } = useAppointmentCheckinStore();
    const { data: enriched_practitioner_data } = useEnrichedPractitionerData();
    const { search_text, selected_rooms, selected_practitioners } = useFilterStore();

    const { data: appointments_data, is_loading, mutate: mutateAppointmentsDate } = useAppointmentsForDate();

    const { check_in_data, mutate: mutateCheckinData, getAppointmentCheckinData } = useCheckInData();

    const { selected_location } = useConfig();
    const rooms = selected_location.config?.rooms;

    // Filter rooms based on selection, always include waiting rooms
    const filtered_rooms = useMemo(() => filterRoomsBySelection(rooms, selected_rooms), [rooms, selected_rooms]);

    const initial_columns = generateColumns(filtered_rooms);

    const [collapsed_map, setCollapsedMap] = useState<Map<string, boolean>>(
        new Map(Array.from(initial_columns.keys()).map((key) => [key, false]))
    );
    const [collapse_treatment_room, setCollapseTreatmentRoom] = useState<boolean>(false);

    const openPatientCard = (appointment: DailyAppointment) => {
        openPatientModal(appointment.user_id, appointment, () => {
            mutateCheckinData();
            mutateAppointmentsDate();
        });
    };

    useEffect(() => {
        if (appointments_data?.data) {
            // Filter appointments based on search text before populating columns
            const filtered_appointments = filterAppointments(
                appointments_data.data,
                search_text,
                null,
                selected_practitioners
            );
            setFilledColumns(
                populateColumnsWithAppointments(filtered_appointments, initial_columns, getAppointmentCheckinData)
            );
        }
    }, [appointments_data, filtered_rooms, search_text, selected_practitioners]);

    const toggleCollapse = (id: string) => {
        setCollapsedMap((prev) => {
            const new_map = new Map(prev);
            new_map.set(id, !prev.get(id));
            return new_map;
        });
    };

    const renderColumn = (column_type: ColumnType, collapsable: boolean, check_in_data: CheckInDataMap) => {
        if (!filled_columns) {
            return undefined;
        }

        return Array.from(filled_columns.values())
            .filter((col) => col.type === column_type)
            .map((col) => (
                <KanbanColumnComponent
                    key={col.id}
                    column={col}
                    collapsed={collapsable ? collapsed_map.get(col.id) : undefined}
                    toggleCollapse={collapsable ? toggleCollapse : undefined}
                    collapsable={collapsable}
                    onAppointmentClick={openPatientCard}
                    checkInData={check_in_data}
                />
            ));
    };

    const first_column = useMemo(() => {
        return renderColumn(ColumnType.EXPECTED, false, check_in_data);
    }, [filled_columns, collapsed_map, check_in_data]);

    const second_column = useMemo(() => {
        return <div className="flex flex-col gap-4">{renderColumn(ColumnType.WAITING_ROOM, true, check_in_data)}</div>;
    }, [filled_columns, collapsed_map, check_in_data]);

    const third_column = useMemo(() => {
        return (
            <div className="flex flex-col gap-4">
                <button
                    className="flex w-full justify-between px-4 tracking-wider text-gray-500"
                    onClick={() => {
                        setCollapseTreatmentRoom(!collapse_treatment_room);
                    }}
                >
                    <Paragraph>{'ZIMMER'.toUpperCase()}</Paragraph>
                    <ChevronDown
                        className={`text-gray-400 transition-transform ${collapse_treatment_room ? 'rotate-180' : ''}`}
                    />
                </button>
                <Collapse in={!collapse_treatment_room}>
                    {renderColumn(ColumnType.TREATMENT_ROOM, false, check_in_data)}
                </Collapse>
                {renderColumn(ColumnType.DISCHARGED, true, check_in_data)}
            </div>
        );
    }, [filled_columns, collapsed_map, check_in_data, collapse_treatment_room]);

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 20, // Drag only starts after moving the mouse distance in px
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 100,
                tolerance: 5,
            },
        })
    );

    const handleDragEnd = async (event: DragEndEvent) => {
        if (!filled_columns) return;

        // basic validation
        const kanban_move_data = prepareKanbanMove(event, filled_columns);
        if (!kanban_move_data) return;
        const { source_column, destination_column, appointment } = kanban_move_data;
        // backup columns to restore if processing fails
        const undo_filled_columns = deepCloneMap(filled_columns);
        // move card
        const new_filled_columns = moveKanbanAppointment(
            filled_columns,
            source_column,
            destination_column,
            appointment
        );
        // ends drop
        setFilledColumns(new_filled_columns);
        setDraggingAppointment(null);

        // business logic
        // TODO: move this to a service or Utils
        const checkin_data = getAppointmentCheckinData(appointment);

        setAppointmentCheckingIn(appointment.id, true);
        if (isRoomColumn(source_column) && isRoomColumn(destination_column)) {
            if (source_column.id !== destination_column.id) {
                const update_room_success = await updateAppointmentRoom(appointment, destination_column.id);
                if (!update_room_success) {
                    setFilledColumns(undo_filled_columns);
                }
                setAppointmentCheckingIn(appointment.id, false);
                return;
            }
        }

        if (
            (source_column.type === ColumnType.EXPECTED || source_column.type === ColumnType.DISCHARGED) &&
            isRoomColumn(destination_column)
        ) {
            const onSuccess = () => {
                mutateCheckinData();
                setAppointmentCheckingIn(appointment.id, false);
            };
            const onFail = () => {
                setFilledColumns(undo_filled_columns);
                setAppointmentCheckingIn(appointment.id, false);
            };
            const practitioner = enriched_practitioner_data?.practitioners.find(
                (prac) => prac.id === appointment.professional.id
            );

            await prepareAndCheckinArrived(
                appointment,
                destination_column.id,
                undefined,
                practitioner,
                onSuccess,
                onFail,
                checkin_data?.number
            );
            return;
        }

        // checkout
        if (isRoomColumn(source_column) && destination_column.type === ColumnType.DISCHARGED) {
            await checkoutAppointment(appointment, checkin_data?.number ?? '');
            setAppointmentCheckingIn(appointment.id, false);
            await mutateCheckinData();
            return;
        }

        if (
            (source_column.type === ColumnType.DISCHARGED || isRoomColumn(source_column)) &&
            destination_column.type === ColumnType.EXPECTED
        ) {
            await moveAppointmentToExpected(appointment, checkin_data?.number ?? '');
            setAppointmentCheckingIn(appointment.id, false);
            await mutateCheckinData();
            return;
        }

        setAppointmentCheckingIn(appointment.id, false);
        setFilledColumns(undo_filled_columns);
    };

    return (
        <Page className="gap-10 p-10">
            {/* TODO: create dedicated reusable component for this page and the list view page */}
            <div className="flex w-full items-center">
                <Heading1 className="flex-grow text-4xl font-semibold">Termine</Heading1>
                <Clock />
                <Button onClick={() => setWalkInModalOpened(true)}>Termin erstellen</Button>
            </div>
            <div className="w-full">
                {/* TODO: create dedicated reusable component for this page and the list view page */}
                <FilterHeader />
            </div>
            {is_loading ? (
                <Loader />
            ) : (
                <DndContext
                    sensors={sensors}
                    onDragEnd={handleDragEnd}
                    onDragStart={(event) => {
                        const appointment = Array.from(filled_columns?.values() || [])
                            .flatMap((col) => col.appointments)
                            .find((app) => app.id === event.active.id);
                        setDraggingAppointment(appointment || null);
                    }}
                >
                    <div className="flex w-full gap-3">
                        <ScrollArea
                            scrollHideDelay={0}
                            classNames={{ root: 'h-[75vh] basis-2/6', scrollbar: 'opacity-10' }}
                        >
                            {first_column}
                        </ScrollArea>
                        <Divider orientation="vertical" />
                        <ScrollArea
                            scrollHideDelay={0}
                            classNames={{ root: 'h-[75vh] basis-2/6', scrollbar: 'opacity-10' }}
                        >
                            {second_column}
                        </ScrollArea>
                        <Divider orientation="vertical" />
                        <ScrollArea
                            scrollHideDelay={0}
                            classNames={{ root: 'h-[75vh] basis-3/6', scrollbar: 'opacity-10' }}
                        >
                            {third_column}
                        </ScrollArea>
                    </div>
                    <DragOverlay modifiers={[snapCenterToCursor]}>
                        {dragging_appointment && (
                            <div className="opacity-60">
                                <AppointmentCard
                                    appointment={dragging_appointment}
                                    isPending={true}
                                    onClick={() => {}}
                                />
                            </div>
                        )}
                    </DragOverlay>
                </DndContext>
            )}
            <WalkInModal
                opened={walk_in_modal_opened}
                onClose={() => setWalkInModalOpened(false)}
                onConfirm={() => openAppointmentScheduler()}
            />
        </Page>
    );
};

export default RoomViewPage;
