import { PrismicDataType } from '@/models/PrismicHookTypes.ts';
import { DailyAppointment, Priority } from '@/models/appointment/Appointment.ts';
import { UserAppointmentDto } from '@/models/call-system/dtos/user-appointment-dto.ts';
import { Card } from '@mantine/core';
import { FileCheck, FileX } from 'lucide-react';
import { FC, useMemo } from 'react';

import PriorityMarker from '@/components/PriorityMarker.tsx';
import AppointmentCardHeader from '@/components/room-view/AppointmentCard/AppointmentCardHeader.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import { usePatientAppointmentRequiredForms } from '@/hooks/usePatientAppointmentRequiredForms.ts';
import usePrismic from '@/hooks/usePrismic.tsx';

import { useAppointmentCheckinStore } from '@/stores/appointmentCheckinStore.ts';

import { getInsuranceByKey } from '@/utils/patientUtils.ts';
import { getTextFromRichTextField } from '@/utils/prismicUtils.ts';
import { cn } from '@/utils/styleUtils.ts';

interface Props {
    appointment: DailyAppointment;
    checkInData?: UserAppointmentDto;
    onClick: (appointment: DailyAppointment) => void;
}

const PendingAppointmentCard: FC<Props> = ({ appointment, onClick, checkInData }) => {
    const insurance = useMemo(() => getInsuranceByKey(appointment.data.insurance_coverage_type), [appointment]);
    const { data: professional_profiles } = usePrismic(PrismicDataType.PROFESSIONAL_PROFILE);
    const { formatted_forms_for_appointment } = usePatientAppointmentRequiredForms(appointment);

    const has_completed_forms = useMemo(
        () => formatted_forms_for_appointment?.every((form) => form.is_completed),
        [formatted_forms_for_appointment]
    );

    const { appointments: appointments_checkin } = useAppointmentCheckinStore();

    // Missing patient id are schedule created on DC without any patient attached. We don't support them.
    const missing_patient_id: boolean =
        appointment.links.patient_id === undefined || appointment.links.patient_id === '-';
    const loading_patient: boolean =
        appointments_checkin[appointment.id]?.is_checking_in || (!missing_patient_id && !appointment.data.patient_name);
    const disabled: boolean = missing_patient_id || loading_patient;

    const practicioner =
        getTextFromRichTextField(professional_profiles?.get(appointment.professional.key)?.display_name) ??
        appointment.professional.key;

    return (
        <Card
            className={cn(
                'gap-2 rounded-lg p-6 shadow-md transition duration-100 hover:bg-slate-50',
                disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
            )}
            withBorder
            onClick={() => {
                if (!disabled) onClick(appointment);
            }}
        >
            <PriorityMarker priority={appointment.priority ?? Priority.NORMAL} />
            <AppointmentCardHeader
                patientName={appointment.data.patient_name}
                insurance={insurance}
                isInsuranceCardActive={appointment.data.is_insurance_card_active}
                date={appointment.start}
                showDate
                loading={loading_patient}
                checkinData={checkInData}
                isOnline={appointment.is_online}
            />
            <div className="flex items-center">
                <div className="flex-grow">
                    <Paragraph>{appointment.data.services[0]?.name}</Paragraph>
                    <Paragraph className="text-slate-500">{practicioner}</Paragraph>
                </div>
                {has_completed_forms ? <FileCheck className="text-green-700" /> : <FileX className="text-red-700" />}
            </div>
        </Card>
    );
};

export default PendingAppointmentCard;
