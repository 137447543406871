import { Divider, MantineSize, Modal } from '@mantine/core';
import { clsx } from 'clsx';
import { X } from 'lucide-react';
import { FC, ReactNode } from 'react';

import { cn } from '@/utils/styleUtils.ts';

interface Props {
    opened: boolean;
    onClose: () => void;
    title?: ReactNode;
    children: ReactNode;
    size?: MantineSize;
    classNames?: {
        header?: string;
        childrenWrapper?: string;
    };
    footer?: ReactNode;
}

// TODO: create BaseModalBody and BaseModalFooter components
const BaseModal: FC<Props> = ({ opened, onClose, title, children, size = 'xl', classNames, footer }) => {
    return (
        <Modal
            opened={opened}
            onClose={onClose}
            size={size}
            centered
            withCloseButton
            closeButtonProps={{
                icon: <X className="size-5/6" />,
            }}
            classNames={{
                // TODO: add config for gradient
                header: clsx(
                    // 'border-b-2 border-slate-200 bg-gradient-to-r from-primaryLight to-primary pl-6 text-font',
                    'border-b-2 border-slate-200 bg-slate-100 pl-6 text-font',
                    classNames?.header
                ),
                title: 'text-lg font-bold',
                content: 'shadow-2xl',
                body: 'bg-slate-100 p-0',
                close: 'text-font transition hover:text-black',
            }}
            title={title}
            overlayProps={{
                className: 'bg-black bg-opacity-30 backdrop-blur',
            }}
        >
            <div className={cn('max-h-[75vh] overflow-y-scroll p-6', classNames?.childrenWrapper)}>{children}</div>
            {footer && (
                <div className="relative bg-slate-200 bg-opacity-75">
                    <Divider />
                    <div
                        className={clsx(
                            'flex h-full w-full flex-row items-center p-4',
                            Array.isArray(footer) ? 'justify-between' : 'justify-end'
                        )}
                    >
                        {footer}
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default BaseModal;
