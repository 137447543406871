import { CardStatus } from '@/models/CardStatus.ts';
import { DailyAppointment } from '@/models/appointment/Appointment.ts';
import { FC, useMemo, useState } from 'react';
import useSWR from 'swr';

import CollapsableItem from '@/components/CollapsableItem.tsx';
import CollapsableSection from '@/components/CollapsableSection.tsx';
import ExternalLink from '@/components/ExternalLink.tsx';
import CardButton from '@/components/PatientModal/CardButton.tsx';
import PatientInformation from '@/components/PatientModal/PatientInformation.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import useAppointmentsForDate from '@/hooks/useAppointmentsForDate.ts';
import useConfig from '@/hooks/useConfig.ts';

import { getUserById } from '@/services/patientService.ts';

import { useBookingStore } from '@/stores/bookingStore.ts';

import { dayjs } from '@/utils/dayjsSetup.ts';
import { buildPatientFolderUrl } from '@/utils/instanceUtils.ts';
import { openAppointmentScheduler } from '@/utils/modalUtils.tsx';

import CircularLoader from '../CircularLoader.tsx';
import AppointmentDetails from './AppointmentDetails.tsx';
import DocumentExchange from './DocumentExchange.tsx';

interface Props {
    appointmentId: string;
}

const PatientModal: FC<Props> = ({ appointmentId }) => {
    const { data: appointments_data, is_loading } = useAppointmentsForDate();
    const appointment: DailyAppointment | undefined =
        appointments_data?.data.active.find((app) => app.id === appointmentId) ??
        appointments_data?.data.inactive.find((app) => app.id === appointmentId);

    // TEMPORARY STATE FOR PRESENTATION
    const [egk_status, setEgkStatus] = useState<CardStatus>(CardStatus.UNREAD);
    const { domain } = useConfig();

    const { data: patient_data } = useSWR(appointment?.links.patient_id, getUserById);

    const patient_folder_deep_link: string = useMemo(() => {
        if (!domain || !appointment?.links.patient_id) return '';

        return buildPatientFolderUrl(domain, appointment.links.patient_id);
    }, [appointment, domain]);

    const cycleEgkStatus = () => {
        switch (egk_status) {
            case CardStatus.UNREAD:
                setEgkStatus(CardStatus.BRING_LATER);
                break;
            case CardStatus.BRING_LATER:
                setEgkStatus(CardStatus.READ);
                break;
            case CardStatus.READ:
                setEgkStatus(CardStatus.UNREAD);
                break;
            default:
                setEgkStatus(CardStatus.BRING_LATER);
                break;
        }
    };

    if (!appointment || is_loading)
        return (
            <div className="flex h-full items-center justify-center">
                <CircularLoader />
            </div>
        );

    return (
        <div className="flex flex-col gap-6">
            <div className="flex items-center gap-4 px-0.5">
                <div className="flex gap-1">
                    <Paragraph>PN:</Paragraph>
                    <Paragraph>{appointment.patient.account.patient_number}</Paragraph>
                </div>
                <Paragraph>{dayjs(appointment.patient.account.date_of_birth).format('DD.MM.YYYY')}</Paragraph>
                <ExternalLink
                    text="Patientenakte"
                    href={patient_folder_deep_link}
                    classNames={{ anchor: 'text-black' }}
                />
                <CardButton status={egk_status} onClick={cycleEgkStatus} />
            </div>
            {appointment ? (
                <AppointmentDetails
                    appointment={appointment}
                    onEdit={() => {
                        useBookingStore.setState({ current_patient: patient_data });
                        openAppointmentScheduler();
                    }}
                />
            ) : (
                <CircularLoader />
            )}
            <CollapsableSection>
                <CollapsableItem title="Patienteninformation">
                    <PatientInformation patient={appointment.patient} />
                </CollapsableItem>
                <CollapsableItem title="Andere Dokumente">
                    <DocumentExchange appointment={appointment} showDocumentsByAppointment={false} />
                </CollapsableItem>
                {/*TODO: implement appointment history*/}
                {/*<CollapsableItem title="Buchungshistorie">*/}
                {/*    <AppointmentHistory appointments={[]} />*/}
                {/*</CollapsableItem>*/}
            </CollapsableSection>
        </div>
    );
};

export default PatientModal;
