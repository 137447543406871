import { Insurance } from '@/models/appointment/Appointment.ts';
import { AppointmentType, Practitioner } from '@/models/appointment/EnrichedPractitionerData.ts';
import { Accordion } from '@mantine/core';
import { motion } from 'framer-motion';
import { FC, useEffect, useMemo, useState } from 'react';

import TimeslotCalendarAccordionItem from '@/components/AppointmentScheduler/TimeslotCalendarAccordionItem.tsx';
import Unavailable from '@/components/Unavailable.tsx';

interface Props {
    selectedAppointment?: AppointmentType;
    practitioners: Practitioner[];
    onSlotSelect: (practitioner_id: string, slot: string) => void;
    selectedCalendar?: string;
    selectedSlot?: string;
    insurance: Insurance;
    defaultStartDate?: string;
}

const TimeslotCalendarAccordion: FC<Props> = ({
    selectedAppointment,
    practitioners,
    onSlotSelect,
    selectedCalendar,
    selectedSlot,
    insurance,
    defaultStartDate,
}) => {
    const [opened_accordion_item, setOpenedAccordionItem] = useState<string>('');
    const available_practitioners = useMemo(
        () => practitioners.filter((item) => selectedAppointment?.offered_by_practitioner_ids.includes(item.id)),
        [practitioners, selectedAppointment]
    );

    useEffect(() => {
        if (available_practitioners.length > 0) setOpenedAccordionItem(available_practitioners[0].id);
    }, [available_practitioners]);

    const selected_slot = useMemo(() => `${selectedCalendar}_${selectedSlot}`, [selectedCalendar, selectedSlot]);

    return (
        <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3 }}
            className="w-full overflow-hidden"
        >
            {!selectedAppointment ? (
                <Unavailable text="Zeitfenster sind fuer diesen Terminart nicht sichtbar" />
            ) : (
                <Accordion
                    value={opened_accordion_item}
                    onChange={(item) => setOpenedAccordionItem(item as string)}
                    classNames={{ content: 'flex flex-col gap-4 p-0', label: 'py-6' }}
                    className="border-t border-solid"
                >
                    {available_practitioners.map((practitioner) => (
                        <TimeslotCalendarAccordionItem
                            key={practitioner.id}
                            practitioner={practitioner}
                            selectedCalendar={selectedCalendar}
                            appointmentType={selectedAppointment}
                            insurance={insurance}
                            onSlotSelect={onSlotSelect}
                            selectedSlot={selected_slot}
                            isOpen={opened_accordion_item === practitioner.id}
                            defaultStartDate={defaultStartDate}
                        />
                    ))}
                </Accordion>
            )}
        </motion.div>
    );
};

export default TimeslotCalendarAccordion;
