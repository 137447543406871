import { ApiError, api } from '@/lib/api-client.ts';
import { DocumentResponseDto } from '@/models/documents/Document.ts';
import { ErrorResponseDto } from '@/models/documents/document-error-dto.ts';

import { ServiceUrl } from '@/services/ServiceURL.ts';

import { getCustomerId } from '@/utils/customerUtils.ts';

export const getDocumentsByUser = async (user_id: string, appointment_id?: string) => {
    const config = {
        headers: {
            customer_id: getCustomerId(),
        },
        params: { user_id, appointment_id },
    };

    try {
        const response = await api.get(
            `${ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.USER.DOCUMENTS_DATA}/shared`,
            config
        );
        return response.data?.data;
    } catch (error) {
        console.debug(error);
        return null;
    }
};

export const getDocumentDownloadUrl = async (
    user_id: string,
    document_id: string
): Promise<[boolean, DocumentResponseDto | undefined, ApiError<ErrorResponseDto> | undefined]> => {
    const config = {
        headers: {
            customer_id: getCustomerId(),
        },
        params: { user_id: user_id },
    };

    try {
        const response = await api.get(
            `${ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.USER.DOCUMENTS_DATA}/${document_id}`,
            config
        );
        return [true, response.data, undefined];
    } catch (error) {
        console.debug(error);
        return [false, undefined, error as ApiError<ErrorResponseDto>];
    }
};

export const generateDocumentDownloadCode = async (
    email: string
): Promise<[boolean, ApiError<ErrorResponseDto> | undefined]> => {
    const config = {
        headers: {
            customer_id: getCustomerId(),
        },
    };

    try {
        await api.post(
            `${ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.USER.DOCUMENTS_DATA}/generate-download-code`,
            { email: email },
            config
        );
        return [true, undefined];
    } catch (error) {
        console.debug(error);

        return [false, error as ApiError<ErrorResponseDto>];
    }
};

export const validateDocumentDownloadCode = async (
    code: string
): Promise<[boolean, ApiError<ErrorResponseDto> | undefined]> => {
    const config = {
        headers: {
            customer_id: getCustomerId(),
        },
    };

    try {
        await api.post(
            `${ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.USER.DOCUMENTS_DATA}/validate-download-code`,
            { code: code },
            config
        );
        return [true, undefined];
    } catch (error) {
        console.debug(error);
        return [false, error as ApiError<ErrorResponseDto>];
    }
};
