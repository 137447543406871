import { Patient } from '@/models/Patient.ts';
import { Appointment, AppointmentBookStatus } from '@/models/appointment/Appointment.ts';
import { AppointmentBookingFormValues } from '@/models/appointment/AppointmentBookingFormValues.ts';
import { BookAppointmentDto } from '@/models/appointment/BookAppointmentDto.ts';
import { Button } from '@mantine/core';
import { FC, useMemo } from 'react';

import useAppointmentData from '@/hooks/useAppointmentData.ts';

import { bookAppointment, pollStatus } from '@/services/appointmentService.ts';
import { showLoadingNotification } from '@/services/notificationService.ts';

import { useBookingStore } from '@/stores/bookingStore.ts';
import { useConfigStore } from '@/stores/configStore.ts';

import { getBookingAppointmentDtoFromFormData } from '@/utils/appointmentUtils.ts';

interface Props {
    patient?: Patient;
}

const AppointmentSchedulerFooter: FC<Props> = ({ patient }) => {
    const {
        appointments_form_data,
        is_last_form_valid,
        getCurrentBatch,
        addBatch,
        updateAppointmentStatus,
        addAppointmentsToBatch,
        current_batch_id,
        is_booking_loading,
        resetAllData,
    } = useBookingStore((state) => state);

    const { selected_location, selected_customer } = useConfigStore((state) => state);

    const current_batch = useBookingStore((state) => state.batches.get(current_batch_id!));

    const handleClose = () => {
        if (!is_booking_loading) {
            resetAllData(); // Clear data on modal close
            useBookingStore.setState({ is_open: false });
        }
    };

    const { getAppointmentDataFromIds } = useAppointmentData();

    const onFormsSubmit = async (forms: (AppointmentBookingFormValues & { id: string })[]) => {
        useBookingStore.setState({
            is_booking_loading: true,
            current_appointment_id: null,
        });

        if (current_batch?.appointments && current_batch.appointments.length >= 1) {
            // Update existing batch
            if (!current_batch_id) {
                console.debug('missing current batch id in scheduler component ');
                return;
            }

            const notification_id = showLoadingNotification(
                `Menge: ${forms.length}`, // Quantity
                `${patient?.name.first_name} ${patient?.name.last_name}`
            );

            addAppointmentsToBatch(
                current_batch_id,
                notification_id,
                forms.map((values) => ({
                    id: values.id,
                    status: AppointmentBookStatus.PENDING_BOOKING,
                    form_data: values,
                }))
            );
        } else {
            // Create new batch
            const notification_id = showLoadingNotification(
                `Menge: ${forms.length}`, // Quantity
                `${patient?.name.first_name} ${patient?.name.last_name}`
            );

            // Add batch with all appointments set to LOADING
            // TODO: ensure customer and location are there
            addBatch(
                selected_customer!,
                selected_location!,
                current_batch_id!,
                notification_id,
                patient!,
                forms.map((values) => ({
                    id: values.id,
                    status: AppointmentBookStatus.PENDING_BOOKING,
                    form_data: values,
                }))
            );
        }

        // TODO: check if this can be moved to a hook
        // Submit each appointment
        for (const form_value of forms) {
            try {
                // Fetch data from forms ids
                const data = getAppointmentDataFromIds(form_value);
                const apt_dto = getBookingAppointmentDtoFromFormData(
                    selected_location!,
                    {
                        user_id: patient?.eterno_user_id || '',
                        doc_cirrus_user_id: patient?.user_id || '',
                        is_eterno_user: patient?.is_registered ?? false,
                    },
                    data!
                );

                const result = await book(apt_dto);

                useBookingStore.setState({ appointments_form_data: [] });
                updateAppointmentStatus(
                    current_batch_id!,
                    form_value.id,
                    result ? AppointmentBookStatus.PENDING_BOOKING : AppointmentBookStatus.DECLINED
                );

                // Poll status until booked
                handlePollStatus(current_batch_id!, {
                    id: form_value.id,
                    appointment_id: result.id,
                    user_id: result.user_id,
                    customer_id: result.customer_id,
                });
            } catch (error) {
                updateAppointmentStatus(
                    current_batch_id!,
                    form_value.id,
                    AppointmentBookStatus.DECLINED,
                    (error as Error).message
                );
            }
        }

        useBookingStore.setState({ appointments_form_data: [], is_booking_loading: false });
    };

    const handlePollStatus = (
        batch_id: string,
        data: { id: string; user_id: string; customer_id: string; appointment_id: string }
    ) => {
        pollStatus(batch_id, data, updateAppointmentStatus, getCurrentBatch, patient);
    };

    const book = async (data: BookAppointmentDto): Promise<Appointment> =>
        await bookAppointment(selected_customer?.customer_id || '', selected_location?.config.instance_id || '', data);

    // Save if there are new appointment or close
    const submit_button = useMemo(() => {
        const is_disabled = (appointments_form_data.length > 0 && !is_last_form_valid) || is_booking_loading;

        let submit_btn_text = 'Schließen'; // close by default
        if (appointments_form_data.length >= 1) {
            // Save
            submit_btn_text = `${appointments_form_data.length} ${appointments_form_data.length > 1 ? 'Termine' : 'Termin'} speichern`;
            return (
                <Button disabled={is_disabled} onClick={() => onFormsSubmit(appointments_form_data)}>
                    {submit_btn_text}
                </Button>
            );
        } else {
            return (
                <Button disabled={is_disabled} onClick={() => handleClose()}>
                    {submit_btn_text}
                </Button>
            );
        }
    }, [appointments_form_data, is_last_form_valid, is_booking_loading]);

    return (
        <div className="flex w-full justify-between">
            <Button disabled={is_booking_loading} variant="outline" onClick={() => handleClose()}>
                {/* TODO: add translation */}
                Abbrechen
            </Button>
            {submit_button}
        </div>
    );
};

export default AppointmentSchedulerFooter;
