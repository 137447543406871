import { Accordion } from '@mantine/core';
import { FC, ReactNode } from 'react';

interface Props {
    title: string;
    children: ReactNode;
}

const CollapsableItem: FC<Props> = ({ title, children }) => {
    return (
        <Accordion.Item value={title} className="w-full rounded-lg border bg-white">
            <Accordion.Control className="px-4 text-sm uppercase text-gray-500">{title}</Accordion.Control>
            <Accordion.Panel className="px-4">{children}</Accordion.Panel>
        </Accordion.Item>
    );
};

export default CollapsableItem;
