// TODO: add unit tests
export const buildUrlWithQueryParams = (
    base_url: string,
    query_params: {
        [key: string]: string;
    }
): string => {
    const url = new URL(base_url);
    url.search = new URLSearchParams(query_params).toString();
    return url.toString();
};
