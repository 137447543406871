import { Option } from '@/models/CheckListItem.ts';
import { RoomType } from '@/models/Room.ts';
import { DatePickerInput, DateValue } from '@mantine/dates';
import { Calendar } from 'lucide-react';
import { FC, useEffect, useMemo, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import PractitionerSelect from '@/components/Filtering/PractitionerSelect.tsx';
import RoomSelect from '@/components/Filtering/RoomSelect.tsx';
import TextFilter from '@/components/Filtering/TextFilter.tsx';

import useConfig from '@/hooks/useConfig.ts';
import useEnrichedPractitionerData from '@/hooks/useEnrichedPractitionerData.ts';

import { useFilterStore } from '@/stores/filterStore.ts';

import { dayjs } from '@/utils/dayjsSetup.ts';

interface Props {
    config?: {
        disablePast?: boolean;
    };
}

const FilterHeader: FC<Props> = ({ config = { disablePast: true } }) => {
    // Get rooms from selected location
    const { selected_location } = useConfig();
    const { search_text, date, selected_rooms, selected_practitioners } = useFilterStore(
        useShallow((state) => ({
            search_text: state.search_text,
            date: state.date,
            selected_rooms: state.selected_rooms,
            selected_practitioners: state.selected_practitioners,
        }))
    );
    const [practitioner_options, setPractitionerOptions] = useState<Option[]>([]);

    const { data } = useEnrichedPractitionerData();

    useEffect(() => {
        if (!data) return;

        const options: Option[] = [];
        const selected: string[] = [];

        data.practitioners.forEach((prac) => {
            options.push({
                label: prac.name,
                value: prac.id,
            });
            selected.push(prac.id);
        });

        setPractitionerOptions(options);
        setSelectedPractitioners(selected);
    }, [data]);

    const room_options = useMemo(() => {
        return (selected_location.config?.rooms ?? [])
            .filter(({ room_type }) => room_type === RoomType.ROOM)
            .map(({ room_name, room_id }) => ({
                label: room_name,
                value: room_id,
            }));
    }, [selected_location]);

    const setDateLogic = (date: DateValue): void => {
        useFilterStore.setState({ date: date ?? null });
    };

    const setSearchText = (search_text: string): void => {
        useFilterStore.setState({ search_text });
    };

    const setSelectedRooms = (rooms: string[]): void => {
        useFilterStore.setState({ selected_rooms: rooms });
    };

    const setSelectedPractitioners = (practitioners: string[]) => {
        useFilterStore.setState({
            selected_practitioners: practitioners,
        });
    };

    return (
        <div className="flex gap-5">
            <TextFilter
                placeholder="Name, Geburtstagsdatum, Email, Patientennummer"
                search_value={search_text}
                onSearchChange={setSearchText}
            />
            <PractitionerSelect
                practitioner_options={practitioner_options}
                selected_practitioners={selected_practitioners}
                onPractitionerChange={setSelectedPractitioners}
            />
            <RoomSelect room_options={room_options} selected_rooms={selected_rooms} onRoomsChange={setSelectedRooms} />
            <DatePickerInput
                value={date}
                onChange={setDateLogic}
                rightSection={<Calendar className="size-6" />}
                locale="de"
                rightSectionPointerEvents="none"
                className="w-48"
                minDate={config.disablePast ? dayjs().toDate() : undefined}
            />
        </div>
    );
};

export default FilterHeader;
