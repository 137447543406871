import { Checkbox, ComboboxItem, Input, Popover, ScrollArea } from '@mantine/core';
import { FC, useMemo, useState } from 'react';

interface Props {
    room_options: ComboboxItem[];
    selected_rooms: string[];
    onRoomsChange: (rooms: string[]) => void;
}

// Component that displays a dropdown with room selection and count
const RoomSelect: FC<Props> = ({ room_options, selected_rooms, onRoomsChange }) => {
    // State for popover visibility
    const [is_open, setIsOpen] = useState(false);

    // Memoized display text for the input should come from translations
    const display_text = useMemo(() => {
        if (selected_rooms.length === 0) return 'Keine Räume';
        if (selected_rooms.length === room_options.length) return `Alle Räume (${room_options.length})`;
        return `Ausgewählte Räume (${selected_rooms.length})`;
    }, [selected_rooms.length, room_options.length]);

    // Handler for selecting/deselecting all rooms
    const handleSelectAll = () => {
        if (selected_rooms.length === room_options.length) {
            onRoomsChange([]);
        } else {
            onRoomsChange(room_options.map((option) => option.value));
        }
    };

    // Handler for toggling individual room selection
    const handleToggleRoom = (room_value: string) => {
        const new_selected = selected_rooms.includes(room_value)
            ? selected_rooms.filter((value) => value !== room_value)
            : [...selected_rooms, room_value];
        onRoomsChange(new_selected);
    };

    // TODO: Use ListSelection component
    return (
        <div className="flex-grow">
            <Popover
                opened={is_open}
                onChange={setIsOpen}
                width="target"
                withArrow={false}
                classNames={{
                    dropdown: 'shadow-md',
                }}
            >
                <Popover.Target>
                    <Input pointer value={display_text} readOnly onClick={() => setIsOpen(!is_open)} />
                </Popover.Target>

                <Popover.Dropdown>
                    <div className="flex flex-col gap-2">
                        {/* Select all checkbox */}
                        <Checkbox
                            label="Alle Räume"
                            checked={selected_rooms.length === room_options.length}
                            indeterminate={selected_rooms.length > 0 && selected_rooms.length < room_options.length}
                            onChange={handleSelectAll}
                        />

                        <ScrollArea.Autosize>
                            <div className="mt-4 flex cursor-pointer flex-col gap-2">
                                {room_options.map((option) => (
                                    <Checkbox
                                        key={option.value}
                                        label={option.label}
                                        checked={selected_rooms.includes(option.value)}
                                        onChange={() => handleToggleRoom(option.value)}
                                    />
                                ))}
                            </div>
                        </ScrollArea.Autosize>
                    </div>
                </Popover.Dropdown>
            </Popover>
        </div>
    );
};

export default RoomSelect;
