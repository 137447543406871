import { EnrichedLocation, Printer } from '@/models/EnrichedPrismicDocuments.ts';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type PrinterStore = {
    printer_token: {
        token?: string;
        validUntil: number;
        instanceId: string;
    };
    selected_printer: Record<string, Printer>; // Adjust the type if you have a specific shape
    setSelectedPrinter: (location: EnrichedLocation, printer: Printer) => void;
};

export const usePrinterStore = create<PrinterStore>()(
    persist(
        (set, get) => ({
            printer_token: {
                token: undefined,
                validUntil: 0,
                instanceId: '',
            },
            selected_printer: {},
            setSelectedPrinter: (location: EnrichedLocation, printer: Printer) =>
                set({
                    selected_printer: { ...get().selected_printer, [location.key]: printer },
                }),
        }),
        {
            name: 'printer_store',
        }
    )
);
