import { PractitionerDto } from '@/models/PractitionerDto.ts';
import { Appointment } from '@/models/appointment/Appointment.ts';
import {
    AppointmentType,
    EnrichedPractitionerData,
    Practice,
    Practitioner,
    PractitionerCalendar,
    UnMappedScheduleType,
} from '@/models/appointment/EnrichedPractitionerData.ts';
import { DCScheduleType } from '@/models/doc-cirrus/DCScheduleType.ts';
import { DCScheduleTypeMapping } from '@/models/doc-cirrus/DCScheduleTypeMapping.ts';

export const getInsuranceTypeFromAppointment = (appointment: Appointment) =>
    appointment.data.insurance_coverage_type?.toLowerCase() === 'selfpayer'
        ? 'SELF_PAYER'
        : (appointment.data?.insurance_coverage_type?.toUpperCase() ?? '');

export const joinAppointmentServicesKeys = (appointment: Appointment) =>
    appointment.data.services.map((service) => service.key).join();

const getPractitionerNameFromUser = (user: PractitionerDto): string =>
    user.prismic_profile.data.display_name[0]?.text || user.dc_user.name.first_name + user.dc_user.name.last_name;

const getPractitionerIdFromUser = (user: PractitionerDto): string => user.dc_user_mapping.user_id;

// TODO: add unit test on the entire file :D
export const enrichPractitionerCachedData = (
    linked_users: PractitionerDto[],
    customer_id: string
): EnrichedPractitionerData => {
    const practices: Practice[] = extractUniquePracticesFromLinkedUsers(linked_users, customer_id);

    const map_appointment_types: Map<string, AppointmentType> = new Map();
    const map_unmapped_schedule_types: Map<string, UnMappedScheduleType> = new Map();

    const practitioners = linked_users.map((user) => {
        // Build enriched practitioner
        const practitioner: Practitioner = {
            id: getPractitionerIdFromUser(user),
            name: getPractitionerNameFromUser(user),
            prismic_key: user.prismic_profile.id,
            calendars: extractPractitionerCalendarsFromUser(user),
            pcs_stack_id: user.dc_user_mapping.pcs_stack_id,
            doc_cirrus: {
                dc_id: user.dc_user.user_id,
                dc_instance_id: user.dc_user.instance_id,
            },
            prismic_profile: user.prismic_profile,
            practices: extractPracticeFromLinkedUser(user, customer_id),
        };

        user.dc_schedule_types.forEach((schedule_type) => {
            if (schedule_type.is_mapped) {
                // If mapped enrich appointment_types
                // This can be done more efficient building a map to find apt_id from schedule_id easier.
                const appointment_type = user.dc_schedule_type_mappings.find(
                    (map) => map.dc_schedule_type_id === schedule_type.id
                );

                if (appointment_type) {
                    const existing_appointment_type = map_appointment_types.get(appointment_type.appointment_type_id);
                    if (existing_appointment_type) {
                        const updated_appointment_type = {
                            ...existing_appointment_type,
                        };

                        // If it exist already just add practitioner_id and calendar_ids
                        updated_appointment_type.offered_by_practitioner_ids.push(practitioner.id);
                        schedule_type.calendar_ids.forEach((id) => {
                            updated_appointment_type.doc_cirrus.dc_calendar_ids.push(id);
                        });

                        // Save the updated element
                        map_appointment_types.set(appointment_type.appointment_type_id, updated_appointment_type);
                    } else {
                        map_appointment_types.set(
                            appointment_type.appointment_type_id,
                            mapDCInfoToAppointmentType(schedule_type, appointment_type, practitioner.id)
                        );
                    }
                } else {
                    // error TBD
                    console.debug(`Missing appointment type for mapped Schedule_type_id: ${schedule_type.id}`);
                }
            } else {
                // If unmapped enrich unmapped_schedule_types
                const existing_unmapped_schedule_type = map_unmapped_schedule_types.get(schedule_type.id);
                if (existing_unmapped_schedule_type) {
                    const update_unmapped_schedule_type = {
                        ...existing_unmapped_schedule_type,
                    };
                    // If it exist already just add practitioner_id and calendar_ids
                    update_unmapped_schedule_type.offered_by_practitioner_ids.push(practitioner.id);
                    schedule_type.calendar_ids.forEach((id) => {
                        update_unmapped_schedule_type.doc_cirrus.dc_calendar_ids.push(id);
                    });

                    // Save the updated element
                    map_unmapped_schedule_types.set(schedule_type.id, update_unmapped_schedule_type);
                } else {
                    // If new just add it
                    const enriched_schedule_type: UnMappedScheduleType = {
                        name: schedule_type.name,
                        mapped: false,
                        offered_by_practitioner_ids: [practitioner.id],
                        doc_cirrus: {
                            dc_instance_id: schedule_type.instance_id,
                            dc_schedule_type_id: schedule_type.id,
                            dc_calendar_ids: schedule_type.calendar_ids,
                        },
                        duration: schedule_type.duration,
                    };

                    map_unmapped_schedule_types.set(schedule_type.id, enriched_schedule_type);
                }
            }
        });

        return practitioner;
    });

    const aggregated_result: EnrichedPractitionerData = {
        practices: practices,
        practitioners: practitioners,
        appointment_types: Array.from(map_appointment_types.values()),
        unmapped_schedule_types: Array.from(map_unmapped_schedule_types.values()),
    };

    return aggregated_result;
};

// Filter appointment or schedule appointment by available practitioner
const filterByAvailablePractitioner = <T extends { offered_by_practitioner_ids: string[] }>(
    data: T[],
    available_practitioner: Practitioner[]
): T[] => {
    const filtered_data = data.reduce<T[]>((acc, apt) => {
        const is_offered_by_practice = apt.offered_by_practitioner_ids.some((id) =>
            available_practitioner.find((practitioner) => practitioner.id === id)
        );

        if (is_offered_by_practice) {
            acc.push(apt);
        }

        return acc;
    }, []);

    return filtered_data;
};

export const filterEnrichedDataByPracticeId = (
    data: EnrichedPractitionerData,
    practice_doc_cirrus_id: string
): EnrichedPractitionerData | undefined => {
    // Don't filter if a key is not provided
    if (!practice_doc_cirrus_id) {
        return data;
    }

    // Filter practitioner and offered services by practice
    const practitioner_filtered_by_practice = data.practitioners.filter((practitioner) =>
        practitioner.practices.some((loc) => loc.doc_cirrus.id === practice_doc_cirrus_id)
    );

    // Filter by practice
    const appointment_types_filtered_by_practice = filterByAvailablePractitioner(
        data.appointment_types,
        practitioner_filtered_by_practice
    );

    // Filter by practice
    const unmapped_schedule_types_filtered_by_practice = filterByAvailablePractitioner(
        data.unmapped_schedule_types,
        practitioner_filtered_by_practice
    );

    const filtered_data: EnrichedPractitionerData = {
        practices: data.practices, // return all practice anyway
        practitioners: practitioner_filtered_by_practice,
        appointment_types: appointment_types_filtered_by_practice,
        unmapped_schedule_types: unmapped_schedule_types_filtered_by_practice,
    };

    return filtered_data;
};

const mapDCInfoToAppointmentType = (
    dc_schedule_type: DCScheduleType,
    dc_schedule_type_mapping: DCScheduleTypeMapping,
    practitioner_id: string
): AppointmentType => {
    const { dc_schedule_type_id, dc_instance_id, appointment_type_id, ...additional_info } = dc_schedule_type_mapping;

    const apt_type: AppointmentType = {
        id: appointment_type_id,
        mapped: true,
        offered_by_practitioner_ids: [practitioner_id],
        name: dc_schedule_type.name,
        ...additional_info,
        duration: dc_schedule_type.duration,
        doc_cirrus: {
            dc_calendar_ids: dc_schedule_type.calendar_ids,
            dc_schedule_type_id: dc_schedule_type_id,
            dc_instance_id: dc_instance_id,
        },
    };

    return apt_type;
};

const extractPractitionerCalendarsFromUser = (user: PractitionerDto): PractitionerCalendar[] => {
    const calendars: PractitionerCalendar[] = user.dc_calendars.map((calendar) => {
        const appointment_type_ids: string[] = user.dc_schedule_type_mappings.map((map) => map.appointment_type_id);
        const unmapped_schedule_type_ids: string[] = user.dc_schedule_types
            .filter((type) => !type.is_mapped)
            .map((type) => type.id);
        const enriched_calendar: PractitionerCalendar = {
            practitioner_id: getPractitionerIdFromUser(user),
            doc_cirrus: calendar,
            appointment_type_ids: appointment_type_ids,
            unmapped_schedule_type_ids: unmapped_schedule_type_ids,
        };

        return enriched_calendar;
    });

    return calendars || [];
};

const extractPracticeFromLinkedUser = (user: PractitionerDto, customer_id: string): Practice[] => {
    const locations = user.dc_user.account.locations.map((loc) => {
        // Enrich each practice
        const practice: Practice = {
            customer_id: customer_id,
            prismic_hub_id: user.prismic_hub.id,
            doc_cirrus: loc,
        };

        return practice;
    });

    return locations;
};

/**
 *  Extract unique practices from users and enrich with customer id and prismic hub id
 *  Users should belong to the same customer_id, there is no check here.
 */
const extractUniquePracticesFromLinkedUsers = (users: PractitionerDto[], customer_id: string): Practice[] => {
    const enriched_practices = users.flatMap((user) => {
        return extractPracticeFromLinkedUser(user, customer_id);
    });

    const enriched_practice_map = enriched_practices.reduce(
        (map, item) => map.set(item.doc_cirrus.id, item),
        new Map<string, Practice>()
    );

    return Array.from(enriched_practice_map.values());
};
