import { ColumnType, KanbanColumn } from '@/models/RoomView.ts';
import { DailyAppointment } from '@/models/appointment/Appointment.ts';
import { CheckInDataMap } from '@/models/call-system/dtos/user-appointment-dto.ts';
import { Collapse } from '@mantine/core';
import { ChevronDown } from 'lucide-react';
import { FC, useMemo } from 'react';

import DroppableComponent from '@/components/DroppableComponent.tsx';
import PendingAppointmentCard from '@/components/room-view/AppointmentCard/PendingAppointmentCard.tsx';
import ResolvedAppointmentCard from '@/components/room-view/AppointmentCard/ResolvedAppointmentCard.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import { generateAppointmentKey } from '@/utils/appointmentUtils.ts';

import DraggableComponent from '../DraggableComponent.tsx';

interface KanbanColumnComponentProps {
    column: KanbanColumn;
    collapsed?: boolean;
    collapsable?: boolean;
    toggleCollapse?: (id: string) => void;
    checkInData: CheckInDataMap;
    onAppointmentClick: (appointment: DailyAppointment) => void;
}

const KanbanColumnComponent: FC<KanbanColumnComponentProps> = ({
    column,
    collapsed = false,
    collapsable = false,
    toggleCollapse,
    onAppointmentClick,
    checkInData,
}) => {
    const sortAppointmentsByStartTime = useMemo(() => {
        return (appointments: DailyAppointment[], columnType: ColumnType) =>
            appointments.slice().sort((a, b) => {
                if (columnType === ColumnType.DISCHARGED) {
                    // For discharged column: sort in descending order (latest to earliest)
                    return new Date(b.start).getTime() - new Date(a.start).getTime();
                }
                // For all other columns: sort in ascending order (earliest to latest)
                return new Date(a.start).getTime() - new Date(b.start).getTime();
            });
    }, []);

    const appointments = useMemo(() => {
        const is_pending = column.type === ColumnType.EXPECTED || column.type === ColumnType.WAITING_ROOM;

        if (column.appointments.length === 0) return [];

        const sorted_appointments = sortAppointmentsByStartTime(column.appointments, column.type);

        return sorted_appointments.map((appointment) => {
            const check_in_data = checkInData?.get(
                generateAppointmentKey(appointment.links.dc_instance_id, appointment.id)
            );

            const card_component = is_pending ? (
                <PendingAppointmentCard
                    appointment={appointment}
                    onClick={onAppointmentClick}
                    checkInData={check_in_data}
                />
            ) : (
                <ResolvedAppointmentCard
                    appointment={appointment}
                    onClick={onAppointmentClick}
                    onCardClick={onAppointmentClick}
                    checkInData={check_in_data}
                    // isDischarged={column.type === ColumnType.DISCHARGED}
                />
            );

            return appointment.data.patient_name ? (
                <DraggableComponent id={appointment.id} key={appointment.id}>
                    {card_component}
                </DraggableComponent>
            ) : (
                <div key={appointment.id}>{card_component}</div>
            );
        });
    }, [column, checkInData, onAppointmentClick]);

    return (
        <DroppableComponent id={column.id}>
            <button
                className="mb-4 flex w-full justify-between px-2 tracking-wider text-gray-500"
                onClick={() => {
                    if (collapsable && toggleCollapse) {
                        toggleCollapse(column.id);
                    }
                }}
            >
                <Paragraph>
                    {column.title.toUpperCase()} {column.appointments.length > 0 && `(${column.appointments.length})`}
                </Paragraph>
                {collapsable && (
                    <ChevronDown className={`text-gray-400 transition-transform ${collapsed ? 'rotate-180' : ''}`} />
                )}
            </button>

            <Collapse in={!collapsed}>
                <div className={`space-y-2 rounded-lg bg-gray-50 p-2`}>
                    {column.appointments.length === 0 ? (
                        <Paragraph className="p-4 text-center text-gray-500">
                            {column.type == ColumnType.TREATMENT_ROOM ? 'Patienten hier ziehen' : 'Keine Patienten'}
                        </Paragraph>
                    ) : (
                        appointments
                    )}
                </div>
            </Collapse>
        </DroppableComponent>
    );
};

export default KanbanColumnComponent;
