import { color_mapping } from '@/constants/priority.ts';
import { Priority, PriorityDETranslation } from '@/models/appointment/Appointment.ts';
import { Tooltip } from '@mantine/core';
import { clsx } from 'clsx';
import { FC } from 'react';

interface Props {
    priority: Priority;
}

const PriorityLabel: FC<Props> = ({ priority }) => {
    const tooltip_text = {
        [Priority.NONE]: PriorityDETranslation.NONE,
        [Priority.LOW]: PriorityDETranslation.LOW,
        [Priority.NORMAL]: PriorityDETranslation.NORMAL,
        [Priority.IMPORTANT]: PriorityDETranslation.IMPORTANT,
        [Priority.VERYIMPORTANT]: PriorityDETranslation.VERYIMPORTANT,
        [Priority.CRITICAL]: PriorityDETranslation.CRITICAL,
    };

    if (priority === Priority.NONE)
        return (
            <Tooltip label={tooltip_text[priority]} withArrow>
                <div className="relative h-6 w-full overflow-hidden rounded border border-black bg-white shadow-md">
                    <div className="absolute left-[-50%] top-1/2 h-[2px] w-[200%] rotate-[36deg] bg-red-500" />
                </div>
            </Tooltip>
        );

    return (
        <Tooltip label={tooltip_text[priority]} withArrow>
            <div className={clsx('h-6 w-full rounded shadow-md', `bg-${color_mapping[priority]}`)} />
        </Tooltip>
    );
};

export default PriorityLabel;
