import { api } from '@/lib/api-client.ts';
import { DCCardReader } from '@/models/doc-cirrus/DCCardReader.ts';
import { Button, Loader } from '@mantine/core';
import { CircleCheck, IdCard, TriangleAlert } from 'lucide-react';
import { FC, ReactNode, useMemo, useState } from 'react';
import useSWRImmutable from 'swr/immutable';

import CircularLoader from '@/components/CircularLoader.tsx';
import VerticalIconWithText from '@/components/VerticalIconWithText.tsx';

import useAuth from '@/hooks/useAuth.ts';
import useConfig from '@/hooks/useConfig.ts';

import { ServiceUrl } from '@/services/ServiceURL.ts';

import SelectableList from './SelectableList.tsx';
import Paragraph from './text/Paragraph.tsx';

enum CardReaderStep {
    SELECT_READER = 'SELECT_READER',
    INSERT_CARD = 'INSERT_CARD',
    RETRY = 'RETRY',
    SUCCESS = 'SUCCESS',
    LOADING = 'LOADING',
}

const CardReader: FC = () => {
    const [step, setStep] = useState<CardReaderStep>(CardReaderStep.SELECT_READER);
    const [selected_item, setSelectedItem] = useState<string>('');
    const { getIdToken } = useAuth();
    const { instance_id, customer_id } = useConfig();

    const fetcher = async () => {
        const token = await getIdToken();

        const response = await api.get(ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.DOC_CIRRUS.CARD_READERS, {
            headers: {
                customer_id: customer_id,
                Authorization: token,
            },
            params: {
                instance_id: instance_id,
            },
        });

        return response;
    };

    const {
        data,
        isLoading: is_loading,
        error,
    } = useSWRImmutable(
        {
            key: 'card-readers',
            instance_id: instance_id,
            customer_id: customer_id,
        },
        fetcher
    );

    const card_readers: DCCardReader[] = useMemo(() => {
        const data_array: DCCardReader[] | undefined = data?.data.card_readers;
        if (!data_array) return [];

        return data_array.filter((entry) => !entry.workplace.name?.toLowerCase().includes('hosting'));
    }, [data, error]);

    const readCard = async (value?: string) => {
        const reader: DCCardReader | undefined = card_readers.find((cr) => cr.tenant.id === (value ?? selected_item));
        if (!reader) return;
        console.log({ selected_reader: reader });

        setStep(CardReaderStep.LOADING);
        const token: string | undefined = await getIdToken();
        try {
            const response = await api.post(
                ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.DOC_CIRRUS.READ_CARD,
                {
                    card_reader_id: reader.id,
                    tenant_id: reader.tenant.id,
                    workplace_id: reader.workplace.id,
                    terminal_id: reader.terminals[0].id,
                    request_type: 'CREATE',
                },
                {
                    headers: {
                        customer_id: customer_id,
                        Authorization: token,
                    },
                    params: {
                        instance_id: instance_id,
                    },
                }
            );
            console.log(response);
            setStep(CardReaderStep.SUCCESS);
        } catch (error) {
            console.log(error);
            setStep(CardReaderStep.RETRY);
        }
    };

    const handleSelect = async (value: string) => {
        const new_value: string = selected_item === value ? '' : value;
        setSelectedItem(new_value);

        if (!new_value) return;
        await readCard(new_value);
    };

    const render_step: ReactNode = useMemo(() => {
        switch (step) {
            case CardReaderStep.SELECT_READER:
                if (is_loading && !error)
                    return (
                        <div className="flex h-full items-center justify-center">
                            <Loader />
                        </div>
                    );

                if (!is_loading && card_readers.length === 0)
                    return (
                        <div className="flex h-full items-center justify-center">
                            <Paragraph>Keine Kartenlesegeräte gefunden</Paragraph>
                        </div>
                    );

                return (
                    <div className="flex flex-col gap-2">
                        <SelectableList
                            title="Kartenlesegeräte"
                            handleSelect={handleSelect}
                            selectedItem={selected_item}
                        >
                            {card_readers.map((reader) => (
                                <div key={reader.tenant.id}>
                                    <Paragraph>{`${reader.tenant.name} - ${reader.terminals[0].name}`}</Paragraph>
                                </div>
                            ))}
                        </SelectableList>
                    </div>
                );
            case CardReaderStep.INSERT_CARD:
                return (
                    <div className="flex h-full items-center justify-center">
                        <VerticalIconWithText
                            icon={<IdCard className="size-16" />}
                            text="Stecken Sie die Karte in das Terminal"
                        />
                    </div>
                );
            case CardReaderStep.RETRY:
                return (
                    <div className="flex h-full items-center justify-center">
                        <VerticalIconWithText
                            icon={<TriangleAlert className="size-16" />}
                            text="Es gab ein Problem beim Lesen der Karte"
                        >
                            <Button onClick={() => readCard()}>Noch einmal versuchen</Button>
                        </VerticalIconWithText>
                    </div>
                );
            case CardReaderStep.SUCCESS:
                return (
                    <div className="flex h-full items-center justify-center">
                        <VerticalIconWithText
                            icon={<CircleCheck className="size-16" />}
                            text="Karte erfolgreich gelesen"
                        >
                            <Button onClick={() => {}}>Zur Buchung</Button>
                        </VerticalIconWithText>
                    </div>
                );
            case CardReaderStep.LOADING:
                return (
                    <div className="flex h-full items-center justify-center">
                        <VerticalIconWithText icon={<CircularLoader />} text="Karte wird gelesen..." />
                    </div>
                );
            default:
                return null;
        }
    }, [step, selected_item, is_loading, error, card_readers]);

    return <div className="h-full">{render_step}</div>;
};

export default CardReader;
