import { Card, CheckIcon, Radio } from '@mantine/core';
import { Printer, PrinterCheck } from 'lucide-react';
import { FC } from 'react';

interface Props {
    label: string;
    value: string;
    checked: boolean;
    onChange: (value: string) => void;
}

const RadioCard: FC<Props> = ({ label, value, checked, onChange }) => {
    return (
        <Card
            className="flex w-1/2 cursor-pointer gap-2"
            shadow="sm"
            radius="md"
            withBorder
            onClick={() => onChange(value)}
        >
            <div className="flex w-full justify-between gap-2">
                <Radio
                    label={label}
                    value={value}
                    icon={CheckIcon}
                    color="#88e59c"
                    iconColor="#000"
                    checked={checked}
                    onChange={(event) => onChange(event.currentTarget.value)}
                />
                {checked ? <PrinterCheck /> : <Printer />}
            </div>
        </Card>
    );
};

export default RadioCard;
