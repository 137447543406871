import { create } from 'zustand';

import { getDefaultRoomIds } from '@/utils/filterUtils.ts';

type FilterStore = {
    date: Date | null;
    search_text: string;
    selected_rooms: string[];
    selected_practitioners: string[];
    // TODO: extend global filter
    // calendars
};

export const useFilterStore = create<FilterStore>(() => ({
    date: new Date(),
    search_text: '',
    selected_rooms: getDefaultRoomIds(),
    selected_practitioners: [],
}));
