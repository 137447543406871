interface FormTemplate {
    id: string;
    professional_id: string;
    customer_id: string; // TODO(ezekiel): change primary key from professional to customer_id
    is_custom: boolean;
    template_key?: string;

    form_name_de?: string;
    form_name_en?: string;

    status: FormTemplateStatus;
    professional: AppointmentTypeData;
    content: FormTemplateContent | CustomFormTemplateContent;
    configuration: FormTemplateConfig;
    meta: FormTemplateMeta;
}

interface FormTemplateDto {
    id?: string;
    professional_id: string;
    is_custom: boolean;
    template_key?: string;

    form_name_de?: string;
    form_name_en?: string;

    status: FormTemplateStatus;
    professional: AppointmentTypeData;
    content: FormTemplateContent | CustomFormTemplateContent;
    configuration: FormTemplateConfig;
}

interface FormTemplateWrapper {
    body: FormTemplateDto;
}

interface FormTemplateContent {
    name: string;
    text: FormTemplateI18n;
    consent_text?: FormTemplateI18n;
}

interface CustomFormTemplateContent {
    categories: Category[];
}

interface Category {
    position: number;
    name_de: string;
    name_en: string;
    questions: Question[];
}

export interface Question {
    position: number;
    key: string;
    is_required: boolean;
}

interface FormTemplateConfig {
    frequency_type: FrequencyType;
    appointment_types: AppointmentTypeData[]; // FormTemplateAppointmentType[];
    signature_type: SignatureType;
    insurance_types: InsuranceType[]; // ENUM: PUBLIC | PRIVATE | SELF_PAYER
}

interface FormTemplateI18n {
    de: string;
    en: string;
}

interface AppointmentTypeData {
    id: string;
    key: string;
}

interface FormTemplateMeta {
    created_at: string; // ISO
    updated_at: string; // ISO
}

export enum FormTemplateStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    ARCHIVED = 'archived',
}

enum FrequencyType {
    ONCE_PER_APPOINTMENT = 'once-per-appointment',
    ONCE_PER_PROFESSIONAL = 'once-per-professional',
}

export enum SignatureType {
    DIGITAL = 'digital',
    PAPER = 'paper',
    UNKNOWN = 'unknown',
}

export enum InsuranceType {
    PUBLIC = 'PUBLIC',
    PRIVATE = 'PRIVATE',
    SELF_PAYER = 'SELF_PAYER',
}

export type {
    FormTemplate,
    FormTemplateDto,
    FormTemplateWrapper,
    FormTemplateMeta,
    FormTemplateContent,
    CustomFormTemplateContent,
};
