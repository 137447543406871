import { theme } from '@/theme.ts';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import '@mantine/spotlight/styles.css';
import { Amplify } from 'aws-amplify';
import { dequal } from 'dequal';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { SWRConfig } from 'swr';

import App from './App.tsx';
import config from './amplifyconfiguration.json';
import './index.css';

Amplify.configure({
    ...config,
    // @ts-expect-error configuration type
    oauth: {
        ...config.oauth,
        redirectSignIn: `${window.location.origin}/`,
        redirectSignOut: `${window.location.origin}/`,
    },
});

createRoot(document.getElementById('root')!).render(
    <StrictMode>
        <MantineProvider theme={theme}>
            <ModalsProvider>
                <SWRConfig
                    value={{
                        compare: dequal, // TODO: check performance impact
                    }}
                >
                    <Notifications />
                    <App />
                </SWRConfig>
                ;
            </ModalsProvider>
        </MantineProvider>
    </StrictMode>
);
