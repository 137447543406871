import { IndicatorStatus } from '@/models/IndicatorStatus.ts';
import { PatientInsurance } from '@/models/Patient.ts';

export enum AppointmentBookStatus {
    PENDING_BOOKING = 'PENDING_BOOKING',
    BOOKED = 'BOOKED',
    CANCELLED = 'CANCELLED',
    COMPLETED = 'COMPLETED',
    DECLINED = 'DECLINED',
}

export interface Appointment {
    id: string;
    user_id: string; // Eterno user id or ext::hash()
    is_eterno_user?: boolean;
    day: string; // 2025-01-23
    status: AppointmentBookStatus;
    location_key: string;
    sub_loc_key?: string;
    is_online: boolean;
    customer_id: string;

    is_active: boolean; // True: In the Future & (Not Cancelled || Cancelled Confirmed) | False: Past || (Cancelled & Cancelled Confirmed)
    is_cancellation_confirmed: boolean; // False: default
    is_mapped: boolean;
    is_feedback_event_sent: boolean;

    name: string;
    description: string;

    start: string; // ISO DATETIME
    end: string; // ISO DATETIME
    duration: {
        value: number;
        units: string; // always MINUTES
    };

    professional: {
        id: string;
        key: string;
        type_key: string;
    };

    links: {
        patient_id: string; // doc_cirrus_user_id
        calendar_id: string;
        schedule_id?: string;

        schedule_type_id: string; // doc_cirrus_schedule_type_id
        room_id: string;
        practitioner_id?: string;
        location_id?: string;
        resource_id?: string;
        conference_id?: string;

        dc_instance_id?: string;

        online_url?: string;
    };

    data: {
        patient_name?: string;
        generated_id?: string;

        documents: Document[];
        info: string;
        symptoms: Symptom[];

        services: Service[];

        is_for_self: boolean;
        is_returning: boolean;

        is_with_prescription: boolean;

        is_anamnesis_shared?: boolean; // TODO
        is_insurance_card_active: boolean;

        insurance_coverage_type?: string; // public, private, selfpayer
    };

    // TODO: replace with correct property and type on backend change
    priority?: Priority;

    is_external?: boolean;

    meta: {
        created_at: string;
        updated_at: string;
    };
}

export interface DailyAppointment extends Appointment {
    patient: {
        id: string;

        name: {
            first_name: string; // firstname
            middle_name?: string; // middlename
            last_name: string; // lastname
            salutation: string; // [ "MR", "MS", "NONE" ]
            gender: string; // [  "", "MALE", "FEMALE", "VARIOUS", "UNKNOWN", "UNDEFINED" ]
        };

        account: {
            date_of_birth: string; // "1985-11-13T09:00:00.000Z"
            patient_number?: string;
        };

        pvs: {
            instance_id: string;
            user_id: string;
        };

        contact: {
            email: string;
            phone: string;
        };

        address: {
            street: string;
            zip: string;
            city: string;
        };

        insurance: PatientInsurance;
    };
}

export interface Service {
    name: string;
    key?: string;
}

export interface Symptom {
    name: string;
    key?: string;
}

export enum Insurance {
    PUBLIC = 'GKV',
    PRIVATE = 'PKV',
    // TODO: decide which self payer key we will stick to
    SELFPAYER = 'Selbszahler',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    SELF_PAYER = 'Selbszahler',
}

export enum Priority {
    NONE = 'NONE',
    LOW = 'LOW',
    NORMAL = 'NORMAL',
    IMPORTANT = 'IMPORTANT',
    VERYIMPORTANT = 'VERYIMPORTANT',
    CRITICAL = 'CRITICAL',
}

// TODO: move into translation
export enum PriorityDETranslation {
    NONE = 'Keine',
    LOW = 'Niedrig',
    NORMAL = 'Normal',
    IMPORTANT = 'Wichtig',
    VERYIMPORTANT = 'Sehr Wichtig',
    CRITICAL = 'Kritisch',
}

interface Requirement {
    status: IndicatorStatus;
    text: string;
}

export enum AppointmentStatus {
    IN_ROOM = 'Zimmer',
    ONLINE = 'Online',
    WAITING = 'Im Warteraum',
    CHECKING_IN = 'Im Check-in',
    EXPECTED = 'Erwartet',
    DONE = 'Fertig',
    CANCELLED = 'Abgesagt',
}

// TODO: To be merged with the `Appointment` interface
export interface AppointmentRow {
    id: string;
    patient_name: string;
    insurance: Insurance;
    practitioner: string;
    appointment_type: string;
    expected_time: string;
    arrival_time?: string;
    requirements: Requirement[];
    dc_profile: string;
    status: AppointmentStatus;
    room_number?: number;
}

// TODO: change to use either ids or full objects
export interface AppointmentFormData {
    practice_doc_cirrus_id: string;
    insurance: Insurance;
    apt_type_id_or_schedule_type_dc_id: string;
    calendar_doc_cirrus_id: string;
    priority: Priority;
    duration: number;
    date_time: string;
    notes?: string;
}
