import { AppointmentBookingFormValues } from '@/models/appointment/AppointmentBookingFormValues.ts';

import { CollapsedAppointmentData } from '@/components/AppointmentScheduler/CollapsedAppointment.tsx';

import useAppointmentBookingFormOptions from '@/hooks/useEnrichedPractitionerData.ts';

import { filterEnrichedDataByPracticeId } from '@/utils/practitioner/cachedDataUtils.ts';

const useAppointmentData = () => {
    const { data: booking_form_options } = useAppointmentBookingFormOptions();

    const getAppointmentDataFromIds = (
        apt_forms_data: AppointmentBookingFormValues
    ): (CollapsedAppointmentData & { calendar_id: string }) | undefined => {
        if (!booking_form_options) {
            return undefined;
        }

        const filtered_data_by_practice = filterEnrichedDataByPracticeId(
            booking_form_options,
            apt_forms_data.practice_doc_cirrus_id
        );

        const practitioner = filtered_data_by_practice?.practitioners.find((p) =>
            p.calendars.some((c) => c.doc_cirrus.id === apt_forms_data.calendar_doc_cirrus_id)
        );

        const appointment_type = filtered_data_by_practice?.appointment_types.find(
            (a) => a.id === apt_forms_data.apt_type_id_or_schedule_type_dc_id
        );

        const unmapped_schedule_type = filtered_data_by_practice?.unmapped_schedule_types.find(
            (a) => a.doc_cirrus.dc_schedule_type_id === apt_forms_data.apt_type_id_or_schedule_type_dc_id
        );

        if (!practitioner || !(appointment_type || unmapped_schedule_type)) {
            console.debug(`Issue on rendering collapsed appointment`);
            return undefined;
        }

        return {
            is_mapped: Boolean(appointment_type),
            practitioner: practitioner,
            appointment_type: appointment_type,
            unmapped_schedule_type: unmapped_schedule_type,
            date_time: apt_forms_data.date_time,
            duration: apt_forms_data.duration,
            priority: apt_forms_data.priority,
            notes: apt_forms_data.notes ?? '',
            insurance: apt_forms_data.insurance,
            calendar_id: apt_forms_data.calendar_doc_cirrus_id,
        };
    };

    return { getAppointmentDataFromIds };
};

export default useAppointmentData;
