import { NotificationLog } from '@/models/Notification.ts';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type NotificationStore = {
    //TODO: change to map with custom serialization/deserialization
    notifications_by_user: {
        [key: string]: NotificationLog[];
    };
    addNotification: (user_email: string, notification: NotificationLog) => () => void;
};

export const useNotificationStore = create<NotificationStore>()(
    persist(
        (set) =>
            ({
                notifications_by_user: {},
                addNotification: (user_email: string, notification: NotificationLog) => () =>
                    set((state: NotificationStore) => ({
                        notifications_by_user: {
                            ...state.notifications_by_user,
                            [user_email]: [...state.notifications_by_user[user_email], notification],
                        },
                    })),
            }) as NotificationStore,
        {
            name: 'notification_store',
        }
    )
);
