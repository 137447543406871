import { Appointment } from '@/models/appointment/Appointment.ts';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type AppointmentState = {
    is_checking_in: boolean;
};

export type AppointmentCheckinStore = {
    is_config_auto_checkin: boolean;
    appointments: Record<Appointment['id'], AppointmentState>;
    setConfigAutoCheckin: (is_config_auto_checkin: boolean) => void;
    getAppointment: (appointment_id: string) => undefined | AppointmentState;
    setAppointmentCheckingIn: (appointment: Appointment['id'], is_checking_in: boolean) => void;
};

export const useAppointmentCheckinStore = create<AppointmentCheckinStore>()(
    persist(
        (set, get) => ({
            is_config_auto_checkin: false,
            appointments: {},
            // use methods below
            setConfigAutoCheckin: (is_config_auto_checkin) => set({ is_config_auto_checkin }),
            getAppointment: (appointment_id: string) => get().appointments[appointment_id],
            setAppointmentCheckingIn: (appointment_id, is_checking_in) => {
                set((state) => ({
                    appointments: {
                        ...state.appointments,
                        [appointment_id]: { is_checking_in },
                    },
                }));
            },
        }),
        {
            name: 'appointment_checkin_store',
            partialize: (state) => ({ is_config_auto_checkin: state.is_config_auto_checkin }),
        }
    )
);
