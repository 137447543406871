import { TextInput } from '@mantine/core';
import { Search } from 'lucide-react';
import { FC } from 'react';

interface Props {
    placeholder: string;
    search_value: string;
    onSearchChange: (value: string) => void;
}

const TextFilter: FC<Props> = ({ placeholder, search_value, onSearchChange }) => {
    return (
        <TextInput
            placeholder={placeholder}
            value={search_value}
            onChange={(event) => onSearchChange(event.currentTarget.value)}
            rightSectionPointerEvents="none"
            rightSection={<Search className="size-6" />}
            className="flex-grow"
        />
    );
};

export default TextFilter;
