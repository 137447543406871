import { isDevEnvironment } from '@/utils/envUtils.ts';

const use_dev_prefix: boolean = isDevEnvironment();

const patient_app_base_url = `https://${use_dev_prefix ? 'dev.' : ''}patients.eterno-health.io`; // TODO: account for cloud customers
const patients_base_url = `https://api.${use_dev_prefix ? 'dev.' : ''}patients.eterno-health.io`;
// Practitioners base URL in Prod is `professionals` not `practitioners`, for example: https://api.professionals.eterno-health.io/public/users/availability/search
const practitioners_base_url = `https://api.${use_dev_prefix ? 'dev.practitioners' : 'professionals'}.eterno-health.io`;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ServiceUrl = {
    PATIENT_APP: {
        BASE: patient_app_base_url,
        ALL_FORMS_CODE: `${patient_app_base_url}/all-forms-code`,
    },
    PRACTITIONER_API_DOMAIN: {
        SECURE_ADMIN: {
            USER: {
                FORM_DATA: `${practitioners_base_url}/secure-admin/user/form-data`,
                AVAILABILITY_CACHED_DATA: `${practitioners_base_url}/secure-admin/users/availability/cached-data`,
            },
        },
        PUBLIC: {
            USERS: {
                AVAILABILITY: `${practitioners_base_url}/public/users/availability`,
            },
        },
    },
    PATIENT_API_DOMAIN: {
        SECURE_ADMIN: {
            CALL_SYSTEM_API: `${patients_base_url}/secure-admin/call-system`,
            USER: {
                FORM_DATA: `${patients_base_url}/secure-admin/user/form-data`,
                DOCUMENTS_DATA: `${patients_base_url}/secure-admin/user/documents`,
                GET_APPOINTMENTS_FOR_TODAY: `${patients_base_url}/secure-admin/user/appointments/today`,
                UPDATE_APPOINTMENT_ROOM: `${patients_base_url}/secure-admin/user/appointments/update-room`,
                GET_APPOINTMENT_BY_ID: `${patients_base_url}/secure-admin/user/appointments`,
                BOOK_APPOINTMENT: `${patients_base_url}/secure-admin/user/appointments/book-admin`,
            },
            DOC_CIRRUS: {
                SEARCH: `${patients_base_url}/secure-admin/doc-cirrus/users/search`,
                CREATE_USER: `${patients_base_url}/secure-admin/doc-cirrus/user/create`,
                GET_USER: `${patients_base_url}/secure-admin/doc-cirrus/users`,
                CARD_READERS: `${patients_base_url}/secure-admin/doc-cirrus/card-readers`,
                READ_CARD: `${patients_base_url}/secure-admin/doc-cirrus/card-readers/read`,
                UPDATE_SCHEDULE_PARTIAL: `${patients_base_url}/secure-admin/doc-cirrus/schedules/update/partial`,
            },
        },
    },
};
