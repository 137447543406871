import { api } from '@/lib/api-client.ts';
import { EnrichedLocation } from '@/models/EnrichedPrismicDocuments.ts';
import { NotificationType } from '@/models/Notification.ts';
import { PractitionerDtoWrapper } from '@/models/PractitionerDto.ts';
import { Room } from '@/models/Room.ts';
import { Appointment, AppointmentBookStatus } from '@/models/appointment/Appointment.ts';
import { BookAppointmentDto } from '@/models/appointment/BookAppointmentDto.ts';
import { Practitioner } from '@/models/appointment/EnrichedPractitionerData.ts';
import { GetAppointmentsForTodayDto } from '@/models/appointment/GetAppointmentsForTodayDto.ts';
import { UserAppointmentDto } from '@/models/call-system/dtos/user-appointment-dto.ts';
import { CheckinStatus, CheckoutStatus, UserStatus } from '@/models/call-system/models/CallSystemUser.ts';
import FormattedForm from '@/models/forms/FormattedForm.ts';
import { notifications } from '@mantine/notifications';
import { v4 as uuidv4 } from 'uuid';

import { updateUserByNumber } from '@/services/callSystemService.ts';
import { generateCode, getRequiredAppointmentForms } from '@/services/formDataService.ts';
import { showErrorNotification, showWarningNotification } from '@/services/notificationService.ts';

import { useAppointmentCheckinStore } from '@/stores/appointmentCheckinStore.ts';
import { BookingBatch, useBookingStore } from '@/stores/bookingStore.ts';
import { useConfigStore } from '@/stores/configStore.ts';
import { usePrinterStore } from '@/stores/printerStore.ts';

import { buildUrlWithQueryParams } from '@/utils/apiUtils.ts';
import { isEternoAppointment } from '@/utils/appointmentUtils.ts';
import { getCustomerId } from '@/utils/customerUtils.ts';
import { isToday } from '@/utils/dateUtils.ts';
import { dayjs } from '@/utils/dayjsSetup.ts';
import { prepareGenerateCodeBody } from '@/utils/formUtils.ts';
import { openQrCodeModal, openTickerNumberModal } from '@/utils/modalUtils.tsx';
import { prepareCheckinData, printTicket, updatePcs } from '@/utils/printUtils.ts';

import { ServiceUrl } from './ServiceURL.ts';
import { updateNotification } from './notificationService.ts';

enum UserDataType {
    LINKED_USERS = 'linked_users',
    PRISMIC_PROFILES = 'prismic_profiles',
    PRISMIC_HUBS = 'prismic_hubs',
    PRISMIC_CUSTOMER_CONFIGS = 'prismic_customer_configs',
    PRISMIC_PROFESSIONAL_TYPES = 'prismic_professional_types',
    PRISMIC_SERVICES_OR_TREATMENTS = 'prismic_services_or_treatments',
}

enum UserDataTypeOption {
    INCLUDE_UNMAPPED_SCHEDULE_TYPES = 'include_unmapped_schedule_types',
}

/**
 * Fetch all practitioner infos from availability cached data.
 */
export const fetchPractitionerByCustomerId = async (customer_id: string): Promise<PractitionerDtoWrapper> => {
    try {
        const api_url = buildUrlWithQueryParams(
            `${ServiceUrl.PRACTITIONER_API_DOMAIN.SECURE_ADMIN.USER.AVAILABILITY_CACHED_DATA}`,
            {
                dataType: UserDataType.LINKED_USERS,
                [UserDataTypeOption.INCLUDE_UNMAPPED_SCHEDULE_TYPES]: 'true',
            }
        );

        // Make the API request
        const response = await api.get(api_url, {
            headers: {
                customer_id: customer_id,
            },
        });

        return response.data.data;
    } catch (error) {
        // TODO: make this better
        throw new Error(`Wrong API call: ${error}`);
    }
};

export const fetchAppointmentsForDate = async (
    customer_id: string,
    instance_id: string,
    selected_date?: Date | null
): Promise<GetAppointmentsForTodayDto> => {
    try {
        if (!customer_id || !instance_id) {
            throw new Error(`missing customer id or instance id`);
        }

        // today by default
        const localized_date: string = dayjs(selected_date).format('YYYY-MM-DD');

        const api_url = buildUrlWithQueryParams(
            `${ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.USER.GET_APPOINTMENTS_FOR_TODAY}`,
            {
                instance_id: instance_id,
                day: localized_date,
            }
        );

        // Make the API request
        const response: { data: GetAppointmentsForTodayDto } = await api.get(api_url, {
            headers: {
                customer_id: customer_id,
            },
        });

        return response.data;
    } catch (error) {
        // TODO: make this better
        throw new Error(`Wrong API call: ${error}`);
    }
};

export const fetchAppointmentsCheckInData = async (customer_id: string, instance_id: string, date: string) => {
    try {
        const api_url = buildUrlWithQueryParams(
            `${ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.CALL_SYSTEM_API}/users/day`,
            {
                instance_id: instance_id,
                day: dayjs(date).format('YYYY-MM-DD'),
            }
        );

        const response = await api.get(api_url, {
            headers: {
                customer_id: customer_id,
            },
        });

        const checkin_data: UserAppointmentDto[] = response.data;

        return checkin_data.filter((entry) => entry.user_status === UserStatus.KNOWN);
    } catch (error) {
        throw new Error(`Error fetching check-in data: ${error}`);
    }
};

export const updateAppointmentRoom = async (appointment: Appointment, room_id: string): Promise<boolean> => {
    try {
        const is_eterno_appointment = isEternoAppointment(appointment);

        if (is_eterno_appointment) {
            const api_url = `${ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.USER.UPDATE_APPOINTMENT_ROOM}`;
            await api.patch(api_url, undefined, {
                params: {
                    appointment_id: appointment.id,
                    user_id: appointment.user_id,
                    room_id: room_id,
                    instance_id: appointment.links.dc_instance_id,
                },
                headers: {
                    customer_id: getCustomerId(),
                },
            });
        } else {
            const api_url = `${ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.DOC_CIRRUS.UPDATE_SCHEDULE_PARTIAL}/${appointment.id}`;
            await api.patch(
                api_url,
                {
                    roomId: room_id,
                },
                {
                    params: {
                        instance_id: appointment.links.dc_instance_id,
                        dc_user_id: appointment.links.patient_id,
                    },
                    headers: {
                        customer_id: getCustomerId(),
                    },
                }
            );
        }

        return true;
    } catch {
        // TODO: make this better
        showErrorNotification(
            `Beim Aktualisieren des Raums ist ein Problem aufgetreten. Bitte versuchen Sie es später erneut.`
        );
        return false;
    }
};

export const getAppointmentById = async (id: string, user_id: string, customer_id: string): Promise<Appointment> => {
    try {
        if (!customer_id || !id) {
            throw new Error(`missing customer id or id`);
        }

        const api_url = buildUrlWithQueryParams(
            `${ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.USER.GET_APPOINTMENT_BY_ID}/${id}/`,
            {
                user_id: user_id,
            }
        );

        // Make the API request
        const response: { data: Appointment } = await api.get(api_url, {
            headers: {
                customer_id: customer_id,
            },
        });

        return response.data;
    } catch (error) {
        throw new Error(`Error fetching appointment data: ${error}`);
    }
};

export const bookAppointment = async (
    customer_id: string,
    instance_id: string,
    appointmentBookingData: BookAppointmentDto
): Promise<Appointment> => {
    try {
        if (!customer_id || !instance_id) {
            throw new Error(`missing customer id or instance id`);
        }

        const api_url = buildUrlWithQueryParams(`${ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.USER.BOOK_APPOINTMENT}`, {
            instance_id: instance_id,
            user_id: appointmentBookingData.patient.id,
        });

        // Make the API request
        const response: { data: Appointment } = await api.post(api_url, appointmentBookingData, {
            headers: {
                customer_id: customer_id,
            },
        });

        return response.data;
    } catch (error) {
        // TODO: make this better
        throw new Error(`Error on booking appointment API call: ${error}`);
    }
};

// all in one checkin arrived with printing if activated
// TODO: separate this better
export const prepareAndCheckinArrived = async (
    appointment: Appointment,
    selected_room: Room['room_id'],
    forms_for_appointment: FormattedForm[] | undefined,
    practitioner: Practitioner | undefined,
    onSuccess: () => void,
    onFail: () => void,
    ticket?: string
) => {
    if (!isToday(appointment.day)) {
        showErrorNotification('Man kann nur Termine vom heutigen Tag verschieben.'); // Can only move appointments of today
        onFail();
        return;
    }

    if (ticket) {
        const success = await checkinArrived(
            appointment,
            selected_room,
            ticket,
            forms_for_appointment,
            practitioner,
            onSuccess,
            onFail
        );
        if (success) {
            onSuccess();
        } else {
            onFail();
        }
        return;
    }

    if (!forms_for_appointment)
        forms_for_appointment = (await getRequiredAppointmentForms(appointment)).formatted_forms_for_appointment;
    const forms_to_fill = forms_for_appointment?.filter((form) => form.is_completed === false);

    const { is_config_auto_checkin } = useAppointmentCheckinStore.getState();

    const selected_location = useConfigStore.getState().selected_location ?? ({} as EnrichedLocation);
    const is_call_system_active = selected_location?.config.is_patient_call_system_active;
    if (is_call_system_active && is_config_auto_checkin) {
        const selected_printer = usePrinterStore.getState().selected_printer;

        const [success, print_ticket] = await printTicket(selected_location, selected_printer[selected_location.key]);
        if (!success || !print_ticket) {
            // TODO: handle it better by opening a modal with options: retry, manual, select printer
            showWarningNotification(
                'Ein Problem ist beim Drucken aufgetreten. Bitte führen Sie einen manuell aufnehmen'
            ); // A problem happened with the printer. Please do a manuak checkin
            onFail();
            return;
        }

        await checkinArrived(appointment, selected_room, print_ticket, forms_to_fill, practitioner, onSuccess, onFail);
    } else {
        if (is_call_system_active) {
            openTickerNumberModal(async (ticket) => {
                await checkinArrived(
                    appointment,
                    selected_room,
                    ticket,
                    forms_to_fill,
                    practitioner,
                    onSuccess,
                    onFail
                );
            }, onFail);
        } else {
            await checkinArrived(appointment, selected_room, uuidv4(), forms_to_fill, practitioner, onSuccess, onFail);
        }
    }
};

export const checkinArrived = async (
    appointment: Appointment,
    selected_room: Room['room_id'],
    ticket: string,
    formatted_forms_for_appointment: FormattedForm[] | undefined,
    practitioner: Practitioner | undefined,
    onSuccess: () => void,
    onFail: () => void
): Promise<boolean> => {
    if (!isToday(appointment.day)) {
        showErrorNotification('Man kann nur Termine vom heutigen Tag verschieben.'); // Can only move appointments of today
        onFail();
        return false;
    }

    if (selected_room !== appointment.links.room_id) {
        const update_room_status = await updateAppointmentRoom(appointment, selected_room);
        if (!update_room_status) {
            showErrorNotification('Ein Problem ist aufgetreten, als der Patient in den Raum verlegt wurde.');
            onFail();
            return false;
        }
    }

    const selected_location = useConfigStore.getState().selected_location ?? ({} as EnrichedLocation);
    const checkin_data = prepareCheckinData(
        appointment,
        selected_location,
        ticket,
        CheckinStatus.ARRIVED,
        CheckoutStatus.PENDING
    );
    const [checkin_success] = await updateUserByNumber(checkin_data);

    if (!checkin_success) {
        showErrorNotification('Beim Einchecken ist ein Problem aufgetreten');
        onFail();
        return false;
    }

    if (
        selected_location.config.is_patient_call_system_active &&
        selected_location.config.is_new_checkin_available &&
        (!formatted_forms_for_appointment || formatted_forms_for_appointment.length === 0)
    ) {
        // print stack
        const selected_printer = usePrinterStore.getState().selected_printer;
        const selected_location = useConfigStore.getState().selected_location ?? ({} as EnrichedLocation);
        const [success_pcs] = await updatePcs(
            selected_location,
            selected_printer[selected_location.key],
            ticket,
            practitioner
        );
        if (!success_pcs) {
            showWarningNotification('Ein Problem ist beim Drucken aufgetreten. Abbrechen'); // A problem happened with the printer. Cancelling
            onFail();
            return false;
        }

        // do checkin
        const checked_in_success = await checkin(appointment, ticket);
        if (!checked_in_success) {
            showErrorNotification('Beim Einchecken ist ein Problem aufgetreten');
            onFail();
            return false;
        }
    } else {
        if (formatted_forms_for_appointment?.some((form) => !form.is_completed)) {
            const generate_code_body = prepareGenerateCodeBody(
                selected_location,
                appointment,
                formatted_forms_for_appointment!
            );
            if (generate_code_body) {
                const generated_forms_code = await generateCode(generate_code_body);
                if (generated_forms_code) {
                    openQrCodeModal(generated_forms_code);
                }
            }
        }
    }

    onSuccess();
    return true;
};

export const checkin = async (appointment: Appointment, ticket: string): Promise<boolean> => {
    if (!isToday(appointment.day)) {
        showErrorNotification('Man kann nur Termine vom heutigen Tag verschieben.'); // Can only move appointments of today
        return false;
    }

    const selected_location = useConfigStore.getState().selected_location ?? ({} as EnrichedLocation);
    const checkout_data = prepareCheckinData(
        appointment,
        selected_location,
        ticket,
        CheckinStatus.CHECKED_IN,
        CheckoutStatus.PENDING
    );
    const [checkin_success] = await updateUserByNumber(checkout_data);

    return checkin_success;
};

export const checkoutAppointment = async (appointment: Appointment, ticket: string): Promise<boolean> => {
    if (!isToday(appointment.day)) {
        showErrorNotification('Man kann nur Termine vom heutigen Tag verschieben.'); // Can only move appointments of today
        return false;
    }

    const update_room_status = await updateAppointmentRoom(appointment, '');
    if (!update_room_status) return false;

    const selected_location = useConfigStore.getState().selected_location ?? ({} as EnrichedLocation);
    const checkout_data = prepareCheckinData(
        appointment,
        selected_location,
        ticket,
        CheckinStatus.CHECKED_IN,
        CheckoutStatus.CHECKED_OUT
    );
    const [checkout_success] = await updateUserByNumber(checkout_data);

    if (!checkout_success) {
        showErrorNotification('Beim Auschecken ist ein Problem aufgetreten');
        return false;
    }

    return true;
};

export const moveAppointmentToExpected = async (appointment: Appointment, ticket: string): Promise<boolean> => {
    if (!isToday(appointment.day)) {
        showErrorNotification('Man kann nur Termine vom heutigen Tag verschieben.'); // Can only move appointments of today
        return false;
    }

    if (appointment.links.room_id) {
        const update_room_status = await updateAppointmentRoom(appointment, '');
        if (!update_room_status) return false;
    }

    const selected_location = useConfigStore.getState().selected_location ?? ({} as EnrichedLocation);
    const call_system_data = prepareCheckinData(
        appointment,
        selected_location,
        ticket,
        CheckinStatus.PENDING,
        CheckoutStatus.PENDING
    );
    const [call_system_success] = await updateUserByNumber(call_system_data);

    if (!call_system_success) {
        showErrorNotification('Es gab ein Problem beim Verschieben des Termins');
        return false;
    }

    return true;
};

const POLL_INTERVAL = 1000;

export const pollStatus = (
    batch_id: string,
    data: { id: string; user_id: string; customer_id: string; appointment_id: string },
    updateAppointmentStatus: (batchId: string, appointmentId: string, status: AppointmentBookStatus) => void,
    getCurrentBatch: (batchId: string) => BookingBatch | undefined,
    current_patient?: { name: { first_name: string; last_name: string } }
) => {
    const interval = setInterval(async () => {
        const appointment = await getAppointmentById(data.appointment_id, data.user_id, data.customer_id);

        // update internal status
        updateAppointmentStatus(batch_id, data.id, appointment.status);

        if (appointment.status !== AppointmentBookStatus.PENDING_BOOKING) {
            clearInterval(interval);

            // Access batches reactively inside the function
            const batch: BookingBatch | undefined = getCurrentBatch(batch_id);

            if (!batch) {
                return;
            }

            if (batch.appointments.every((apt) => apt.status !== AppointmentBookStatus.PENDING_BOOKING)) {
                // Wait until the entire batch finish to update, otherwise we can update intermediate stare.

                const failed_booking = batch.appointments.some((apt) => apt.status === AppointmentBookStatus.DECLINED);

                const notification_update = {
                    id: batch.notification_id,
                    title: 'Booking completed',
                    message: `${current_patient?.name.first_name} ${current_patient?.name.last_name}`,
                    loading: false,
                    autoClose: false,
                    withCloseButton: true,
                    onClick: () => {
                        console.debug('on notification click');
                        // Prevent to click on notification if modal is already open
                        if (!useBookingStore.getState().is_open) {
                            console.debug('schedule is closed, should be opened');
                            // Set context of the notification
                            useConfigStore.setState({
                                selected_customer: batch.customer,
                                selected_location: batch.location,
                            });
                            // Open scheduler and set context of the scheduler and patient...
                            useBookingStore.setState({
                                current_batch_id: batch.batchId,
                                current_patient: batch.patient,
                                is_open: true,
                            });
                            notifications.hide(batch.notification_id);
                        }
                    },
                };

                if (!failed_booking && batch.appointments.every((apt) => apt.status === AppointmentBookStatus.BOOKED)) {
                    // All appointments are booked
                    notification_update.title = 'Buchung abgeschlossen';
                    updateNotification(NotificationType.SUCCESS, notification_update);
                } else {
                    // TODO: update message
                    // 1 or more appointment failed
                    notification_update.title = 'Die Buchung ist fehlgeschlagen';
                    updateNotification(NotificationType.ERROR, notification_update);
                }
            }
        }

        // TODO: manage failed booking of appointments
    }, POLL_INTERVAL);
};
