import { Appointment } from '@/models/appointment/Appointment.ts';
import { Button } from '@mantine/core';
import { useState } from 'react';

import DisplayForms from '@/components/PatientModal/DisplayForms.tsx';

import { usePatientAppointmentRequiredForms } from '@/hooks/usePatientAppointmentRequiredForms.ts';

import Paragraph from '../text/Paragraph.tsx';
import EditSelectedForms from './EditSelectedForms.tsx';

interface Props {
    appointment: Appointment;
    isEditingFormsCallback: (arg0: boolean) => void;
    disabled: boolean;
}

const PatientAppointmentForms = ({ appointment, isEditingFormsCallback, disabled }: Props) => {
    const [edit_documents, setEditDocuments] = useState(false);
    const { professional_form_templates, formatted_forms_for_appointment, is_loading, mutateSelectedAppointmentForms } =
        usePatientAppointmentRequiredForms(appointment);

    const handleEditDocuments = (new_edit_documents: boolean) => {
        setEditDocuments(new_edit_documents);
        isEditingFormsCallback(new_edit_documents);
    };

    const handleOnSaveForms = async () => {
        await mutateSelectedAppointmentForms();
        // setEditDocuments(false);
        handleEditDocuments(false);
    };

    return (
        <div className="flex flex-col gap-4">
            <div className="flex-start mt-1 w-full">
                <Paragraph className="text-sm uppercase text-gray-500">Dokumenten</Paragraph>
            </div>
            <div className="flex w-full justify-between gap-2 overflow-x-hidden">
                {edit_documents && professional_form_templates && formatted_forms_for_appointment ? (
                    <EditSelectedForms
                        appointment={appointment}
                        professional_form_templates={professional_form_templates}
                        formatted_forms_for_appointment={formatted_forms_for_appointment}
                        onSave={handleOnSaveForms}
                        onCancel={() => handleEditDocuments(false)}
                    />
                ) : (
                    <div className="flex w-full flex-col overflow-x-hidden">
                        <DisplayForms
                            is_loading={is_loading}
                            formatted_forms_for_appointment={formatted_forms_for_appointment}
                        />
                        {!is_loading && (
                            <div className="w-full text-right">
                                <Button
                                    disabled={disabled}
                                    variant="transparent"
                                    className="capitalize underline"
                                    onClick={() => handleEditDocuments(!edit_documents)}
                                >
                                    Bearbeiten
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default PatientAppointmentForms;
