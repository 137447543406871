import { AutoCompleteResult } from '@/models/AddressComplete.ts';
import { uniqBy } from 'es-toolkit';

export const buildDisplayName = (result: AutoCompleteResult): string => {
    return `${result.address.road ?? result.address.name} ${result.address.house_number ?? ''}, ${result.address.postcode} ${result.address.city}`;
};

export const buildResults = (results: AutoCompleteResult[]): AutoCompleteResult[] => {
    const has_full_address = results.some((result: AutoCompleteResult) => result.address.house_number);

    let final_results: AutoCompleteResult[] = results;
    if (has_full_address) {
        final_results = results.filter((result: AutoCompleteResult) => result.address.house_number);
    }

    final_results = filterIncompleteResults(final_results);
    final_results = filterDuplicates(final_results);

    return final_results;
};

export const filterIncompleteResults = (results: AutoCompleteResult[]): AutoCompleteResult[] => {
    const filtered_results: AutoCompleteResult[] = results.filter(
        (result: AutoCompleteResult) => result.address.postcode && result.address.city
    );
    return filtered_results;
};

export const filterDuplicates = (results: AutoCompleteResult[]): AutoCompleteResult[] => {
    const filtered_results: AutoCompleteResult[] = uniqBy(
        results,
        (item: AutoCompleteResult) =>
            `${item.address.road}${item.address.house_number}${item.address.postcode}${item.address.city}`
    );
    return filtered_results;
};
