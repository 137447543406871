import { DailyAppointment } from '@/models/appointment/Appointment.ts';
import { Text } from '@mantine/core';
import { ArrowRight } from 'lucide-react';

import AppointmentScheduler from '@/components/AppointmentScheduler/AppointmentScheduler.tsx';
import AppointmentSchedulerFooter from '@/components/AppointmentScheduler/AppointmentSchedulerFooter.tsx';
import PatientModal from '@/components/PatientModal/PatientModal.tsx';
import TicketInputModal from '@/components/PatientModal/TicketInputModal.tsx';
import FormQRCode from '@/components/form/FormQRCode.tsx';
import Heading2 from '@/components/text/Heading2.tsx';

import { closeModal, openConfirmModal, openModal } from '@/services/modalService.tsx';

import { useBookingStore } from '@/stores/bookingStore.ts';

export const openPatientModal = (patient_id: string, appointment?: DailyAppointment, onClose?: () => void) => {
    if (!appointment || !appointment.patient) return;

    openModal({
        onClose: () => {
            if (onClose) onClose();
        },
        title: appointment.data.patient_name,
        size: 'xl',
        classNames: {
            childrenWrapper: 'h-[75vh]',
        },
        children: <PatientModal appointmentId={appointment.id} />,
    });
};

export const openQrCodeModal = (code: string) => {
    openModal({
        title: 'Lesen Sie den QR-Code',
        children: <FormQRCode code={code} />,
    });
};

export const openTickerNumberModal = (
    handleSave: (ticket: string) => Promise<void>,
    handleCancel?: () => Promise<void> | void
) => {
    const modal_id = `ticket_number_modal`;

    const onCancel = async () => {
        closeModal(modal_id);
        if (handleCancel) await handleCancel();
    };

    const onConfirm = async (ticket: string) => {
        closeModal(modal_id);
        await handleSave(ticket);
    };

    openModal({
        modalId: modal_id,
        title: 'Ticket ausdrucken',
        children: <TicketInputModal onCancel={onCancel} onConfirm={onConfirm} />,
        withCloseButton: false,
        closeOnEscape: false,
        closeOnClickOutside: false,
    });
};

export const openConfirmationModal = (
    text: string,
    title: string | undefined = 'Bestätigung',
    handleConfirm: () => Promise<void> | void,
    handleCancel?: () => Promise<void> | void
) => {
    openConfirmModal({
        title: title,
        children: <Text size="sm">{text}</Text>,
        onCancel: handleCancel,
        onConfirm: handleConfirm,
        withCloseButton: false,
    });
};

export const openAppointmentScheduler = (onClose?: () => void) => {
    const current_patient = useBookingStore.getState().current_patient;

    openModal({
        onClose: () => {
            if (onClose) onClose();
        },
        title: (
            <div className="flex items-center gap-2">
                <Heading2>
                    {current_patient?.name.first_name} {current_patient?.name.last_name}{' '}
                    <ArrowRight className="inline" /> Termindetails eingeben
                </Heading2>
            </div>
        ),
        children: <AppointmentScheduler />,
        footer: <AppointmentSchedulerFooter patient={current_patient} />,
        size: 'xl',
    });
};
