import { Insurance } from '@/models/appointment/Appointment.ts';
import { AppointmentType, Practitioner } from '@/models/appointment/EnrichedPractitionerData.ts';
import { useMemo, useState } from 'react';
import useSWR from 'swr';

import { getAvailableTimeslots } from '@/services/availabilityService.ts';

import { useConfigStore } from '@/stores/configStore.ts';

import { getInsuranceKey } from '@/utils/appointmentUtils.ts';
import { dayjs } from '@/utils/dayjsSetup.ts';

const useTimeslots = (
    practitioner: Practitioner,
    insurance: Insurance,
    appointment_type?: AppointmentType,
    is_returning?: boolean,
    default_start_date?: string
) => {
    const [start_date, setStartDate] = useState(dayjs(default_start_date).format('YYYY-MM-DD'));
    const { selected_location, selected_customer } = useConfigStore((state) => state);

    const { data, isLoading: is_loading } = useSWR(
        appointment_type
            ? [
                  'available-timeslots',
                  insurance,
                  start_date,
                  appointment_type?.id,
                  practitioner.id,
                  is_returning ? 'returning' : 'new',
              ]
            : null,
        () =>
            getAvailableTimeslots({
                customer_id: selected_customer?.customer_id ?? '',
                user_id: practitioner.id,
                location_key: selected_location?.key ?? '',
                appointment_type_key: appointment_type?.prismic_key ?? '',
                insurance_type_key: getInsuranceKey(insurance) ?? '',
                dc_calendar_id:
                    practitioner.calendars.find((cal) => cal.appointment_type_ids.includes(appointment_type?.id ?? ''))
                        ?.doc_cirrus.id ?? '',
                start_date: start_date,
                is_returning: is_returning,
            }),
        { refreshInterval: 20000 }
    );

    const has_slots = useMemo(() => data?.availability?.slice(0, 3).some(({ slots }) => slots.length > 0), [data]);
    const first_date_with_slots = useMemo(
        () => data?.availability?.find(({ slots }) => slots.length > 0)?.date,
        [data]
    );

    return {
        availability: data?.availability?.slice(0, 3),
        is_loading: is_loading,
        next_available_slot: data?.next_slot || first_date_with_slots,
        has_slots: has_slots,
        start_date: start_date,
        setStartDate: setStartDate,
    };
};

export default useTimeslots;
