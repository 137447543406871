import { PrismicDataType } from '@/models/PrismicHookTypes.ts';
import { DailyAppointment, Priority } from '@/models/appointment/Appointment.ts';
import { UserAppointmentDto } from '@/models/call-system/dtos/user-appointment-dto.ts';
import { Button, Card } from '@mantine/core';
import { FC, useMemo } from 'react';

import PriorityMarker from '@/components/PriorityMarker.tsx';
import AppointmentCardHeader from '@/components/room-view/AppointmentCard/AppointmentCardHeader.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import usePrismic from '@/hooks/usePrismic.tsx';

import { useAppointmentCheckinStore } from '@/stores/appointmentCheckinStore.ts';

import { dayjs } from '@/utils/dayjsSetup.ts';
import { getInsuranceByKey } from '@/utils/patientUtils.ts';

interface Props {
    appointment: DailyAppointment;
    checkInData?: UserAppointmentDto;
    onClick: (appointment: DailyAppointment) => void;
    onCardClick?: (appointment: DailyAppointment) => void;
    isDischarged?: boolean;
}

const ResolvedAppointmentCard: FC<Props> = ({ appointment, onClick, checkInData, isDischarged = false }) => {
    const insurance = useMemo(() => getInsuranceByKey(appointment.data.insurance_coverage_type), [appointment]);
    const { data: professional_profiles } = usePrismic(PrismicDataType.PROFESSIONAL_PROFILE);
    const { appointments: appointments_checkin } = useAppointmentCheckinStore();

    // Missing patient id are schedule created on DC without any patient attached. We don't support them.
    const missing_patient_id = appointment.links.patient_id === undefined || appointment.links.patient_id === '-';
    const loading_patient =
        appointments_checkin[appointment.id]?.is_checking_in || (!missing_patient_id && !appointment.data.patient_name);
    const disabled = missing_patient_id || loading_patient;

    const handleButtonClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        // TODO: Uncomment when ready to enable the onClick handler
        // onClick(appointment);
        console.debug('click');
    };

    return (
        <Card
            className="flex-row items-center justify-between gap-2 rounded-lg p-6 shadow-md"
            withBorder
            onClick={() => onClick(appointment)}
        >
            <PriorityMarker priority={appointment.priority ?? Priority.NORMAL} />
            <div className="flex flex-col gap-1.5">
                <AppointmentCardHeader
                    patientName={appointment.data.patient_name}
                    insurance={insurance}
                    isInsuranceCardActive={appointment.data.is_insurance_card_active}
                    date={appointment.start}
                    loading={loading_patient}
                    checkinData={checkInData}
                    isOnline={appointment.is_online}
                    isDischarged={isDischarged}
                />
                <Paragraph className="text-sm text-slate-500">{`${dayjs(appointment.start).format('HH:mm')} | ${appointment.data.services[0]?.name} | ${professional_profiles?.get(appointment.professional.key)?.display_name[0]?.text}`}</Paragraph>
            </div>
            <Button
                disabled={disabled}
                variant={appointment.is_active ? 'filled' : 'outline'}
                onClick={handleButtonClick}
            >
                {appointment.is_active ? 'Entlassen' : 'Folgetermin'}
            </Button>
        </Card>
    );
};

export default ResolvedAppointmentCard;
