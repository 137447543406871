import { TableGroup } from '@/models/TableGroup.ts';
import { AppointmentRow, AppointmentStatus } from '@/models/appointment/Appointment.ts';
import { Anchor, Button, Pill, Table } from '@mantine/core';
import { clsx } from 'clsx';
import { ChevronsUpDown } from 'lucide-react';
import { FC, ReactNode } from 'react';

import StatusLamp from '@/components/StatusLamp.tsx';
import TableRow from '@/components/TableRow.tsx';
import Heading2 from '@/components/text/Heading2.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import useSortObjects from '@/hooks/useSortObjects.ts';

import { dayjs } from '@/utils/dayjsSetup.ts';

interface Props {
    title: string;
    group: TableGroup;
    appointments: AppointmentRow[];
    isDescendingByDefault?: boolean;
}

const AppointmentTable: FC<Props> = ({ title, group, appointments, isDescendingByDefault = false }) => {
    const { sorted, setSortBy } = useSortObjects({
        unsorted: appointments,
        default_field: 'expected_time',
        is_descending_by_default: isDescendingByDefault,
    });

    const widths = [7, 7, 25, 25, 16, 10, 10];
    const columns: { label: string; field?: keyof AppointmentRow }[] = [
        { label: 'Erwartet', field: 'expected_time' },
        { label: 'Ankunft', field: 'arrival_time' },
        { label: 'Vor- und Nachname', field: 'patient_name' },
        { label: 'Behandler:in', field: 'practitioner' },
        { label: 'Bitte beachten' },
        { label: 'DC Profil' },
        { label: 'Status' },
    ];

    const onSort = (selected_field: keyof AppointmentRow) => {
        setSortBy(({ field, is_descending }) => ({
            field: selected_field,
            is_descending: selected_field === field ? !is_descending : isDescendingByDefault,
        }));
    };

    // TODO: replace with memoized value
    const createRow = (appointment: AppointmentRow): ReactNode => {
        const expected_time = dayjs(appointment.expected_time).format('HH:mm');
        const arrival_time = appointment.arrival_time ? dayjs(appointment.arrival_time).format('HH:mm') : '--';
        const is_active =
            group === TableGroup.IN_PROGRESS &&
            (appointment.status === AppointmentStatus.IN_ROOM || appointment.status === AppointmentStatus.ONLINE);

        return [
            expected_time,
            arrival_time,
            `${appointment.patient_name} (${appointment.insurance})`,
            <>
                <Paragraph>{appointment.practitioner}</Paragraph>
                <Paragraph className="font-medium">{appointment.appointment_type}</Paragraph>
            </>,
            <>
                {appointment.requirements.map((requirement) => (
                    <StatusLamp
                        key={`${appointment.id}_requirement_${requirement.text}`}
                        status={requirement.status}
                        text={requirement.text}
                    />
                ))}
            </>,
            <Anchor
                key={`${appointment.id}_dc_profile`}
                href={appointment.dc_profile}
                target="_blank"
                underline="always"
            >
                Patientenakte
            </Anchor>,
            <Pill key={`${appointment.id}_status`} className={clsx(is_active && 'bg-green-100 text-green-700')}>
                {appointment.status === AppointmentStatus.IN_ROOM
                    ? `${AppointmentStatus.IN_ROOM} ${appointment.room_number}`
                    : appointment.status}
            </Pill>,
        ];
    };

    if (appointments.length === 0) return null;

    return (
        <div className="flex w-full flex-col gap-6">
            <Heading2 className="font-medium">{title}</Heading2>
            <Table layout="fixed">
                <Table.Thead className="h-8 border-b">
                    <Table.Tr>
                        {columns.map((col, index) => (
                            <Table.Th
                                key={`${title}_col_${col.label}_${col.field}`}
                                className="group px-0"
                                style={{ width: `${widths[index]}%` }}
                            >
                                <div className="flex gap-0.5">
                                    <Paragraph className="inline-block text-sm font-medium text-gray-500">
                                        {col.label}
                                    </Paragraph>
                                    {col.field && (
                                        <Button
                                            variant="transparent"
                                            className="hidden h-auto border-0 p-0 opacity-50 hover:opacity-100 group-hover:inline-block"
                                            onClick={() => col.field && onSort(col.field)}
                                        >
                                            <ChevronsUpDown className="h-4 w-4" />
                                        </Button>
                                    )}
                                </div>
                            </Table.Th>
                        ))}
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody className="border-b">
                    {sorted.map((appointment, index) => (
                        <TableRow
                            key={`${title}_table_row_${appointment.id}`}
                            row={createRow(appointment)}
                            tableId={`${title}_table`}
                            rowIndex={index}
                            // onClick={{ TODO: call patientModal }}
                        />
                    ))}
                </Table.Tbody>
            </Table>
        </div>
    );
};

export default AppointmentTable;
