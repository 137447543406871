import { useDroppable } from '@dnd-kit/core';
import { FC } from 'react';

interface DroppableComponentProps {
    id: string;
    children: React.ReactNode;
}

// TODO: create story
const DroppableComponent: FC<DroppableComponentProps> = ({ id, children }) => {
    const { setNodeRef, isOver: is_over } = useDroppable({ id });

    return (
        <div ref={setNodeRef} className={`m-2 rounded-lg ${is_over ? 'ring-2 ring-green-500' : ''}`}>
            {children}
        </div>
    );
};

export default DroppableComponent;
