import { NavLink as MantineNavLink } from '@mantine/core';
import { FC, ReactNode } from 'react';

interface NavLinkProps {
    label: string;
    leftSection?: ReactNode;
    onClick: () => void;
    active: boolean;
}

const NavLink: FC<NavLinkProps> = ({ label, leftSection, onClick, active }) => {
    return (
        <MantineNavLink
            classNames={{
                root: 'opacity-50 transition-opacity hover:bg-transparent hover:opacity-100 data-[active=true]:font-bold data-[active=true]:opacity-100',
                label: 'hover:bg-transparent',
            }}
            onClick={onClick}
            active={active}
            leftSection={leftSection}
            label={label}
            variant="subtle"
        />
    );
};

export default NavLink;
