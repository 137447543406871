import { EnrichedCustomer, EnrichedLocation } from '@/models/EnrichedPrismicDocuments.ts';
import { Button } from '@mantine/core';
import { Spotlight, spotlight } from '@mantine/spotlight';
import { Pencil, Search } from 'lucide-react';
import { FC, ReactElement, useMemo, useState } from 'react';

import { useAppointmentCheckinStore } from '@/stores/appointmentCheckinStore.ts';
import { useConfigStore } from '@/stores/configStore.ts';

import Paragraph from './text/Paragraph.tsx';

// TODO: add recommendation logic
const CustomerPracticeSelection: FC = () => {
    const [query, setQuery] = useState<string>('');
    const { selected_customer, selected_location, user_customers: customers } = useConfigStore((state) => state);

    const filtered_results: ReactElement[] = useMemo(() => {
        if (!query) {
            return customers.map((customer: EnrichedCustomer) => (
                <Spotlight.ActionsGroup key={customer.key} label={customer.customer_name}>
                    {customer.locations.map((location: EnrichedLocation) => (
                        <Spotlight.Action
                            onClick={() => {
                                useConfigStore.setState({
                                    selected_customer: customer,
                                    selected_location: location,
                                });
                                useAppointmentCheckinStore.setState({
                                    is_config_auto_checkin: location.config.is_new_checkin_available,
                                });
                            }}
                            key={`${customer.key}_${location.key}`}
                            label={location.title}
                        />
                    ))}
                </Spotlight.ActionsGroup>
            ));
        }

        const formatted_query = query.toLowerCase().trim();

        const filtered_options: ReactElement[] = [];

        customers.forEach((customer: EnrichedCustomer) => {
            const is_customer_name_match = customer.customer_name.toLowerCase().trim().includes(formatted_query);
            const filtered_locations = customer.locations.filter((location) =>
                location.title.toLowerCase().trim().includes(formatted_query)
            );

            if (!is_customer_name_match && filtered_locations.length == 0) {
                return;
            }

            filtered_options.push(
                <Spotlight.ActionsGroup key={customer.key} label={customer.customer_name}>
                    {(is_customer_name_match ? customer.locations : filtered_locations).map(
                        (location: EnrichedLocation) => (
                            <Spotlight.Action
                                onClick={() => {
                                    useConfigStore.setState({
                                        selected_customer: customer,
                                        selected_location: location,
                                    });
                                    useAppointmentCheckinStore.setState({
                                        is_config_auto_checkin: location.config.is_new_checkin_available,
                                    });
                                }}
                                key={`${customer.key}_${location.key}`}
                                label={location.title}
                            />
                        )
                    )}
                </Spotlight.ActionsGroup>
            );
        });

        return filtered_options;
    }, [query, customers]);

    return (
        <>
            <Button
                className="border-slate-300"
                variant="outline"
                onClick={() => spotlight.open()}
                rightSection={<Pencil className="size-4" />}
            >
                {selected_customer && selected_location ? (
                    <div className="align-center color flex items-center gap-2">
                        <Paragraph>{selected_location.title}</Paragraph>
                        <Paragraph>{selected_customer.customer_name}</Paragraph>
                    </div>
                ) : (
                    'Keine Praxis ausgewählt'
                )}
            </Button>
            <Spotlight.Root query={query} onQueryChange={setQuery}>
                <Spotlight.Search placeholder="Suche..." leftSection={<Search className="size-6" />} />
                <Spotlight.ActionsList>
                    {filtered_results.length > 0 ? (
                        filtered_results
                    ) : (
                        <Spotlight.Empty>Für diese Suche wurden keine Ergebnisse gefunden!</Spotlight.Empty>
                    )}
                </Spotlight.ActionsList>
            </Spotlight.Root>
        </>
    );
};

export default CustomerPracticeSelection;
