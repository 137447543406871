import { Insurance } from '@/models/appointment/Appointment.ts';
import { AppointmentType, Practitioner } from '@/models/appointment/EnrichedPractitionerData.ts';
import { Accordion, Pill, Switch } from '@mantine/core';
import { useToggle } from '@mantine/hooks';
import { Minus, Plus } from 'lucide-react';
import { FC, useMemo } from 'react';

import TimeslotCalendar from '@/components/booking/TimeslotCalendar.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import useTimeslots from '@/hooks/useTimeslots.ts';

import { dayjs } from '@/utils/dayjsSetup.ts';

interface Props {
    practitioner: Practitioner;
    selectedCalendar?: string;
    isOpen?: boolean;
    insurance: Insurance;
    appointmentType?: AppointmentType;
    selectedSlot?: string;
    onSlotSelect: (calendar_id: string, slot: string) => void;
    defaultStartDate?: string;
}

const TimeslotCalendarAccordionItem: FC<Props> = ({
    practitioner,
    selectedCalendar,
    isOpen,
    insurance,
    appointmentType,
    selectedSlot,
    onSlotSelect,
    defaultStartDate,
}) => {
    const [is_returning, toggle] = useToggle([false, true]);
    const { availability, is_loading, start_date, setStartDate, has_slots, next_available_slot } = useTimeslots(
        practitioner,
        insurance,
        appointmentType,
        is_returning,
        defaultStartDate
    );

    const calendar_id = useMemo(() => {
        if (selectedCalendar && practitioner.calendars.some((cal) => cal.doc_cirrus.id === selectedCalendar))
            return selectedCalendar;

        return practitioner.calendars.find((cal) =>
            appointmentType?.doc_cirrus.dc_calendar_ids.includes(cal.doc_cirrus.id)
        )?.doc_cirrus.id;
    }, [practitioner, appointmentType]);

    const onSelect = (slot: string) => {
        onSlotSelect(calendar_id ?? '', slot);
    };

    return (
        <Accordion.Item key={practitioner.id} value={practitioner.id}>
            <Accordion.Control
                className="gap-3 p-0 text-lg"
                chevron={isOpen && availability ? <Minus className="size-6" /> : <Plus className="size-6" />}
                disabled={!availability}
            >
                <div className="flex items-center">
                    <Paragraph className="flex-grow font-semibold">{practitioner.name}</Paragraph>
                    {next_available_slot && (
                        <Pill className="h-6 text-sm">{dayjs(next_available_slot).format('D MMM')}</Pill>
                    )}
                </div>
            </Accordion.Control>
            <Accordion.Panel>
                {availability && <Switch label="Bestandpatient:in" checked={is_returning} onChange={() => toggle()} />}
                <TimeslotCalendar
                    calendarId={calendar_id}
                    availability={availability}
                    isLoading={is_loading}
                    startDate={start_date}
                    setStartDate={setStartDate}
                    hasSlots={has_slots}
                    nextAvailableSlot={next_available_slot}
                    onSlotSelect={onSelect}
                    selectedSlot={selectedSlot}
                />
            </Accordion.Panel>
        </Accordion.Item>
    );
};

export default TimeslotCalendarAccordionItem;
