import { LucideIcon } from 'lucide-react';
import { FC } from 'react';

import BlockIcon from '@/components/BlockIcon.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

interface Props {
    label: string;
    value?: string;
    icon: LucideIcon;
}

const PatientInfoItem: FC<Props> = ({ label, value, icon }) => {
    return (
        <div className="flex items-center gap-4">
            <BlockIcon icon={icon} />
            <div className="flex flex-col">
                <Paragraph className="text-sm text-slate-500">{label}</Paragraph>
                <Paragraph className="font-medium">{value || '--'}</Paragraph>
            </div>
        </div>
    );
};

export default PatientInfoItem;
