import { UseFormReturnType, useForm } from '@mantine/form';
import { zodResolver } from 'mantine-form-zod-resolver';
import { FormEvent, ReactNode, useEffect, useMemo } from 'react';
import { ZodRawShape, z } from 'zod';

import useFormRef from '@/hooks/useFormRef.ts';

export interface Props<T> {
    initialValues?: T;
    handleSubmit: (values: T, event: FormEvent<HTMLFormElement> | undefined) => void;
    children: (form: UseFormReturnType<T>) => ReactNode;
    schema?: z.ZodObject<ZodRawShape>;
    className?: string;
    onValuesChange?: (values: T) => void;
    validateInputOnChange?: boolean | (keyof T)[];
    validateInputOnBlur?: boolean | (keyof T)[];
    checkIfValid?: (is_valid: boolean) => void;
}

const Form = <T extends Record<string, unknown>>({
    initialValues,
    handleSubmit,
    children,
    schema,
    className,
    onValuesChange,
    validateInputOnChange = false,
    validateInputOnBlur = false,
    checkIfValid,
}: Props<T>) => {
    const form = useForm<T>({
        mode: 'uncontrolled',
        initialValues: initialValues,
        validateInputOnChange: validateInputOnChange,
        validateInputOnBlur: validateInputOnBlur,
        validate: schema ? zodResolver(schema) : undefined,
        onValuesChange: onValuesChange,
    });
    const ref = useFormRef();

    const is_valid = useMemo(() => form.isValid(), [form]);

    useEffect(() => {
        if (!checkIfValid) return;

        checkIfValid(is_valid);
    }, [is_valid]);

    return (
        <form ref={ref} onSubmit={form.onSubmit(handleSubmit)} className={className}>
            {children(form)}
        </form>
    );
};

export default Form;
