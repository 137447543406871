import { NotificationData } from '@mantine/notifications';

export enum NotificationType {
    SUCCESS = 'success',
    WARNING = 'warning',
    ERROR = 'error',
    LOADING = 'loading',
}

export type NotificationStyleType = Omit<NotificationData, 'message' | 'title' | 'children'>;

export interface NotificationLog {
    title: string;
    message: string;
    type: NotificationType;
    timestamp: number;
}
