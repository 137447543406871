import { useDraggable } from '@dnd-kit/core';
import { FC } from 'react';

interface DraggableAppointmentProps {
    id: string;
    children: React.ReactNode;
}

// TODO: create story
const DraggableComponent: FC<DraggableAppointmentProps> = ({ id, children }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        isDragging: is_dragging,
    } = useDraggable({
        id,
    });

    return (
        <div
            ref={setNodeRef}
            className={`transition-opacity ${is_dragging ? 'opacity-40' : 'opacity-100'}`}
            {...attributes}
            {...listeners}
        >
            {children}
        </div>
    );
};

export default DraggableComponent;
