import { Option } from '@/models/CheckListItem.ts';
import { Checkbox, Input, Popover, ScrollArea } from '@mantine/core';
import { useToggle } from '@mantine/hooks';
import { FC, useMemo } from 'react';

interface Props {
    practitioner_options: Option[];
    selected_practitioners: string[];
    onPractitionerChange: (practitioners: string[]) => void;
}

const PractitionerSelect: FC<Props> = ({ practitioner_options, selected_practitioners, onPractitionerChange }) => {
    const [is_open, toggleIsOpen] = useToggle([false, true]);

    const handleSelectAll = () => {
        if (selected_practitioners.length === practitioner_options.length) {
            onPractitionerChange([]);
        } else {
            onPractitionerChange(practitioner_options.map((option) => option.value));
        }
    };

    const handleTogglePractitioner = (id: string) => {
        onPractitionerChange(
            selected_practitioners.includes(id)
                ? selected_practitioners.filter((value) => value !== id)
                : [...selected_practitioners, id]
        );
    };

    const display_text = useMemo(() => {
        if (selected_practitioners.length === 0) return 'Keine Behander:innen';

        if (selected_practitioners.length === practitioner_options.length) {
            return `Alle Behandler:innen (${practitioner_options.length})`;
        }

        return `Ausgewählte Behandler:innen (${selected_practitioners.length})`;
    }, [selected_practitioners]);

    return (
        <div className="flex-grow">
            <Popover
                opened={is_open}
                onChange={toggleIsOpen}
                width="target"
                withArrow={false}
                classNames={{
                    dropdown: 'shadow-md',
                }}
            >
                <Popover.Target>
                    <Input pointer value={display_text} readOnly onClick={() => toggleIsOpen()} />
                </Popover.Target>

                <Popover.Dropdown>
                    <div className="flex flex-col gap-2">
                        <Checkbox
                            label="Alle Behandler:innen"
                            checked={selected_practitioners.length === practitioner_options.length}
                            indeterminate={
                                selected_practitioners.length > 0 &&
                                selected_practitioners.length < practitioner_options.length
                            }
                            onChange={handleSelectAll}
                        />

                        <ScrollArea.Autosize>
                            <div className="mt-4 flex cursor-pointer flex-col gap-2">
                                {practitioner_options.map((option) => (
                                    <Checkbox
                                        key={option.value}
                                        label={option.label}
                                        checked={selected_practitioners.includes(option.value)}
                                        onChange={() => handleTogglePractitioner(option.value)}
                                    />
                                ))}
                            </div>
                        </ScrollArea.Autosize>
                    </div>
                </Popover.Dropdown>
            </Popover>
        </div>
    );
};

export default PractitionerSelect;
