import { Room, RoomType } from '@/models/Room.ts';
import { DailyAppointment } from '@/models/appointment/Appointment.ts';
import { GetAppointmentsForTodayDto } from '@/models/appointment/GetAppointmentsForTodayDto.ts';

import { useConfigStore } from '@/stores/configStore.ts';

import { dayjs } from '@/utils/dayjsSetup.ts';

// TODO: use fuzzy search
// Filter appointments based on search text and date
export const filterAppointments = (
    appointments: GetAppointmentsForTodayDto['data'],
    search_text: string,
    date: Date | null,
    practitioner_ids: string[]
): GetAppointmentsForTodayDto['data'] => {
    const normalized_search = search_text.toLowerCase().trim();

    // Helper function to filter appointments based on search criteria
    const filterBySearchCriteria = (appointment: DailyAppointment): boolean => {
        // Safely check if properties exist before accessing them
        const first_name = appointment?.patient?.name?.first_name?.toLowerCase() ?? '';
        const last_name = appointment?.patient?.name?.last_name?.toLowerCase() ?? '';
        const email = appointment?.patient?.contact?.email?.toLowerCase() ?? '';
        const patient_number = appointment?.patient?.account?.patient_number?.toLowerCase() ?? '';
        const date_of_birth = appointment?.patient?.account?.date_of_birth;

        // Check if any of the fields match the search text
        const matches_search =
            first_name.includes(normalized_search) ||
            last_name.includes(normalized_search) ||
            email.includes(normalized_search) ||
            patient_number.includes(normalized_search) ||
            (date_of_birth ? dayjs(date_of_birth).format('DD.MM.YYYY').includes(normalized_search) : false);

        // Check date filter if provided
        const matches_date =
            !date || (appointment?.start ? dayjs(appointment.start).isSame(dayjs(date), 'day') : false);

        const matches_practitioner = practitioner_ids.includes(appointment.professional.id);

        return matches_search && matches_date && matches_practitioner;
    };

    // Filter both active and inactive appointments
    return {
        active: appointments.active.filter(filterBySearchCriteria),
        inactive: appointments.inactive.filter(filterBySearchCriteria),
    };
};

/**
 * Get all room IDs from the current location config that are of type ROOM
 * @returns Array of room IDs
 */
export const getDefaultRoomIds = (): string[] => {
    const selected_location = useConfigStore.getState().selected_location;
    return (selected_location?.config?.rooms ?? ([] as Room[]))
        .filter((room: Room) => room.room_type === RoomType.ROOM)
        .map((room: Room) => room.room_id);
};

/**
 * Filter rooms based on selected room IDs. Always includes waiting rooms.
 * @param rooms - Array of all rooms
 * @param selected_room_ids - Array of selected room IDs to filter by
 * @returns Filtered array of rooms with waiting rooms always included
 */
export const filterRoomsBySelection = (rooms: Room[] = [], selected_room_ids: string[] = []): Room[] => {
    if (rooms.length === 0) return [];

    // Get waiting rooms - always visible
    const waiting_rooms = rooms.filter((room) => room.room_type === RoomType.WAITING_ROOM);

    // Get filtered treatment rooms based on selection
    const treatment_rooms =
        selected_room_ids.length > 0
            ? rooms.filter((room) => room.room_type === RoomType.ROOM && selected_room_ids.includes(room.room_id))
            : [];

    // Combine waiting rooms with filtered treatment rooms
    return [...waiting_rooms, ...treatment_rooms];
};
