import { Appointment } from '@/models/appointment/Appointment.ts';
import useSWR from 'swr';

import { getRequiredAppointmentForms } from '@/services/formDataService.ts';

export const usePatientAppointmentRequiredForms = (appointment: Appointment) => {
    const {
        isLoading: is_loading,
        mutate,
        data,
    } = useSWR(
        ['appointment_forms', appointment.user_id, appointment.id],
        () => getRequiredAppointmentForms(appointment),
        {
            revalidateOnFocus: false,
            refreshInterval: 60000,
        }
    );

    return {
        formatted_forms_for_appointment: data?.formatted_forms_for_appointment, // This is the one you probably need
        professional_form_templates: data?.professional_form_templates,
        mutateSelectedAppointmentForms: mutate,
        selected_appointment_forms: data?.selected_appointment_forms,
        is_loading: is_loading,
    };
};
