import { AutoCompleteResult } from '@/models/AddressComplete.ts';
import { TextInput, UnstyledButton } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import axios from 'axios';
import { debounce } from 'lodash-es';
import { ChangeEvent, FC, useState } from 'react';
import useSWRImmutable from 'swr/immutable';

import { PatientFormData } from '@/components/form/PatientDataForm.tsx';

import { buildDisplayName, buildResults } from '@/utils/addressUtils.ts';

interface Props {
    form: UseFormReturnType<PatientFormData, (values: PatientFormData) => PatientFormData>;
    onSelect: (value: AutoCompleteResult) => void;
}

const MINIMUM_SEARCH_LENGTH = 3;

const AddressAutocomplete: FC<Props> = ({ form, onSelect }) => {
    const [query, setQuery] = useState<string>('');
    const [is_focused, setIsFocused] = useState<boolean>(false);

    const fetcher = async (): Promise<AutoCompleteResult[]> => {
        const url = new URL('https://api.locationiq.com/v1/autocomplete');
        url.search = new URLSearchParams({
            key: import.meta.env.VITE_LOCATIONIQ_TOKEN,
            q: query,
            countrycodes: 'de',
            dedupe: '1',
            ['accept-language']: 'de',
        }).toString();

        const response = await axios.get(url.toString());

        if (!response.data || response.data.length === 0) return [];

        const results: AutoCompleteResult[] = buildResults(response.data);

        return results.map((result: AutoCompleteResult) => ({
            ...result,
            display_name: buildDisplayName(result),
        }));
    };

    const { data: results } = useSWRImmutable(
        query.length >= MINIMUM_SEARCH_LENGTH && is_focused
            ? `address-autocomplete-${query.toLowerCase().trim()}`
            : null,
        fetcher
    );

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const handleSearch = debounce((search_value: string) => {
        setQuery(search_value);
    }, 400);

    const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        handleSearch(value);
    };

    return (
        <div className="relative">
            <TextInput
                label="Adresse" // TODO: translation
                placeholder="Adresse" // TODO: translation
                key={form.key('street')}
                {...form.getInputProps('street')}
                onFocus={() => setIsFocused(true)}
                // timeout necessary to let button trigger
                onBlur={() => setTimeout(() => setIsFocused(false), 200)}
                onChange={onChangeSearch}
            />
            {query.length > 0 && results && results.length > 0 && is_focused && (
                <div className="absolute -bottom-2 left-0 z-20 flex size-9 h-auto max-h-52 w-full translate-y-full flex-col gap-2 overflow-y-scroll rounded-md border-2 border-slate-300 bg-slate-200">
                    {results.map((ac_result: AutoCompleteResult) => (
                        <UnstyledButton
                            className="p-3 text-sm transition hover:bg-slate-300 active:bg-slate-400"
                            key={ac_result.osm_id}
                            onClick={() => onSelect(ac_result)}
                        >
                            {ac_result.display_name}
                        </UnstyledButton>
                    ))}
                </div>
            )}
        </div>
    );
};

export default AddressAutocomplete;
