import { WeekAvailability } from '@/models/Availability.ts';
import { Button } from '@mantine/core';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { FC } from 'react';

import ButtonSelection from '@/components/ButtonSelection.tsx';
import CircularLoader from '@/components/CircularLoader.tsx';
import Unavailable from '@/components/Unavailable.tsx';
import Heading4 from '@/components/text/Heading4.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import { dayjs } from '@/utils/dayjsSetup.ts';

interface Props {
    calendarId?: string;
    isLoading?: boolean;
    availability?: WeekAvailability[];
    startDate: string;
    setStartDate: (date: string) => void;
    hasSlots?: boolean;
    nextAvailableSlot?: string;
    onSlotSelect: (slot: string) => void;
    selectedSlot?: string;
}

const TimeslotCalendar: FC<Props> = ({
    calendarId,
    isLoading,
    availability,
    startDate,
    setStartDate,
    hasSlots,
    nextAvailableSlot,
    onSlotSelect,
    selectedSlot,
}) => {
    if (isLoading)
        return (
            <div className="flex h-40 items-center justify-center">
                <CircularLoader />
            </div>
        );

    if (!availability) return null;

    return (
        <div className="mb-8 flex flex-col gap-6">
            <div className="flex gap-6">
                <Heading4 className="flex-grow text-lg">
                    {`${dayjs(startDate).format('D MMM')} bis ${dayjs(startDate).add(2, 'day').format('D MMM')}`}
                </Heading4>
                <Button
                    variant="transparent"
                    className="size-6 border-0 !p-0 text-gray-900 hover:text-gray-900 disabled:bg-transparent disabled:opacity-50"
                    onClick={() => setStartDate(dayjs(startDate).subtract(3, 'day').format('YYYY-MM-DD'))}
                    disabled={dayjs(startDate).isSame(dayjs()) || dayjs(startDate).isBefore(dayjs())}
                >
                    <ChevronLeft />
                </Button>
                <Button
                    variant="transparent"
                    className="size-6 border-0 !p-0 text-gray-900 hover:text-gray-900"
                    onClick={() => setStartDate(dayjs(startDate).add(3, 'day').format('YYYY-MM-DD'))}
                >
                    <ChevronRight />
                </Button>
            </div>
            {!hasSlots && nextAvailableSlot && (
                <div className="flex h-36 flex-col items-center justify-center gap-6">
                    <Paragraph>
                        {`Der nächste verfügbare Termin ist am ${dayjs(nextAvailableSlot).format('dddd, DD. MMMM')}`}
                    </Paragraph>
                    <Button
                        onClick={() => setStartDate(dayjs(nextAvailableSlot).format('YYYY-MM-DD'))}
                        className="uppercase"
                    >
                        Nächstes Zeitfenster
                    </Button>
                </div>
            )}
            {!hasSlots && !nextAvailableSlot && (
                <Unavailable text="Für diesen Zeitraum gibt es keine verfügbaren Termine" />
            )}
            {hasSlots &&
                availability.map(({ date, slots }) => (
                    <div className="flex flex-col gap-3" key={`${calendarId}_day_${date}`}>
                        <Paragraph className="text-sm font-medium text-gray-500">
                            {dayjs(date).format('dddd D. MMM')}
                        </Paragraph>
                        {!slots || slots.length === 0 ? (
                            <Unavailable text="Keine verfügbaren Zeitfenster" />
                        ) : (
                            <ButtonSelection
                                onSelect={onSlotSelect}
                                options={slots.map((slot) => ({
                                    id: `${calendarId}_${slot}`,
                                    value: slot,
                                    label: dayjs(slot).format('HH:mm'),
                                }))}
                                selected={selectedSlot}
                                allowDeselect
                            />
                        )}
                    </div>
                ))}
        </div>
    );
};

export default TimeslotCalendar;
