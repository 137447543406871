import { EnrichedLocation } from '@/models/EnrichedPrismicDocuments.ts';
import { Room, RoomType } from '@/models/Room.ts';
import { ColumnType, KanbanColumn } from '@/models/RoomView.ts';
import { Appointment, DailyAppointment, Insurance, Priority } from '@/models/appointment/Appointment.ts';
import { BookAppointmentDto } from '@/models/appointment/BookAppointmentDto.ts';
import { CheckinStatus, CheckoutStatus } from '@/models/call-system/models/CallSystemUser.ts';
import { DragEndEvent } from '@dnd-kit/core';

import { CollapsedAppointmentData } from '@/components/AppointmentScheduler/CollapsedAppointment.tsx';

import { getEnumKeyByValue } from '@/utils/objectUtils.ts';

export const getInsuranceTypeFromAppointment = (appointment: Appointment) => {
    return appointment.data.insurance_coverage_type?.toLowerCase() === 'selfpayer'
        ? 'SELF_PAYER'
        : (appointment.data?.insurance_coverage_type?.toUpperCase() ?? '');
};

export const joinAppointmentServicesKeys = (appointment: Appointment) => {
    return appointment.data.services.map((service) => service.key).join();
};

export const getUserIdFromAppointment = (appointment: Appointment) => {
    if (appointment.user_id && appointment.user_id !== '-') {
        return appointment.user_id;
    }
    return appointment.links.patient_id ?? '-';
};

export const generateAppointmentKey = (instance_id?: string, appointment_id?: string) =>
    `${instance_id}_${appointment_id}`;

export const getInsuranceKey = (value: Insurance) =>
    value === 'Selbszahler' ? 'self_payer' : getEnumKeyByValue(Insurance, value)?.toLowerCase();

export const isRoomColumn = (column: KanbanColumn) =>
    column.type === ColumnType.WAITING_ROOM || column.type === ColumnType.TREATMENT_ROOM;

// Sort all in alphabetic order and have all waiting rooms on the top
export const sortRooms = (rooms: Room[]) => {
    const waiting_rooms = rooms
        .filter((room) => room.room_type === RoomType.WAITING_ROOM)
        .sort((a, b) => a.room_name.localeCompare(b.room_name));

    const treatment_rooms = rooms
        .filter((room) => room.room_type !== RoomType.WAITING_ROOM)
        .sort((a, b) => a.room_name.localeCompare(b.room_name));

    return [...waiting_rooms, ...treatment_rooms];
};

export const getRoomsSelection = (rooms: Room[], checkin_status?: CheckinStatus) => {
    let result_rooms = sortRooms(rooms);
    if (isCheckinPending(checkin_status)) {
        result_rooms = result_rooms.filter((room) => room.room_type === RoomType.WAITING_ROOM);
    }
    return result_rooms.map((room) => ({
        label: room.room_name,
        value: room.room_id,
    }));
};

export const prepareKanbanMove = (event: DragEndEvent, filled_columns: Map<string, KanbanColumn>) => {
    const { active, over } = event;
    if (!over) return null;

    const active_id = active.id as string;
    const over_id = over.id as string;

    // Find source column and appointment
    const source_column = Array.from(filled_columns.values()).find((col) =>
        col.appointments.some((app) => app.id === active_id)
    );
    if (!source_column) return null;

    const appointment = source_column.appointments.find((app) => app.id === active_id);
    if (!appointment) return null;

    // Find destination column - either by column id or by appointment id
    const destination_column =
        filled_columns.get(over_id) || Array.from(filled_columns.values()).find((col) => col.id === over_id);
    if (!destination_column) return null;

    // If setting in the same column don't change
    if (source_column.id === destination_column.id) return null;

    return { appointment, source_column, destination_column };
};

export const moveKanbanAppointment = (
    filled_columns: Map<string, KanbanColumn>,
    source_column: KanbanColumn,
    destination_column: KanbanColumn,
    appointment: DailyAppointment
): Map<string, KanbanColumn> => {
    // Create new columns map
    const new_filled_columns = new Map(filled_columns);

    // Remove from source
    new_filled_columns.set(source_column.id, {
        ...source_column,
        appointments: source_column.appointments.filter((app) => app.id !== appointment.id),
    });

    new_filled_columns.set(destination_column.id, {
        ...destination_column,
        appointments: [...destination_column.appointments, appointment],
    });

    return new_filled_columns;
};

export const getBookingAppointmentDtoFromFormData = (
    location: EnrichedLocation,
    patient: {
        user_id: string;
        doc_cirrus_user_id: string;
        is_eterno_user: boolean;
    },
    data: CollapsedAppointmentData & {
        calendar_id: string;
    }
): BookAppointmentDto => {
    // Get the name from either mapped or unmapped type
    const appointment_name = data.appointment_type?.name ?? data.unmapped_schedule_type?.name ?? '';

    if (!data.duration || !appointment_name || !data.calendar_id || !data.date_time) {
        throw new Error(`Missing data on appointment object`);
    }

    const appointment_dto: BookAppointmentDto = {
        appointment: {
            duration: {
                value: data.duration,
                units: 'MINUTES',
            },
            priority: data.priority || Priority.NONE,
            services: [appointment_name],
            slot: data.date_time,
            type: {
                // For unmapped appointments, generate a UUID since backend expects one
                id: data.appointment_type?.id || '',
                doc_cirrus_schedule_type_id:
                    data.appointment_type?.doc_cirrus.dc_schedule_type_id ||
                    data.unmapped_schedule_type?.doc_cirrus.dc_schedule_type_id ||
                    '',
                is_mapped: Boolean(data.appointment_type?.id),
            },
            type_keys: [data.appointment_type?.prismic_key || ''],
            meta: {
                calendar_id: data.calendar_id,
                is_at_location: data.appointment_type?.is_at_location || true,
                is_online: data.appointment_type?.is_online || false,
                type_id: data.appointment_type
                    ? data.appointment_type.doc_cirrus.dc_schedule_type_id
                    : data.unmapped_schedule_type?.doc_cirrus.dc_schedule_type_id || '',
                dc_practitioner_id: data.practitioner.doc_cirrus.dc_id || '',
            },
        },
        professional: {
            id: data.practitioner.id || '',
            key: data.practitioner.prismic_profile?.data.key[0]?.text || '',
            location_key: location.key,
            sub_loc_key: '', // TODO: implement sub location
            type_key: data.practitioner.prismic_profile?.type || '',
        },
        patient: {
            id: patient.user_id, // uuid or ext::hash()
            dc_user_id: patient.doc_cirrus_user_id,
            is_eterno_user: patient.is_eterno_user,
            is_for_self: true,
            is_returning: false,
            is_public_insurance: data.insurance === Insurance.PUBLIC,
            is_self_payer: [Insurance.SELFPAYER, Insurance.SELF_PAYER].includes(data.insurance as Insurance),
            is_private_insurance: data.insurance === Insurance.PRIVATE,
            is_with_prescription: false,
            documents: [],
            symptoms: [],
            additional_info: data.notes || '',
        },
    };

    return appointment_dto;
};

export const isCheckinPending = (checkin_status: CheckinStatus | undefined): boolean => {
    return checkin_status === CheckinStatus.PENDING || !checkin_status;
};

export const isCheckinArrived = (checkin_status: CheckinStatus | undefined): boolean => {
    return checkin_status === CheckinStatus.ARRIVED;
};

export const isCheckedIn = (checkin_status: CheckinStatus | undefined): boolean => {
    return checkin_status === CheckinStatus.CHECKED_IN || checkin_status === CheckinStatus.MANUALLY_CHECKED_IN;
};

export const isCheckedOut = (checkout_status: CheckoutStatus | undefined): boolean => {
    return checkout_status === CheckoutStatus.CHECKED_OUT || checkout_status === CheckoutStatus.MANUALLY_CHECKED_OUT;
};

// If it's not, is only a DC schedule, with no appointment data on Eterno DynamoDB
export const isEternoAppointment = (appointment: Appointment): boolean => {
    return appointment.user_id !== '-';
};
