import { AvailabilityResponse, AvailableTimeslots } from '@/models/Availability.ts';
import axios from 'axios';

import { ServiceUrl } from '@/services/ServiceURL.ts';

import { buildUrlWithQueryParams } from '@/utils/apiUtils.ts';

export const getAvailableTimeslots = async ({
    customer_id,
    user_id,
    location_key,
    appointment_type_key,
    insurance_type_key,
    dc_calendar_id,
    start_date,
    is_online = false,
    is_returning = false,
    sub_loc_key = '',
}: AvailableTimeslots): Promise<AvailabilityResponse> => {
    try {
        const api_url = buildUrlWithQueryParams(
            `${ServiceUrl.PRACTITIONER_API_DOMAIN.PUBLIC.USERS.AVAILABILITY}/${user_id}`,
            {
                location_key: location_key,
                appointment_type_keys: appointment_type_key,
                insurance_type_key: insurance_type_key,
                sub_loc_key: sub_loc_key,
                dc_calendar_id: dc_calendar_id,
                start_date: start_date,
                is_online: is_online.toString(),
                is_returning: is_returning.toString(),
                journey_type: 'office-app-book',
            }
        );

        // TODO: add option in api wrapper to allow removal of auth header
        const response = await axios.get(api_url, {
            headers: {
                customer_id: customer_id,
            },
        });

        return response.data.data;
    } catch (error) {
        throw error;
    }
};
