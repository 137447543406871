import StatusDotType from '@/models/StatusDotType.ts';
import { Insurance } from '@/models/appointment/Appointment.ts';
import { UserAppointmentDto } from '@/models/call-system/dtos/user-appointment-dto.ts';
import { Pill } from '@mantine/core';
import { Video } from 'lucide-react';
import { FC, useMemo } from 'react';
import { validate } from 'uuid';

import CircularLoader from '@/components/CircularLoader.tsx';
import StatusDot from '@/components/StatusDot.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import { isCheckedIn, isCheckedOut, isCheckinArrived, isCheckinPending } from '@/utils/appointmentUtils.ts';
import { dayjs } from '@/utils/dayjsSetup.ts';
import { cn } from '@/utils/styleUtils.ts';

interface Props {
    patientName?: string;
    insurance?: Insurance;
    isInsuranceCardActive: boolean;
    checkinData?: UserAppointmentDto;
    date: string;
    showDate?: boolean;
    loading: boolean;
    isOnline?: boolean;
    isDischarged?: boolean;
}

const AppointmentCardHeader: FC<Props> = ({
    patientName,
    insurance,
    isInsuranceCardActive,
    checkinData,
    date,
    showDate = false,
    loading,
    isOnline = false,
}) => {
    const name = patientName ?? 'Nicht angegeben';
    const is_ticket_displayed = useMemo(
        () => checkinData?.number && !validate(checkinData.number),
        [checkinData?.number]
    );

    const [status_dot, status_text]: [StatusDotType | undefined, string] = useMemo(() => {
        if (isCheckedOut(checkinData?.appointment?.checkout_status)) {
            return [StatusDotType.BLUE, 'Abgemeldet']; //"Checked out"
        }
        if (isCheckinPending(checkinData?.appointment?.checkin_status)) {
            return [undefined, ''];
        }
        if (isCheckinArrived(checkinData?.appointment?.checkin_status)) {
            return [StatusDotType.YELLOW, `Angekommen mit Ticket: ${checkinData?.number}`]; // "Arrived"
        }
        if (isCheckedIn(checkinData?.appointment?.checkin_status)) {
            return [StatusDotType.GREEN, `Eingecheckt mit Ticket: ${checkinData?.number}`]; // "Checked-in with ticket"
        }
        return [StatusDotType.RED, 'No-show'];
    }, [checkinData?.appointment?.checkin_status, checkinData?.appointment?.checkout_status]);

    const renderStatus = () => {
        if (isOnline) return <Video />;
        if (status_dot) return <StatusDot type={status_dot} text={status_text} />;
        return null;
    };

    return (
        <div className="flex items-center gap-3">
            {loading && <CircularLoader size="sm" />}
            <Paragraph className={cn('font-semibold underline', !patientName && 'text-slate-400 no-underline')}>
                {loading ? 'Wird geladen' : name}
            </Paragraph>
            {insurance && (
                <Pill
                    className={cn(
                        'h-6 bg-blue-100 text-sm text-blue-700',
                        insurance === Insurance.PUBLIC && 'bg-red-100 text-red-700',
                        isInsuranceCardActive && 'bg-green-100 text-green-700'
                    )}
                >
                    {insurance}
                </Pill>
            )}
            {is_ticket_displayed && checkinData?.number && <Pill className="h-6 text-sm">{checkinData?.number}</Pill>}
            {renderStatus()}
            {showDate && (
                <Paragraph className="flex-grow text-right text-sm text-slate-500">
                    {dayjs(date).format('HH:mm')}
                </Paragraph>
            )}
        </div>
    );
};

export default AppointmentCardHeader;
