import { DailyAppointment } from '@/models/appointment/Appointment.ts';

export enum ColumnType {
    EXPECTED = 'expected',
    WAITING_ROOM = 'waitingRoom',
    TREATMENT_ROOM = 'treatmentRoom',
    DISCHARGED = 'discharged',
}

export interface KanbanColumn {
    id: string;
    title: string;
    type: ColumnType;
    collapsed: boolean;
    appointments: DailyAppointment[];
}
