import { Divider, MantineSize } from '@mantine/core';
import { modals } from '@mantine/modals';
import { clsx } from 'clsx';
import { X } from 'lucide-react';
import { ReactNode } from 'react';

import { cn } from '@/utils/styleUtils.ts';

interface Props {
    modalId?: string;
    onClose?: () => void;
    title?: ReactNode;
    children: ReactNode;
    size?: MantineSize;
    classNames?: {
        header?: string;
        childrenWrapper?: string;
    };
    footer?: ReactNode;
    closeOnEscape?: boolean;
    closeOnClickOutside?: boolean;
    withCloseButton?: boolean;
}

// TODO: create BaseModalBody and BaseModalFooter components
export const openModal = ({
    modalId,
    onClose,
    title,
    children,
    size = 'xl',
    classNames,
    footer,
    closeOnEscape = true,
    closeOnClickOutside = true,
    withCloseButton = true,
}: Props) => {
    // TODO: manage modals ids and opened order with a global store
    modals.open({
        modalId: modalId,
        onClose: onClose,
        closeOnEscape: closeOnEscape,
        closeOnClickOutside: closeOnClickOutside,
        withCloseButton: withCloseButton,
        size: size,
        centered: true,
        closeButtonProps: {
            icon: <X className="size-5/6" />,
        },
        classNames: {
            // TODO: add config for gradient
            header: clsx(
                // 'border-b-2 border-slate-200 bg-gradient-to-r from-primaryLight to-primary pl-6 text-font',
                'border-b-2 border-slate-200 bg-slate-100 pl-6 text-font',
                classNames?.header
            ),
            title: 'text-lg font-bold',
            content: 'shadow-2xl',
            body: 'bg-slate-100 p-0',
            close: 'text-font transition hover:text-black',
        },
        title: title,
        overlayProps: {
            className: 'bg-black bg-opacity-30 backdrop-blur',
        },
        children: (
            <>
                <div className={cn('max-h-[75vh] overflow-y-scroll p-6', classNames?.childrenWrapper)}>{children}</div>
                {footer && (
                    <div className="relative bg-slate-200 bg-opacity-75">
                        <Divider />
                        <div
                            className={clsx(
                                'flex h-full w-full flex-row items-center p-4',
                                Array.isArray(footer) ? 'justify-between' : 'justify-end'
                            )}
                        >
                            {footer}
                        </div>
                    </div>
                )}
            </>
        ),
    });
};

export const closeModal = (id: string) => {
    modals.close(id);
};

interface ConfirmModalProps extends Props {
    onConfirm: () => void;
    onCancel?: () => void;
}

// TODO: create BaseModalBody and BaseModalFooter components
export const openConfirmModal = ({
    modalId,
    onClose,
    onConfirm,
    onCancel,
    title,
    children,
    size = 'md',
    classNames,
    footer,
    closeOnEscape = true,
    closeOnClickOutside = true,
    withCloseButton = true,
}: ConfirmModalProps) => {
    // TODO: manage modals ids and opened order with a global store
    modals.openConfirmModal({
        modalId: modalId,
        onConfirm: onConfirm,
        onCancel: onCancel,
        onClose: onClose,
        closeOnEscape: closeOnEscape,
        closeOnClickOutside: closeOnClickOutside,
        withCloseButton: withCloseButton,
        size: size,
        centered: true,
        labels: { confirm: 'Bestätigen', cancel: 'Abbrechen' },
        closeButtonProps: {
            icon: <X className="size-5/6" />,
        },
        classNames: {
            header: clsx(
                // 'border-b-2 border-slate-200 bg-gradient-to-r from-primaryLight to-primary pl-6 text-font',
                'border-b-2 border-slate-200 bg-slate-100 pl-6 text-font',
                classNames?.header
            ),
            title: 'text-lg font-bold',
            content: 'shadow-2xl',
            body: 'bg-slate-100 p-4',
            close: 'text-font transition hover:text-black',
        },
        title: title,
        overlayProps: {
            className: 'bg-black bg-opacity-30 backdrop-blur',
        },
        children: (
            <>
                <div className={cn('', classNames?.childrenWrapper)}>{children}</div>
                {footer && (
                    <div className="relative bg-slate-200 bg-opacity-75">
                        <Divider />
                        <div
                            className={clsx(
                                'flex h-full w-full flex-row items-center p-4',
                                Array.isArray(footer) ? 'justify-between' : 'justify-end'
                            )}
                        >
                            {footer}
                        </div>
                    </div>
                )}
            </>
        ),
    });
};
