import { LucideIcon } from 'lucide-react';
import { FC } from 'react';

interface Props {
    icon: LucideIcon;
}

const BlockIcon: FC<Props> = ({ icon: Icon }) => {
    return (
        <div className="flex h-10 w-10 items-center justify-center rounded-md bg-slate-100">
            <Icon className="size-6 text-slate-600" />
        </div>
    );
};

export default BlockIcon;
