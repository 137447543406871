import { PrismicDataType } from '@/models/PrismicHookTypes.ts';
import { Document } from '@/models/documents/Document.ts';
import { Card, Tooltip } from '@mantine/core';
import { Download } from 'lucide-react';
import { FC, useMemo } from 'react';

import Paragraph from '@/components/text/Paragraph.tsx';

import usePrismic from '@/hooks/usePrismic.tsx';

import { dayjs } from '@/utils/dayjsSetup.ts';
import { getDocumentType } from '@/utils/formUtils.ts';

interface Props {
    document: Document;
    downloadDocument: (document_id: string) => Promise<void>;
}

const DocumentItem: FC<Props> = ({ document, downloadDocument }) => {
    const formatted_date = useMemo(() => dayjs(document.meta.created_at).format('DD MMM YYYY'), [document]);
    const { data: medical_records } = usePrismic(PrismicDataType.MEDICAL_RECORDS);

    return (
        <Tooltip label={document.name}>
            <Card
                className="w-full cursor-pointer p-2 hover:bg-slate-100"
                onClick={() => downloadDocument(document.id)}
            >
                <div className="grid grid-cols-[2fr_1fr_1fr_40px] items-center gap-4">
                    <Paragraph className="truncate">{document.name}</Paragraph>
                    <Paragraph className="truncate">{formatted_date}</Paragraph>
                    <Paragraph className="truncate">
                        {getDocumentType(medical_records?.type_of_doc_response, document.type)}
                    </Paragraph>

                    <Download size={16} />
                </div>
            </Card>
        </Tooltip>
    );
};

export default DocumentItem;
