import { ApiError, api } from '@/lib/api-client.ts';
import { Appointment } from '@/models/appointment/Appointment.ts';
import FormattedForm from '@/models/forms/FormattedForm.ts';
import {
    FormDataAppointmentDto,
    FormKeyObject as FormTemplateAppointment,
} from '@/models/forms/backend-types/FormDataAppointment.ts';
import { FormDataCodeDto, FormDataCodeResult } from '@/models/forms/backend-types/FormDataCode.ts';
import { FormDataRequiredTemplate } from '@/models/forms/backend-types/FormDataRequiredTemplates.ts';
import { GetDocumentsResult } from '@/models/forms/backend-types/GetDocumentsResult.ts';
import { FormTemplate } from '@/models/forms/backend-types/form-template/FormTemplate.ts';
import { AxiosRequestConfig } from 'axios';

import { ServiceUrl } from '@/services/ServiceURL.ts';

import { getUserIdFromAppointment } from '@/utils/appointmentUtils.ts';
import { getCustomerId } from '@/utils/customerUtils.ts';
import { formatRequiredForms } from '@/utils/formUtils.ts';
import { getInsuranceTypeFromAppointment, joinAppointmentServicesKeys } from '@/utils/practitioner/cachedDataUtils.ts';

export const getRequiredFormsForAppointment = async (
    appointment: Appointment
): Promise<FormDataRequiredTemplate[] | null> => {
    const options: AxiosRequestConfig = {
        headers: {
            customer_id: getCustomerId(),
        },
        params: {
            user_id: appointment.user_id,
            professional_id: appointment.professional.id,
            insurance_type: getInsuranceTypeFromAppointment(appointment),
            appointment_type_keys: joinAppointmentServicesKeys(appointment),
        },
    };
    try {
        const response = await api.get(
            `${ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.USER.FORM_DATA}/required-templates`,
            options
        );
        return response.data.templates;
    } catch (e) {
        console.debug(e);
        return null;
    }
};

export const getSelectedFormsForAppointment = async (
    user_id: string,
    appointment_id: string
): Promise<FormTemplateAppointment[] | undefined> => {
    const config = {
        headers: {
            customer_id: getCustomerId(),
        },
    };
    try {
        const res = await api.get(
            `${ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.USER.FORM_DATA}/appointment?user_id=${user_id}&appointment_id=${appointment_id}`,
            config
        );
        return res?.data?.data?.forms;
    } catch (error) {
        console.debug((error as ApiError).message);
        return undefined;
    }
};

export const getFormTemplates = async (professional_id: string): Promise<FormTemplate[] | undefined> => {
    const config = {
        headers: {
            customer_id: getCustomerId(),
        },
    };
    try {
        const res = await api.get<{ data: FormTemplate[] }>(
            `${ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.USER.FORM_DATA}/templates?professional_id=${professional_id}`,
            config
        );
        console.debug('formTempRES', res);
        return res.data.data;
    } catch (error) {
        console.debug(error);
        return undefined;
    }
};

export const saveSelectedFormsForAppointment = async (
    appointment_forms_to_save: FormDataAppointmentDto
): Promise<boolean> => {
    const config = {
        headers: {
            customer_id: getCustomerId(),
        },
    };
    try {
        await api.post(
            `${ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.USER.FORM_DATA}/appointment`,
            appointment_forms_to_save,
            config
        );
        return true;
    } catch (error) {
        console.debug(error);
        return false;
    }
};

export const getSharedForms = async (user_id: string): Promise<GetDocumentsResult[] | undefined> => {
    const config = {
        headers: {
            customer_id: getCustomerId(),
        },
    };
    try {
        const res = await api.get(
            `${ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.USER.FORM_DATA}/documents?user_id=${user_id}`,
            config
        );
        return res?.data?.data;
    } catch (error) {
        console.debug(error);
        return undefined;
    }
};

export const generateCode = async (code_data: FormDataCodeDto): Promise<string | undefined> => {
    const config = {
        headers: {
            customer_id: getCustomerId(),
        },
    };
    try {
        const res = await api.post<FormDataCodeResult>(
            `${ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.USER.FORM_DATA}/codes/generate`,
            code_data,
            config
        );
        return res?.data?.code;
    } catch (error) {
        console.debug(error);
        return undefined;
    }
};

export const getCodeByAppointment = async (
    appointment_id: string,
    user_id: string,
    location_key: string,
    sub_loc_key: string | undefined
): Promise<string | undefined> => {
    const config: AxiosRequestConfig = {
        headers: {
            customer_id: getCustomerId(),
        },
        params: {
            appointment_id: appointment_id,
            location_key: location_key,
            sub_loc_key: sub_loc_key,
            user_id: user_id,
        },
    };
    try {
        const res = await api.get<FormDataCodeResult>(
            `${ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.USER.FORM_DATA}/codes/appointment`,
            config
        );
        return res?.data?.code;
    } catch (error) {
        console.debug(error);
        return undefined;
    }
};

export const getRequiredAppointmentForms = async (appointment: Appointment) => {
    const user_id = getUserIdFromAppointment(appointment);

    const [professional_forms, appointment_forms, selected_forms, shared_forms] = await Promise.all([
        // TODO: load professional templates directly after cached data
        getFormTemplates(appointment.professional.id),
        getRequiredFormsForAppointment(appointment),
        user_id !== '-' ? getSelectedFormsForAppointment(user_id, appointment.id) : undefined,
        getSharedForms(user_id),
    ]);

    let formatted_forms: FormattedForm[] | undefined = undefined;
    if (professional_forms && appointment_forms) {
        formatted_forms = formatRequiredForms(selected_forms, appointment_forms, professional_forms, shared_forms);
    }

    return {
        professional_form_templates: professional_forms,
        selected_appointment_forms: selected_forms,
        formatted_forms_for_appointment: formatted_forms,
    };
};
