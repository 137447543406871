import { AppShell } from '@mantine/core';
import { AnimatePresence } from 'framer-motion';
import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import eterno_logo from '@/assets/logo.svg';

import CustomerPracticeSelection from '@/components/CustomerPracticeSelection.tsx';
import HeaderNavigation from '@/components/HeaderNavigation.tsx';
import UserMenu from '@/components/UserMenu.tsx';

import { useConfigStore } from '@/stores/configStore.ts';

import CustomerPrinterSelection from './CustomerPrinterSelection.tsx';

interface Props {
    children: ReactNode;
}

const OfficeAppShell: FC<Props> = ({ children }) => {
    const { selected_location } = useConfigStore();
    // const [mobile_opened, { toggle: toggleMobile }] = useDisclosure();
    // const [desktop_opened, { toggle: toggleDesktop }] = useDisclosure(true);

    // const filtered_routes = useMemo(() => {
    //     return authenticated_routes.filter((route) => route.name && route.key);
    // }, []);

    // TEMPORARY OUTCOMENTED CODE FOR SIDE MENU

    return (
        <AnimatePresence mode="wait">
            <AppShell
                header={{ height: 60 }}
                // navbar={{
                //     width: 300,
                //     breakpoint: 'sm',
                //     collapsed: { mobile: !mobile_opened, desktop: !desktop_opened },
                // }}
            >
                <AppShell.Header>
                    <div className="flex h-full items-center justify-between px-10">
                        <div className="flex h-full items-center gap-5">
                            {/* TEMPORARY DISABLED SIDE MENU  */}

                            {/* <Burger opened={mobile_opened} onClick={toggleMobile} className="block size-8 sm:hidden" />
                            <Burger
                                opened={desktop_opened}
                                onClick={toggleDesktop}
                                className="hidden size-8 sm:block"
                            /> */}

                            <Link to="/">
                                <img src={eterno_logo} alt="eterno" className="w-43 h-6" />
                            </Link>
                            <CustomerPracticeSelection />
                            {selected_location && <CustomerPrinterSelection location={selected_location} />}
                        </div>

                        <div className="flex items-center gap-8">
                            <HeaderNavigation />
                            <UserMenu />
                        </div>
                    </div>
                </AppShell.Header>
                {/* <AppShell.Navbar className="p-2">
                    {filtered_routes.map((route) => (
                        <NavLink key={route.key} href={route.path} label={route.name} leftSection={route.icon} />
                    ))}
                </AppShell.Navbar> */}
                <AppShell.Main
                    classNames={{
                        main: 'h-svh',
                    }}
                >
                    {children}
                </AppShell.Main>
            </AppShell>
        </AnimatePresence>
    );
};

export default OfficeAppShell;
