import StatusDotType from '@/models/StatusDotType.ts';
import { ColorSwatch, Tooltip } from '@mantine/core';
import { FC } from 'react';

interface Props {
    type: StatusDotType;
    text?: string;
}

const StatusDot: FC<Props> = ({ type, text }) => {
    let color_class_name = '';

    switch (type) {
        case StatusDotType.GREEN:
            color_class_name = 'bg-green-600';
            break;
        case StatusDotType.YELLOW:
            color_class_name = 'bg-yellow-500';
            break;
        case StatusDotType.RED:
            color_class_name = 'bg-red-600';
            break;
        case StatusDotType.BLUE:
            color_class_name = 'bg-blue-600';
            break;
        default:
            color_class_name = '';
    }

    const renderStatus = () => (
        <div className="flex size-8 items-center justify-center">
            <ColorSwatch
                size={8}
                classNames={{
                    colorOverlay: color_class_name ?? 'bg-green-600',
                }}
                color=""
            />
        </div>
    );

    if (text) {
        return <Tooltip label={text}>{renderStatus()}</Tooltip>;
    }
    return renderStatus();
};

export default StatusDot;
