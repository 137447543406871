import { ApiError, api } from '@/lib/api-client.ts';

export const printPcsTicket = async (
    auth_token: string,
    endpoint: string,
    // TODO: remove cert stuff
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    certificate_text: string
): Promise<[boolean, string | ApiError]> => {
    try {
        const response = await api.get(`${import.meta.env.VITE_PROTOCOL}://${endpoint}/`, {
            headers: {
                token: auth_token,
            },
        });
        console.debug(response);
        return [true, response.data?.id];
    } catch (error) {
        const api_error = error as ApiError;
        console.debug(api_error);
        return [false, api_error];
    }
};

export const updatePcsTicket = async (
    auth_token: string,
    endpoint: string,
    stack: string,
    ticket: string | null = null
): Promise<undefined | string> => {
    try {
        const response = await api.get(
            `${import.meta.env.VITE_PROTOCOL}://${endpoint}/?stack=${stack}${ticket ? `&ticket=${ticket}` : ''}`,
            {
                headers: {
                    token: auth_token,
                },
            }
        );
        console.debug(response);
        if (ticket) return response.status.toString();
        return response.data?.id;
    } catch (error) {
        console.debug(error);
        return undefined;
    }
};

export const isPrinterLive = async (endpoint: string): Promise<boolean> => {
    try {
        const response = await api.get(`${import.meta.env.VITE_PROTOCOL}://${endpoint}/`, {
            timeout: 1000,
        });
        console.debug({ response });
        return true;
    } catch (error) {
        console.debug(error);
        return false;
    }
};
