import { PatientInsurance } from '@/models/Patient.ts';
import { DailyAppointment } from '@/models/appointment/Appointment.ts';
import { Insurance } from '@/models/appointment/Appointment.ts';
import { Button } from '@mantine/core';
import { CreditCard, FileText, Mail, MapPin, Phone, Shield } from 'lucide-react';
import { FC, useMemo } from 'react';
import useSWR from 'swr';

import { getUserById } from '@/services/patientService.ts';

import PatientInfoItem from './PatientInfoItem.tsx';

interface Props {
    patient: DailyAppointment['patient'];
}

const PatientInformation: FC<Props> = ({ patient }) => {
    const { data: detailed_patient, isLoading: is_loading } = useSWR(
        patient?.pvs?.user_id ? `patient-${patient.pvs.user_id}` : null,
        () => (patient?.pvs?.user_id ? getUserById(patient.pvs.user_id) : null)
    );

    const getInsuranceTypeDisplay = (insurance: string) => {
        return Insurance[insurance as keyof typeof Insurance] || '';
    };

    const { insurance_types, insurance_names } = useMemo(
        () => ({
            insurance_types:
                detailed_patient?.insurances
                    ?.map((insurance: PatientInsurance) => getInsuranceTypeDisplay(insurance.type))
                    ?.join(', ') || '-',
            insurance_names:
                detailed_patient?.insurances?.map((insurance: PatientInsurance) => insurance.name || '-').join(', ') ||
                '-',
        }),
        [detailed_patient]
    );

    if (is_loading) return null;

    return (
        <div className="flex w-full flex-col gap-4">
            <div className="flex flex-col gap-4">
                <PatientInfoItem icon={Shield} label="Versicherungsart" value={insurance_types} />
                <PatientInfoItem icon={CreditCard} label="Krankenversicherung" value={insurance_names} />
                <PatientInfoItem icon={Phone} label="Telefon" value={patient.contact.phone} />
                <PatientInfoItem
                    icon={MapPin}
                    label="Adresse"
                    value={`${patient.address.street}, ${patient.address.zip} ${patient.address.city}`}
                />
                <PatientInfoItem icon={Mail} label="E-Mail" value={patient.contact.email} />
                <PatientInfoItem icon={FileText} label="Anmerkung" value={''} />
            </div>
            <div className="w-full text-right">
                <Button
                    variant="transparent"
                    className="p-0 capitalize underline"
                    onClick={() => {
                        console.debug('edit patient');
                    }}
                >
                    Bearbeiten
                </Button>
            </div>
        </div>
    );
};

export default PatientInformation;
