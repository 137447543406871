import { FuseResultMatch } from '@/models/Fuse.ts';
import { NormalizedPatient, Patient } from '@/models/Patient.ts';
import { Button, Loader, TextInput } from '@mantine/core';
import axios from 'axios';
import { debounce } from 'lodash-es';
import { Search } from 'lucide-react';
import { ChangeEvent, FC, useState } from 'react';
import useSWR from 'swr';

import Paragraph from '@/components/text/Paragraph.tsx';

import useAuth from '@/hooks/useAuth.ts';
import useConfig from '@/hooks/useConfig.ts';

import { ServiceUrl } from '@/services/ServiceURL.ts';

import { preparePatientForStore, updateCurrentPatient } from '@/utils/patientUtils.ts';

import SelectableList from '../SelectableList.tsx';
import Heading3 from '../text/Heading3.tsx';
import PatientRow from './PatientRow.tsx';

const MINIMUM_SEARCH_LENGTH = 3;

interface Props {
    setIsPatientSelected: (value: boolean) => void;
}

// TODO: add memoization
// TODO: move search patient outside and pass it as children
const SearchPatient: FC<Props> = ({ setIsPatientSelected }) => {
    const { getIdToken } = useAuth();
    const [selected_item, setSelectedItem] = useState<string>('');
    const [search, setSearch] = useState<string>('');
    const { instance_id, customer_id } = useConfig();

    const fetcher = async (): Promise<{
        patients: {
            user: NormalizedPatient;
            matches: FuseResultMatch[];
        }[];
        duration: number;
        results_count: number;
        total_count: number;
    }> => {
        const url = new URL(ServiceUrl.PATIENT_API_DOMAIN.SECURE_ADMIN.DOC_CIRRUS.SEARCH);
        url.search = new URLSearchParams({
            instance_id: instance_id,
            search: encodeURIComponent(search.toLowerCase().toString()),
        }).toString();

        const token = await getIdToken();

        // TODO: error catching
        const start = performance.now();
        const response = await axios.get(url.toString(), {
            headers: {
                Authorization: token,
                customer_id: customer_id,
            },
        });

        return {
            patients: response.data.results.map(({ user, matches }: { user: Patient; matches: FuseResultMatch[] }) => ({
                user: {
                    ...user,
                    _normalized: {
                        patient_number: user.account.patient_number?.toLowerCase().trim() ?? '',
                        primary_email: user.primary_email?.toLowerCase().trim() ?? '',
                        first_name: user.name.first_name.toLowerCase().trim(),
                        last_name: user.name.last_name.toLowerCase().trim(),
                        date_of_birth: user.account.date_of_birth.toLowerCase().trim(),
                    },
                },
                matches: matches,
            })),
            duration: performance.now() - start,
            results_count: response.data.meta.results_count,
            total_count: response.data.meta.total_count,
        };
    };

    const {
        data,
        isLoading: is_loading,
        isValidating: is_validating,
    } = useSWR(search ? `patient-search-${search.toLowerCase().trim()}` : null, fetcher);

    // TODO: this should not run be done on select
    const handleSelect = async (value: string) => {
        setSelectedItem(selected_item === value ? '' : value);

        if (selected_item === value) {
            // deselect
            updateCurrentPatient(undefined);
            setIsPatientSelected(false);
        } else {
            // select
            const selected_user = data?.patients.find((user) => user.user.user_id === value)?.user;
            const prepared_patient = preparePatientForStore(selected_user);
            updateCurrentPatient(prepared_patient);
            setIsPatientSelected(true);
        }
    };

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const handleSearch = debounce((search_value: string) => {
        setSearch(search_value);
    }, 400);

    const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value.length < MINIMUM_SEARCH_LENGTH) return;

        handleSearch(value);
    };

    return (
        <div className="flex w-full flex-col gap-3">
            <div className="flex gap-5">
                <Heading3>{`Search Patient (${data?.results_count ?? 0} out of ${data?.total_count ?? 0} matches)`}</Heading3>
                {data?.duration && <p>{`${Math.round(data.duration / 10) / 100}s`}</p>}
            </div>
            <div className="flex items-center justify-stretch gap-4">
                <TextInput
                    className="w-3/4"
                    classNames={{
                        input: 'bg-white',
                    }}
                    placeholder="Patient name"
                    rightSectionPointerEvents="none"
                    leftSection={(is_loading || is_validating) && <Loader size="xs" />}
                    rightSection={<Search className="size-5" />}
                    onChange={onChangeSearch}
                />
                <Button className="w-1/4 !p-2.5" variant="filled">
                    Search
                </Button>
            </div>
            <div className="h-[50vh] overflow-scroll">
                {data?.patients && data?.patients?.length > 0 ? (
                    <SelectableList handleSelect={handleSelect} selectedItem={selected_item}>
                        {data?.patients.map((patient) => (
                            <PatientRow matches={patient.matches} patient={patient.user} key={patient.user.user_id} />
                        ))}
                    </SelectableList>
                ) : (
                    <div className="flex h-48 w-full items-center justify-center rounded-md bg-slate-300 bg-opacity-50">
                        {is_loading && <Loader />}
                        {!is_loading && (
                            <Paragraph className="text-center text-gray-700">
                                Suchen Sie mit Name, Geburtsdatum oder ID
                            </Paragraph>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SearchPatient;
