import { EnrichedCustomer, EnrichedLocation } from '@/models/EnrichedPrismicDocuments.ts';
import { Patient } from '@/models/Patient.ts';
import { Appointment, AppointmentBookStatus, Insurance, Priority } from '@/models/appointment/Appointment.ts';
import { AppointmentBookingFormValues } from '@/models/appointment/AppointmentBookingFormValues.ts';
import { create } from 'zustand';

// Define types for each appointment status
export interface AppointmentBookingStatus {
    id: string;
    status: AppointmentBookStatus;
    form_data: AppointmentBookingFormValues; // TODO: store the form data to be able to recover it
    response?: Appointment; // TODO: save the api call response at least with the id of the appointment to be able to fetch the status
    error?: string;
}

// Define batch structure
export interface BookingBatch {
    customer: EnrichedCustomer;
    location: EnrichedLocation;
    batchId: string;
    notification_id: string;
    patient: Patient;
    appointments: AppointmentBookingStatus[];
    created_at: number;
}

// Define Zustand store
interface BookingStore {
    is_open: boolean;
    appointments_form_data: (AppointmentBookingFormValues & { id: string })[];
    initial_form_values: AppointmentBookingFormValues;
    current_appointment_id: string | null;
    is_last_form_valid: boolean;
    default_practice_id: string;
    is_booking_loading: boolean;
    current_batch_id?: string;
    current_patient?: Patient;
    batches: Map<string, BookingBatch>;
    resetAllData: () => void;
    setIsLastFormValid: (is_valid: boolean) => void;
    setDefaultPracticeId: (id: string) => void;
    setCurrentBatchId: (batchId: string | undefined) => void;
    getCurrentBatch: (batchId: string) => BookingBatch | undefined;
    addBatch: (
        customer: EnrichedCustomer,
        location: EnrichedLocation,
        batchId: string,
        notificationId: string,
        patient: Patient,
        appointments: AppointmentBookingStatus[]
    ) => void;
    addAppointmentsToBatch: (
        batchId: string,
        notificationId: string,
        newAppointments: AppointmentBookingStatus[]
    ) => void;
    updateAppointmentStatus: (batchId: string, id: string, status: AppointmentBookStatus, error?: string) => void;
    removeBatch: (batchId: string) => void;
}

const initial_values: AppointmentBookingFormValues = {
    practice_doc_cirrus_id: '',
    insurance: Insurance.PUBLIC,
    apt_type_id_or_schedule_type_dc_id: '',
    calendar_doc_cirrus_id: '',
    priority: Priority.NONE,
    duration: 0,
    date_time: new Date().toISOString(),
    notes: '',
};

// Create Zustand store
export const useBookingStore = create<BookingStore>((set, get) => ({
    is_open: false,
    appointments_form_data: [],
    initial_form_values: initial_values,
    current_appointment_id: null,
    is_last_form_valid: false,
    default_practice_id: '',
    is_booking_loading: false,
    current_batch_id: undefined,
    current_patient: undefined,
    batches: new Map<string, BookingBatch>(),

    resetAllData: () =>
        set(() => ({
            appointments_form_data: [],
            current_appointment_id: null,
            initial_form_values: initial_values,
            is_last_form_valid: false,
            default_practice_id: '',
            current_batch_id: undefined,
        })),

    setIsLastFormValid: (is_valid: boolean) => set(() => ({ is_last_form_valid: is_valid })),

    setDefaultPracticeId: (id: string) => set(() => ({ default_practice_id: id })),

    setCurrentBatchId: (batchId: string | undefined) =>
        set(() => ({
            current_batch_id: batchId,
        })),

    getCurrentBatch: (batchId: string): BookingBatch | undefined => {
        return get().batches.get(batchId);
    },

    addBatch: (customer, location, batchId, notificationId, patient, appointments) =>
        set((state) => {
            // If batchId is falsy (undefined, null, ""), return the same state (no changes)
            if (!batchId) {
                console.debug('addBatch was called without a valid batchId. Ignoring.');
                return state;
            }

            const updated_batches = new Map(state.batches);
            updated_batches.set(batchId, {
                batchId: batchId,
                customer: customer,
                location: location,
                notification_id: notificationId,
                patient: patient,
                appointments: appointments,
                created_at: Date.now(),
            });
            return { batches: updated_batches };
        }),

    /**
     * Add additional appointments to an existing batch.
     */
    addAppointmentsToBatch: (batchId, notificationId, newAppointments) =>
        set((state) => {
            const updated_batches = new Map(state.batches);
            const batch = { ...(updated_batches.get(batchId) as BookingBatch), notificationId: notificationId };

            // If the batch doesn't exist, don't modify state
            if (!batch || !batch.appointments) {
                console.debug('No batch found for batchId:', batchId);
                return state;
            }

            // Merge new appointments into existing array
            batch.appointments = batch.appointments.concat(newAppointments);

            updated_batches.set(batchId, batch);
            return { batches: updated_batches };
        }),

    updateAppointmentStatus: (batchId, id, status, error) =>
        set((state) => {
            const updated_batches = new Map(state.batches);
            const batch = updated_batches.get(batchId);
            if (!batch) return state;

            batch.appointments = batch.appointments.map((apt) => (apt.id === id ? { ...apt, status, error } : apt));

            updated_batches.set(batchId, batch);
            return { batches: updated_batches };
        }),

    // Remove only if no need to retrieve it anymore.
    // Batch are used to retrieve scheduler context from a notification.
    removeBatch: (batchId) =>
        set((state) => {
            const updated_batches = new Map(state.batches);
            updated_batches.delete(batchId);
            return { batches: updated_batches };
        }),
}));
