import { Children, FC, ReactElement, ReactNode } from 'react';

import { cn } from '@/utils/styleUtils.ts';

import Heading2 from '../text/Heading2.tsx';

export interface FormSectionProps {
    title?: string;
    children: ReactNode;
    classNames?: {
        title?: string;
        wrapper?: string;
        children?: string;
        firstChild?: string;
    };
}

const FormSection: FC<FormSectionProps> = ({ title, children, classNames }) => (
    <>
        {title && (
            <div>
                <Heading2 className={classNames?.title}>{title}</Heading2>
            </div>
        )}
        <div className={cn('flex flex-wrap justify-between gap-2', classNames?.wrapper)}>
            {Children.map(children, (child, index) => {
                const element_key: string = (child as ReactElement).key ?? '';

                return (
                    <div
                        className={cn('flex-[0_0_49%]', classNames?.children, index === 0 && classNames?.firstChild)}
                        key={`wrapper_${element_key}`}
                    >
                        {child}
                    </div>
                );
            })}
        </div>
    </>
);

export default FormSection;
