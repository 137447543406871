import { EnrichedCustomer, EnrichedLocation } from '@/models/EnrichedPrismicDocuments.ts';
import { PrismicDataType } from '@/models/PrismicHookTypes.ts';
import { RoomType } from '@/models/Room.ts';
import { CustomerConfigDocument } from '@/models/prismicTypes.ts';
import { FilledContentRelationshipField } from '@prismicio/client';
import { useEffect } from 'react';

import useAuth from '@/hooks/useAuth.ts';
import usePrismic from '@/hooks/usePrismic.tsx';

import { useAppointmentCheckinStore } from '@/stores/appointmentCheckinStore.ts';
import { useConfigStore } from '@/stores/configStore.ts';
import { usePrismicStore } from '@/stores/prismicStore.ts';

import { filterCustomersForUser } from '@/utils/customerUtils.ts';
import { formatCustomerPrinters, getTextFromRichTextField } from '@/utils/prismicUtils.ts';

const useCustomers = () => {
    const { auth_user, customer_ids, instance_ids, groups, is_admin } = useAuth();
    const { selected_location, selected_customer, user_customers } = useConfigStore((state) => state);
    const { data: customer_config_data } = usePrismic(PrismicDataType.CUSTOMER_CONFIG);
    const { data: location_data } = usePrismic(PrismicDataType.HUB);
    const { data: dc_instance_config_data } = usePrismic(PrismicDataType.CONFIG_DC_INSTANCE);

    const customers = usePrismicStore((state) => state.customers);

    // TODO: split into utils functions and improve readability
    useEffect(() => {
        if (!customer_config_data || !location_data || !dc_instance_config_data) return;

        const enriched_locations = Object.keys(location_data).reduce(
            (obj, location_document_id) => {
                const location = location_data[location_document_id];
                const instance_config = dc_instance_config_data[location_document_id];

                if (!location || !instance_config) return obj;

                obj[location_document_id] = {
                    ...location,
                    title: getTextFromRichTextField(location.title),
                    key: getTextFromRichTextField(location.key),
                    group_key: getTextFromRichTextField(location.group_key),
                    config: {
                        ...instance_config,
                        rooms:
                            instance_config?.rooms.map((room) => ({
                                room_id: room.room_id ?? '',
                                room_name: room.room_name ?? '',
                                room_type: room.room_type as RoomType,
                            })) ?? [],
                        instance_id: getTextFromRichTextField(instance_config.instance_id),
                        pcs_printers: formatCustomerPrinters(instance_config.pcs_printers),
                    },
                };

                return obj;
            },
            {} as { [key: string]: EnrichedLocation }
        );

        const enriched_customers: EnrichedCustomer[] = [];

        customer_config_data.forEach((customer_config: CustomerConfigDocument['data']) => {
            const customer_key: string = getTextFromRichTextField(customer_config.key);
            if (!customer_key) return;

            const customer_locations: EnrichedLocation[] = [];
            customer_config.locations.forEach(({ location }) => {
                const document_id: string = (location as FilledContentRelationshipField<'hub'>)?.id;
                if (!document_id) return;

                const location_obj: EnrichedLocation = enriched_locations[document_id];
                customer_locations.push(location_obj);
            });

            const enriched_customer_id: string = getTextFromRichTextField(customer_config.customer_id);

            const enriched_customer: EnrichedCustomer = {
                ...customer_config,
                key: getTextFromRichTextField(customer_config.key),
                customer_id: enriched_customer_id,
                customer_name: getTextFromRichTextField(customer_config.customer_name),
                locations: customer_locations,
            };

            enriched_customers.push(enriched_customer);
        });

        usePrismicStore.setState({ customers: enriched_customers });
    }, [customer_config_data, location_data, dc_instance_config_data]);

    useEffect(() => {
        if (!auth_user || customers.length === 0) return;

        if (is_admin) {
            useConfigStore.setState({ user_customers: customers });
            return;
        }

        const customer_id_set = new Set<string>(customer_ids);
        const instance_id_set = new Set<string>(instance_ids);
        const groups_set = new Set(groups);

        const filtered_customers: EnrichedCustomer[] = filterCustomersForUser(
            customers,
            customer_id_set,
            instance_id_set,
            groups_set
        );

        useConfigStore.setState({ user_customers: filtered_customers });
    }, [auth_user, customers, is_admin, customer_ids, instance_ids, groups]);

    useEffect(() => {
        if (!auth_user || user_customers.length === 0 || selected_customer || selected_location) return;

        const default_customer: EnrichedCustomer | undefined = user_customers[0];
        const default_location: EnrichedLocation | undefined = default_customer?.locations[0];

        useConfigStore.setState({
            selected_customer: default_customer,
            selected_location: default_location,
        });
        useAppointmentCheckinStore.setState({
            is_config_auto_checkin: default_location.config.is_new_checkin_available,
        });
    }, [auth_user, user_customers, selected_customer, selected_location, customer_ids, is_admin]);
};

export default useCustomers;
