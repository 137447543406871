import { EternoQuoteVariant } from '@/models/EternoQuoteVariant.ts';
import { Blockquote } from '@mantine/core';
import { AlertCircle, Info, XCircle } from 'lucide-react';
import { FC } from 'react';

interface Props {
    text: string;
    variant?: EternoQuoteVariant;
}

const getIcon = (variant: EternoQuoteVariant) => {
    switch (variant) {
        case EternoQuoteVariant.INFO:
            return Info;
        case EternoQuoteVariant.WARNING:
            return AlertCircle;
        case EternoQuoteVariant.ERROR:
            return XCircle;
        default:
            return Info;
    }
};

const EternoQuote: FC<Props> = ({ text, variant = EternoQuoteVariant.INFO }) => {
    const Icon = getIcon(variant);

    return (
        <Blockquote
            classNames={{
                root: 'p-5',
            }}
            color={variant}
            icon={<Icon />}
            iconSize={40}
        >
            {text}
        </Blockquote>
    );
};

export default EternoQuote;
