export enum RoomType {
    ROOM = 'ROOM',
    WAITING_ROOM = 'WAITING_ROOM',
    UNKNOWN = 'Unknown',
}

export interface Room {
    room_id: string;
    room_name: string;
    room_type: RoomType;
}
