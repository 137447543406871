import { useEffect, useRef } from 'react';

import { useFormStore } from '@/stores/formStore.ts';

const useFormRef = () => {
    const ref = useRef<HTMLFormElement | null>(null);

    useEffect(() => {
        if (!ref.current) return;

        useFormStore.setState({ form_element: ref.current });
    }, [ref]);

    return ref;
};

export default useFormRef;
