import { Anchor, Tooltip } from '@mantine/core';
import clsx from 'clsx';
import { ExternalLink as LinkIcon } from 'lucide-react';
import { FC } from 'react';

interface Props {
    href?: string;
    onClick?: () => void;
    text: string;
    classNames?: {
        anchor?: string;
        icon?: string;
    };
}

const ExternalLink: FC<Props> = ({ href, onClick, text, classNames }) => {
    return (
        <Anchor
            href={href}
            onClick={onClick}
            target="_blank"
            rel="noreferrer noopener"
            className={clsx('flex items-center gap-1', classNames?.anchor)}
        >
            <Tooltip label={text}>
                <span className="max-w-[450px] truncate text-black">{text}</span>
            </Tooltip>
            <LinkIcon className={clsx('size-4', classNames?.icon)} />
        </Anchor>
    );
};

export default ExternalLink;
