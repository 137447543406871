import { PrismicDataType } from '@/models/PrismicHookTypes.ts';
import { Appointment } from '@/models/appointment/Appointment.ts';
import { Document } from '@/models/documents/Document.ts';
import { Button } from '@mantine/core';
import { ChevronsDown, ChevronsUp } from 'lucide-react';
import { FC, useMemo, useState } from 'react';
import useSWR from 'swr';

import CircularLoader from '@/components/CircularLoader.tsx';
import DocumentCodePopup from '@/components/PatientModal/DocumentCodePopup.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import usePrismic from '@/hooks/usePrismic.tsx';

import { getDocumentsByUser } from '@/services/documentsService.ts';

import { downloadDocument } from '@/utils/formUtils.ts';
import { filterPatientDocuments } from '@/utils/patientUtils.ts';

import DocumentItem from './DocumentItem.tsx';

interface Props {
    appointment: Appointment;
    showDocumentsByAppointment?: boolean;
}

const DocumentExchange: FC<Props> = ({ appointment, showDocumentsByAppointment = true }) => {
    const { data: medical_records } = usePrismic(PrismicDataType.MEDICAL_RECORDS);
    const [show_modal, setShowModal] = useState(false);
    const [document_to_download_after_code_input, setDocumentToDownloadAfterCodeInput] = useState<string | undefined>();
    const [display_count, setDisplayCount] = useState(5);

    const { data: documents, isLoading: is_loading } = useSWR<Document[]>(
        ['appointment-documents', appointment.user_id],
        () => getDocumentsByUser(appointment.user_id)
    );

    const filtered_documents = useMemo(
        () => filterPatientDocuments(documents, showDocumentsByAppointment),
        [documents]
    );

    const visible_documents = useMemo(
        () => filtered_documents?.slice(0, display_count),
        [filtered_documents, display_count]
    );

    const has_more_documents = filtered_documents && filtered_documents.length > display_count;

    const toggleDisplayCount = (increase: boolean) => {
        setDisplayCount((prev) => (increase ? prev + 5 : 5));
    };

    const download = async (document_id: string): Promise<void> => {
        await downloadDocument(appointment, document_id, () => {
            setShowModal(true);
            setDocumentToDownloadAfterCodeInput(document_id);
        });
    };

    const onInputCodeSuccess = () => {
        if (document_to_download_after_code_input) download(document_to_download_after_code_input);
    };

    const showMoreLessButtons = () => {
        if (!has_more_documents && display_count <= 5) return null;

        return (
            <div className="flex items-center gap-2">
                {display_count > 5 && (
                    <>
                        <Button
                            leftSection={<ChevronsUp className="size-4" />}
                            onClick={() => toggleDisplayCount(false)}
                            variant="transparent"
                            className="text-gray-500 hover:text-black"
                        >
                            Weniger anzeigen
                        </Button>
                        {has_more_documents && <div className="h-6 w-px bg-gray-300" />}
                    </>
                )}
                {has_more_documents && (
                    <Button
                        leftSection={<ChevronsDown className="size-4" />}
                        onClick={() => toggleDisplayCount(true)}
                        variant="transparent"
                        className="text-gray-500 hover:text-black"
                    >
                        Mehr anzeigen
                    </Button>
                )}
            </div>
        );
    };

    const renderDocumentContent = () => {
        if (is_loading) {
            return <CircularLoader />;
        }

        if (!documents || documents.length === 0 || !visible_documents?.length) {
            return <Paragraph>{medical_records?.no_documents_found[0]?.text}</Paragraph>;
        }

        return (
            <>
                {visible_documents.map((document) => (
                    <DocumentItem document={document} key={document?.id} downloadDocument={download} />
                ))}
                {filtered_documents && filtered_documents.length > 0 && (
                    <div className="mt-4 flex justify-center">{showMoreLessButtons()}</div>
                )}
            </>
        );
    };

    return (
        <div className="flex w-full flex-col gap-4">
            <DocumentCodePopup setVisible={setShowModal} visible={show_modal} onInputCodeSuccess={onInputCodeSuccess} />
            {showDocumentsByAppointment && (
                <Paragraph className="text-sm uppercase text-gray-500">Austausch von Dokumenten</Paragraph>
            )}
            <section className="flex flex-col gap-2">{renderDocumentContent()}</section>
        </div>
    );
};

export default DocumentExchange;
