import { NotificationType } from '@/models/Notification.ts';
import { InsuranceType } from '@/models/forms/backend-types/form-template/FormTemplate.ts';
import { Button, Tabs } from '@mantine/core';
import { FC, useMemo, useState } from 'react';

import useAuth from '@/hooks/useAuth.ts';

import {
    showLoadingNotification,
    showWarningNotification,
    updateNotification,
} from '@/services/notificationService.ts';
import { createUser, getUserById } from '@/services/patientService.ts';

import { useFormStore } from '@/stores/formStore.ts';

import { dayjs } from '@/utils/dayjsSetup.ts';
import { validatePatientForm } from '@/utils/formUtils.ts';
import { preparePatientForStore, transformPatientObject, updateCurrentPatient } from '@/utils/patientUtils.ts';

import BaseModal from './BaseModal.tsx';
import CardReader from './CardReader.tsx';
import PatientDataForm, { PatientFormData } from './form/PatientDataForm.tsx';
import SearchPatient from './searchPatient/SearchPatient.tsx';

interface Props {
    opened: boolean;
    onClose: () => void;
    onConfirm?: () => void;
}

// Form test
const form_initial_values: PatientFormData = {
    cost_center: InsuranceType.PUBLIC,
    first_name: '',
    last_name: '',
    date_of_birth: dayjs('1990-01-01').toDate(),
    street: '',
    house_number: '',
    postal_code: 12345,
    city: '',
    email: '',
    phone: '+49',
    practice: '',
};
// END testing data ============================

const WalkInModal: FC<Props> = ({ opened, onClose, onConfirm = () => {} }) => {
    const title = 'Termin erstellen'; // TODO: translation
    const [active_tab, setActiveTab] = useState<string | null>('search');
    const { getIdToken } = useAuth();
    const [loading, setLoading] = useState(false);
    const [is_user_selected, setIsUserSelected] = useState<boolean>(false);

    // TODO: add proper logic for switching to appointment booking modal
    const createPatient = async (values: PatientFormData) => {
        const { show_notification, message } = validatePatientForm(values);
        if (show_notification) {
            showWarningNotification(message ?? '', 'Fehlende Informationen');
            return;
        }

        setLoading(true);
        const notification_id: string = showLoadingNotification('Patient anlegen...');
        try {
            const new_patient = transformPatientObject(values);
            const token = await getIdToken();

            // Use the patient service to create the patient
            const user_id = await createUser(new_patient, token!);
            const patient = await getUserById(user_id);
            const prepared_patient = preparePatientForStore(patient);

            // Close the modal first
            onClose();

            // Then update the store
            updateCurrentPatient(prepared_patient);

            startBooking();

            updateNotification(NotificationType.SUCCESS, {
                id: notification_id,
                message: 'Patient erfolgreich angelegt!',
            });
        } catch (error) {
            console.debug(error);
            updateNotification(NotificationType.ERROR, {
                id: notification_id,
                message: 'Patient anlegen fehlgeschlagen!',
            });
        }
        setLoading(false);
    };

    const startBooking = () => {
        if (onConfirm) onConfirm();
    };

    const triggerFormSubmit = () => {
        useFormStore.getState().form_element?.dispatchEvent(
            new Event('submit', {
                bubbles: true,
                cancelable: true,
            })
        );
    };

    const handleOnClose = () => {
        // reset initial values
        setIsUserSelected(false);
        onClose();
    };

    // TODO: add proper logic for switching to appointment booking modal
    const footer_element = useMemo(() => {
        switch (active_tab) {
            case 'search':
                return (
                    <div className="flex flex-row gap-4" key={0}>
                        <Button disabled={!is_user_selected} key={0} variant="outline" onClick={startBooking}>
                            Weiter ohne eGK einlesen
                        </Button>
                        <Button disabled={!is_user_selected} key={1} variant="filled" onClick={startBooking}>
                            Weiter mit eGK einlesen
                        </Button>
                    </div>
                );
            case 'read-card':
                return (
                    <Button className="w-52" key={1} variant="filled" onClick={() => {}}>
                        Bestätigen
                    </Button>
                );
            case 'new-patient':
                return (
                    <Button className="w-52" onClick={triggerFormSubmit} loading={loading}>
                        Patient:in anlegen
                    </Button>
                );
            default:
                return null;
        }
    }, [active_tab, loading, is_user_selected]);

    return (
        <BaseModal
            classNames={{ childrenWrapper: 'h-[65vh] pt-2' }}
            opened={opened}
            onClose={handleOnClose}
            size="xl"
            title={title}
            footer={footer_element}
        >
            <Tabs value={active_tab} onChange={setActiveTab} className="h-[90%]">
                <Tabs.List className="mb-2">
                    <Tabs.Tab value="search">Patient:in suchen</Tabs.Tab>
                    <Tabs.Tab value="read-card">Karte lesen</Tabs.Tab>
                    <Tabs.Tab value="new-patient">Neue Akte erstellen</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="search">
                    <SearchPatient setIsPatientSelected={setIsUserSelected} />
                </Tabs.Panel>
                <Tabs.Panel value="read-card" className="h-full">
                    <CardReader />
                </Tabs.Panel>
                <Tabs.Panel value="new-patient">
                    <PatientDataForm
                        handleSubmit={(values) => createPatient(values)}
                        initialValues={form_initial_values}
                    />
                </Tabs.Panel>
            </Tabs>
        </BaseModal>
    );
};

export default WalkInModal;
