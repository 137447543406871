import { Tooltip } from '@mantine/core';
import { FC, ReactNode } from 'react';

interface ConditionalTooltipProps {
    children: ReactNode;
    label?: string;
}

const ConditionalTooltip: FC<ConditionalTooltipProps> = ({ children, label }) => {
    if (!label) {
        return <>{children}</>;
    }

    return <Tooltip label={label}>{children}</Tooltip>;
};

export default ConditionalTooltip;
