import { Room, RoomType } from '@/models/Room.ts';
import { ColumnType, KanbanColumn } from '@/models/RoomView.ts';
import { Appointment, AppointmentBookStatus } from '@/models/appointment/Appointment.ts';
import { GetAppointmentsForTodayDto } from '@/models/appointment/GetAppointmentsForTodayDto.ts';
import { UserAppointmentDto } from '@/models/call-system/dtos/user-appointment-dto.ts';

import { isCheckedOut } from '@/utils/appointmentUtils.ts';

const base_columns: Map<string, KanbanColumn> = new Map([
    [
        'expected',
        {
            id: 'expected',
            collapsed: false,
            title: 'ERWARTET',
            type: ColumnType.EXPECTED,
            appointments: [],
        },
    ],
    [
        'discharged',
        {
            id: 'discharged',
            collapsed: false,
            title: 'ENTLASSEN',
            type: ColumnType.DISCHARGED,
            appointments: [],
        },
    ],
]);

// TODO: add unit test
const columnTypeFromString = (type: RoomType): ColumnType | undefined => {
    const type_map: Record<string, ColumnType> = {
        [RoomType.ROOM]: ColumnType.TREATMENT_ROOM,
        [RoomType.WAITING_ROOM]: ColumnType.WAITING_ROOM,
    };

    const column_type = type_map[type];
    if (!column_type) {
        // TODO: managed unrecognized type properly
        console.debug(`Unrecognized room type: ${type}`); // Log unrecognized types
    }

    return column_type;
};

// TODO: add unit test
export const populateColumnsWithAppointments = (
    appointments: GetAppointmentsForTodayDto['data'],
    columns: Map<string, KanbanColumn>,
    getCheckinData: (appointment: Appointment) => UserAppointmentDto | undefined
): Map<string, KanbanColumn> | undefined => {
    const new_columns_map = new Map(columns);

    // Retrieve expected and discharged columns
    const expected_column = new_columns_map.get(ColumnType.EXPECTED);
    const discharged_column = new_columns_map.get(ColumnType.DISCHARGED);

    if (!expected_column || !discharged_column) {
        // Error in the code
        return undefined;
    }

    // Clear existing appointments
    expected_column.appointments = [];
    discharged_column.appointments = [];

    const merged_appointments = [...appointments.active, ...appointments.inactive];

    merged_appointments.forEach((apt) => {
        const checkin_data = getCheckinData(apt);

        if (isCheckedOut(checkin_data?.appointment?.checkout_status)) {
            discharged_column.appointments.push(apt);
            return;
        }

        if (
            !checkin_data?.appointment?.checkin_status &&
            (apt.status === AppointmentBookStatus.COMPLETED ||
                apt.status === AppointmentBookStatus.CANCELLED ||
                apt.status === AppointmentBookStatus.DECLINED ||
                !apt.is_active)
        ) {
            discharged_column.appointments.push(apt);
            return;
        }

        const column_id = apt.links.room_id ?? ColumnType.EXPECTED;
        const column = new_columns_map.get(column_id);

        if (!column) {
            console.debug(`Unexpected room: ${apt.links.room_id}`);
            return;
        }

        column.appointments.push(apt);
        new_columns_map.set(column_id, column);
    });

    new_columns_map.set(ColumnType.EXPECTED, expected_column);
    new_columns_map.set(ColumnType.DISCHARGED, discharged_column);

    return new_columns_map;
};

// TODO: add unit test
export const generateColumns = (rooms: Room[] = []): Map<string, KanbanColumn> => {
    const columns = new Map(base_columns);

    rooms.forEach((room) => {
        const column_type = columnTypeFromString(room.room_type);

        if (!column_type) return;

        columns.set(room.room_id, {
            collapsed: false,
            id: room.room_id,
            title: room.room_name,
            type: column_type,
            appointments: [],
        });
    });

    return columns;
};
