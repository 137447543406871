import { AppointmentBookStatus, Insurance, Priority } from '@/models/appointment/Appointment.ts';
import { AppointmentBookingFormValues } from '@/models/appointment/AppointmentBookingFormValues.ts';
import { Button, Tooltip } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { AnimatePresence, motion } from 'framer-motion';
import { Plus } from 'lucide-react';
import { FC, useEffect } from 'react';

import useAppointmentData from '@/hooks/useAppointmentData.ts';

import { useBookingStore } from '@/stores/bookingStore.ts';

import AppointmentForm from './AppointmentForm.tsx';
import CollapsedAppointment from './CollapsedAppointment.tsx';

const initial_values: AppointmentBookingFormValues = {
    practice_doc_cirrus_id: '',
    insurance: Insurance.PUBLIC,
    apt_type_id_or_schedule_type_dc_id: '',
    calendar_doc_cirrus_id: '',
    priority: Priority.NONE,
    duration: 0,
    date_time: new Date().toISOString(),
    notes: '',
};

const AppointmentScheduler: FC = () => {
    const {
        appointments_form_data,
        current_appointment_id,
        initial_form_values,
        is_last_form_valid,
        setIsLastFormValid,
        is_booking_loading,
        default_practice_id,
        setDefaultPracticeId,
        getCurrentBatch,
        setCurrentBatchId,
        current_batch_id,
    } = useBookingStore((state) => state);

    // TODO: move logic to store
    useEffect(() => {
        if (!current_batch_id) {
            const new_batch_id = randomId();
            setCurrentBatchId(new_batch_id);
        }
    }, [current_batch_id]);

    // TODO: move logic to store
    //this can also be handled in the store itself. whenever the current_batch_id changes, the current_batch value can be set
    const current_batch = useBookingStore((state) => state.batches.get(current_batch_id!));

    useEffect(() => {
        if (!current_batch_id) return;

        // Check if there's an existing batch
        const existing_batch = getCurrentBatch(current_batch_id);

        const has_no_appointments = !existing_batch || existing_batch.appointments.length === 0;

        // If the local form array is empty AND the batch has no appointments,
        // we add one initial form.
        if (has_no_appointments && appointments_form_data.length === 0) {
            const new_id = randomId();
            useBookingStore.setState({
                appointments_form_data: [{ id: new_id, ...initial_values }],
                current_appointment_id: new_id,
                initial_form_values: initial_values,
                is_last_form_valid: false,
            });
        }
    }, [current_batch_id, getCurrentBatch, appointments_form_data]);

    const { getAppointmentDataFromIds } = useAppointmentData();

    const addNewAppointment = () => {
        const id = randomId();
        useBookingStore.setState({
            appointments_form_data: [...appointments_form_data, { id: id, ...initial_values }],
            current_appointment_id: id,
            initial_form_values: initial_values,
        });
    };

    const setCurrentFormData = (values: AppointmentBookingFormValues) => {
        if (!current_appointment_id) {
            return;
        }

        const appointments_after_edit = appointments_form_data.map((apt) => {
            if (apt.id === current_appointment_id) return { id: current_appointment_id, ...values, status: 'DRAFT' };

            return apt;
        });

        useBookingStore.setState({ appointments_form_data: appointments_after_edit });
    };

    const handleEdit = (id: string) => {
        const appointment = appointments_form_data.find((item) => item.id === id);
        if (appointment) useBookingStore.setState({ initial_form_values: appointment });
    };

    const handleDelete = (id: string) => {
        useBookingStore.setState({ appointments_form_data: appointments_form_data.filter((item) => item.id !== id) });
    };

    return (
        <div className="flex min-h-[71vh] flex-col gap-4">
            <div className="flex flex-grow flex-col gap-4">
                <AnimatePresence>
                    {current_batch?.appointments.map((value) => (
                        <CollapsedAppointment
                            key={value.id}
                            disableEdit={true}
                            disableCancel={true}
                            appointment={getAppointmentDataFromIds(value.form_data)}
                            onEdit={() => {}}
                            onDelete={() => {}}
                            batchId={current_batch_id}
                            appointmentId={value.id}
                            onCheckIn={() => {}} // TODO: implement check in ac
                        />
                    ))}
                    {!is_booking_loading &&
                        appointments_form_data.map(({ id, ...appointment }, index) =>
                            id !== current_appointment_id ? (
                                <motion.div key={id} layoutId={id} transition={{ type: 'tween' }} exit={{ opacity: 0 }}>
                                    <CollapsedAppointment
                                        disableEdit={!is_last_form_valid || is_booking_loading}
                                        disableCancel={
                                            (current_batch?.appointments.length === 0 &&
                                                appointments_form_data.length <= 1) ||
                                            is_booking_loading
                                        }
                                        appointment={getAppointmentDataFromIds(appointment)}
                                        appointmentCount={`(${index + 1} of ${appointments_form_data.length})`}
                                        onEdit={() => {
                                            useBookingStore.setState({ current_appointment_id: id });
                                            handleEdit(id);
                                        }}
                                        onDelete={() => handleDelete(id)}
                                    />
                                </motion.div>
                            ) : (
                                <motion.div
                                    key={id}
                                    layoutId={id}
                                    style={{ overflow: 'hidden' }}
                                    transition={{ duration: 0.3 }}
                                    initial={{
                                        translateY: appointments_form_data.length > 1 ? '150%' : 0,
                                        opacity: 0,
                                    }}
                                    animate={{
                                        translateY: 0,
                                        opacity: 1,
                                    }}
                                    exit={{ opacity: 0 }}
                                >
                                    <AppointmentForm
                                        setIsFormValid={setIsLastFormValid}
                                        setFormData={setCurrentFormData}
                                        initialValues={initial_form_values}
                                        disableCancel={
                                            current_batch?.appointments.length === 0 &&
                                            appointments_form_data.length <= 1
                                        }
                                        onDelete={() => {
                                            handleDelete(id);
                                            setIsLastFormValid(true);
                                        }}
                                        allowTransition={appointments_form_data.length > 1}
                                        // Check if form has different value of default one
                                        enableSteps={initial_form_values === initial_values}
                                        setDefaultPracticeId={setDefaultPracticeId}
                                        defaultPracticeId={default_practice_id}
                                    />
                                </motion.div>
                            )
                        )}
                </AnimatePresence>
            </div>
            <Tooltip label="Funktion kommt bald">
                <Button
                    disabled={
                        (appointments_form_data.length > 0 && !is_last_form_valid) ||
                        is_booking_loading ||
                        // TODO: Since we don't allow to fix the declined error for now just disable the new appointment button
                        current_batch?.appointments.some((apt) => apt.status === AppointmentBookStatus.DECLINED)
                    }
                    leftSection={<Plus />}
                    className="w-full shadow-sm"
                    variant="outline"
                    onClick={addNewAppointment}
                >
                    {/* TODO: add translation */}
                    Termin hinzufügen
                </Button>
            </Tooltip>
        </div>
    );
};

export default AppointmentScheduler;
