import { CreatePatient, NormalizedPatient, Patient } from '@/models/Patient.ts';
import { Appointment, Insurance } from '@/models/appointment/Appointment.ts';
import { Document } from '@/models/documents/Document.ts';
import hash from 'hash.js';

import { PatientFormData } from '@/components/form/PatientDataForm.tsx';

import { useBookingStore } from '@/stores/bookingStore.ts';

import { dayjs } from '@/utils/dayjsSetup.ts';

const EXTERNAL_ID_PREFIX = 'ext';
const ID_SEPARATOR = '::';

export const appointmentPatient = (appointment: Appointment): string | undefined => {
    return appointment.user_id && appointment.user_id !== '-' ? appointment.user_id : appointment.data?.generated_id;
};

// insurance_coverage_type being returned as lowercase on dev while it's returned as uppercase on prod
export const getInsuranceByKey = (key?: string): Insurance | undefined => {
    if (!key) return undefined;

    return Insurance[key.toUpperCase() as keyof typeof Insurance];
};

export const filterPatientDocuments = (docs: Document[] | undefined, withAppointmentId: boolean) => {
    return docs?.filter((document) => (withAppointmentId ? document?.appointment_id : !document?.appointment_id));
};

// TODO: add unit test

export const generateExternalUserId = (first_name?: string, last_name?: string, date_of_birth?: string): string => {
    // Validate inputs
    if (!first_name || !last_name || !date_of_birth) {
        throw new Error('Missing required input fields');
    }

    const hash_hex: string = hash.sha256().update(first_name).update(last_name).update(date_of_birth).digest('hex');

    return `${EXTERNAL_ID_PREFIX}${ID_SEPARATOR}${hash_hex}`;
};

export const transformPatientObject = (values: PatientFormData): CreatePatient => {
    const new_patient: CreatePatient = {
        user: {
            personal: {
                first_name: values.first_name,
                last_name: values.last_name,
                date_of_birth: dayjs(values.date_of_birth).format('YYYY-MM-DD'),
                email: values.email,
                phone: values.phone,
            },
            contact: {
                street: values.street,
                zip: values.postal_code.toString(),
                city: values.city,
                // TODO: get this from locationiq
                country: 'Germany',
            },
            health_insurance: {
                insurance_type: values.cost_center,
            },
        },
        location_id: values.practice,
    };

    return new_patient;
};

export const preparePatientForStore = (selected_user: NormalizedPatient | undefined): Patient | undefined => {
    if (!selected_user) return undefined;

    return {
        ...(selected_user as Patient),
        user_id: selected_user.user_id || '',
        eterno_user_id: selected_user.is_registered
            ? selected_user.eterno_user_id
            : generateExternalUserId(
                  selected_user.name.first_name,
                  selected_user.name.last_name,
                  selected_user.account.date_of_birth
              ),
        is_registered: selected_user.is_registered,
    };
};

export const updateCurrentPatient = (patient: Patient | undefined) => {
    useBookingStore.setState({
        current_patient: patient,
    });
};
