import { PrismicDataType } from '@/models/PrismicHookTypes.ts';
import { Appointment, Priority } from '@/models/appointment/Appointment.ts';
import { ActionIcon, Divider } from '@mantine/core';
import { Pencil } from 'lucide-react';
import { Trash2 } from 'lucide-react';
import { FC, useMemo } from 'react';

import OverlaySection from '@/components/OverlaySection.tsx';
import PriorityMarker from '@/components/PriorityMarker.tsx';

import usePrismic from '@/hooks/usePrismic.tsx';

import { useAppointmentCheckinStore } from '@/stores/appointmentCheckinStore.ts';

import { dayjs } from '@/utils/dayjsSetup.ts';
import { getTextFromRichTextField } from '@/utils/prismicUtils.ts';

import Paragraph from '../text/Paragraph.tsx';
import PatientAppointmentActions from './PatientAppointmentActions.tsx';

interface Props {
    appointment: Appointment;
    onEdit: () => void;
}

const AppointmentDetails: FC<Props> = ({ appointment, onEdit }) => {
    const formatted_date = useMemo(() => dayjs(appointment.start).format('dddd, D MMMM YYYY'), [appointment]);
    const formatted_time = useMemo(() => dayjs(appointment.start).format('HH:mm'), [appointment]);
    const { data: professional_profiles } = usePrismic(PrismicDataType.PROFESSIONAL_PROFILE);
    const practitioner =
        getTextFromRichTextField(professional_profiles?.get(appointment.professional.key)?.display_name) ??
        appointment.professional.key;
    const appointment_checkin_store = useAppointmentCheckinStore();

    if (appointment_checkin_store.appointments[appointment.id]?.is_checking_in) {
        // TODO: GREYED OUT MODAL
    }

    return (
        <OverlaySection className="relative overflow-hidden p-0">
            <PriorityMarker priority={appointment.priority ?? Priority.NORMAL} />
            <div className="relative flex w-full flex-col gap-4 p-4">
                <div className="flex justify-between">
                    <div className="flex flex-col gap-1">
                        <div className="flex gap-2">
                            <Paragraph className="text-lg text-slate-500">
                                {appointment.name} - {appointment.duration.value} Min
                            </Paragraph>
                        </div>
                        <div className="flex items-center gap-2">
                            <Paragraph className="font-md">
                                {formatted_date} um {formatted_time} mit {practitioner}
                            </Paragraph>
                        </div>
                        <div className="flex items-center gap-2">
                            <Paragraph className="font-normal italic">{appointment.data.info}</Paragraph>
                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        <ActionIcon variant="transparent" aria-label="Settings" onClick={onEdit}>
                            <Trash2 className="size-5 cursor-pointer text-red-600" />
                        </ActionIcon>
                        <ActionIcon variant="transparent" aria-label="Settings" onClick={onEdit}>
                            <Pencil className="size-5 cursor-pointer text-black" />
                        </ActionIcon>
                    </div>
                </div>
                <Divider className="w-full" />
                <PatientAppointmentActions key={appointment.meta.updated_at} appointment={appointment} />
            </div>
        </OverlaySection>
    );
};

export default AppointmentDetails;
