import { authenticated_routes, unauthenticated_routes } from '@/routes.tsx';
import { FC } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import useAuth from '@/hooks/useAuth.ts';
import useCustomers from '@/hooks/useCustomers.ts';

import LoadingPage from '@/pages/LoadingPage.tsx';

import { useAuthStore } from '@/stores/authStore.ts';

const Router: FC = () => {
    const { auth_user } = useAuth();
    const is_user_set = useAuthStore((state) => state.is_user_set);
    useCustomers();

    const router = createBrowserRouter(auth_user ? authenticated_routes : unauthenticated_routes);

    if (!is_user_set) return <LoadingPage />;

    return <RouterProvider router={router} />;
};

export default Router;
