import { Accordion } from '@mantine/core';
import { ChevronDown } from 'lucide-react';
import { FC, ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

const CollapsableSection: FC<Props> = ({ children }) => {
    return (
        <Accordion
            multiple={false}
            chevron={<ChevronDown />}
            classNames={{
                control: 'px-4',
                content: 'px-0',
            }}
            className="flex flex-col gap-6"
            defaultValue={'Patient Informationen'}
        >
            {children}
        </Accordion>
    );
};
export default CollapsableSection;
