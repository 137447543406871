import { Insurance, Priority } from '@/models/appointment/Appointment.ts';
import { z } from 'zod';

// This is an easy way to prevent too long notes field. If exceeded we should provide a better solution.
const NOTES_FIELD_MAX_LENGTH = 1000 * 1000;

export const book_appointment_form_schema = z.object({
    practice_doc_cirrus_id: z.string().min(1), // location on doc_cirrus
    insurance: z.nativeEnum(Insurance),
    apt_type_id_or_schedule_type_dc_id: z.string().min(1),
    calendar_doc_cirrus_id: z.string().min(1),
    duration: z.number().positive(),
    date_time: z.string().datetime(),
    priority: z.nativeEnum(Priority),
    notes: z.string().max(NOTES_FIELD_MAX_LENGTH).optional(),
});
