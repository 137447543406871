import { Printer } from '@/models/EnrichedPrismicDocuments.ts';
import { Fetchers, KeyString, KeyStringArray, PrismicDataType, PrismicReturnType } from '@/models/PrismicHookTypes.ts';
import {
    ConfigAdminGroupsDocument,
    ConfigDcInstanceDocument,
    CustomerConfigDocument,
    HubDocument,
    ProfessionalProfileDocument,
} from '@/models/prismicTypes.ts';
import * as prismic from '@prismicio/client';
import { FilledContentRelationshipField, PrismicDocument, Query, RTNode, RTParagraphNode } from '@prismicio/client';

import { client } from '@/services/prismicService.ts';

import { filterCustomerConfigsByDomain } from '@/utils/customerUtils.ts';

export enum FeatureKey {
    CONSENT_FORMS = 'feature-consent-forms',
    ANAMNESIS_BUILDER = 'feature-anamnesis-builder',
    CUSTOM_ANAMNESIS = 'feature-custom-anamnesis',
    USER_MANAGEMENT = 'feature-user-management',
    ACCESS_REQUESTS = 'feature-access-requests',
}

const filterByEnvironmentTag = (prismic_data: PrismicDocument[]) => {
    return prismic_data.filter((entry: PrismicDocument) => entry.tags.includes(import.meta.env.VITE_ENVIRONMENT));
};

// Default fetcher to use when no custom fetcher is defined
export const defaultFetcher = async <T extends PrismicDataType>(type: T): Promise<PrismicReturnType[T]> => {
    const response = await client.getSingle(type, { lang: 'de-de' });
    console.debug('defaultFetcher call', {
        type,
        response,
    });
    return response.data as PrismicReturnType[T];
};

// Specific fetcher functions for different data types
export const fetchers: Fetchers = {
    [PrismicDataType.CONFIG_ADMIN_GROUPS]: async () => {
        const response: ConfigAdminGroupsDocument = await client.getSingle(PrismicDataType.CONFIG_ADMIN_GROUPS, {
            lang: 'en-us',
        });
        const groups: KeyStringArray = formatAdminGroups(response);

        return groups;
    },
    [PrismicDataType.CUSTOMER_CONFIG]: async () => {
        const response: Query<CustomerConfigDocument> = await client.get({
            filters: [prismic.filter.at('my.customer_config.environment', import.meta.env.VITE_ENVIRONMENT)],
            lang: 'de-de',
            pageSize: 200,
        });

        const filtered_configs: CustomerConfigDocument[] = filterCustomerConfigsByDomain(response.results);

        return filtered_configs.map((config: CustomerConfigDocument) => config.data);
    },
    [PrismicDataType.HUB]: async () => {
        const response: HubDocument[] = await client.getAllByType('hub', { lang: 'de-de' });

        const filtered_response = filterByEnvironmentTag(response) as HubDocument[];

        const hubs_obj = filtered_response.reduce(
            (obj, hub) => {
                obj[hub.id] = hub.data;
                return obj;
            },
            {} as { [key: string]: HubDocument['data'] }
        );

        return hubs_obj;
    },
    [PrismicDataType.CONFIG_DC_INSTANCE]: async () => {
        const response: ConfigDcInstanceDocument[] = await client.getAllByType('config_dc_instance', {
            lang: 'de-de',
        });

        const filtered_configs: ConfigDcInstanceDocument[] = filterByEnvironmentTag(
            response
        ) as ConfigDcInstanceDocument[];

        const configs_obj = filtered_configs.reduce(
            (obj, config: ConfigDcInstanceDocument) => {
                const hub_document_id: string = (config.data.hub as FilledContentRelationshipField<'hub'>)?.id;
                if (!hub_document_id) return obj;

                obj[hub_document_id] = config.data;
                return obj;
            },
            {} as { [key: string]: ConfigDcInstanceDocument['data'] }
        );

        return configs_obj;
    },
    [PrismicDataType.PROFESSIONAL_PROFILE]: async () => {
        const response: ProfessionalProfileDocument[] = await client.getAllByType('professional_profile', {
            lang: 'de-de',
        });

        const filtered_profiles: ProfessionalProfileDocument[] = filterByEnvironmentTag(
            response
        ) as ProfessionalProfileDocument[];

        return new Map(filtered_profiles.map((profile) => [profile.data.key?.[0]?.text ?? '', profile.data]));
    },
    // Add other fetcher functions for different data types here
};

// Type guard to check if a value is a PrismicDataType
export const isPrismicDataType = (value: string): value is PrismicDataType => {
    return Object.values(PrismicDataType).includes(value as PrismicDataType);
};

const formatAdminGroups = (object: ConfigAdminGroupsDocument): KeyStringArray => {
    return object?.data?.groups.reduce((obj, group) => {
        if (!group.group[0]) return obj;

        if (
            (import.meta.env.VITE_ENVIRONMENT === 'dev' && group.group[0].text.startsWith('prod-')) ||
            (import.meta.env.VITE_ENVIRONMENT === 'prod' && group?.group[0].text.startsWith('dev-'))
        ) {
            return obj;
        }

        // eslint-disable-next-line no-param-reassign
        if (!obj[group.group[0].text]) obj[group.group[0].text] = [];
        if (group.app_component_key[0]?.text) {
            obj[group.group[0].text].push(group.app_component_key[0].text);
        }
        return obj;
    }, {} as KeyStringArray);
};

export const getUserPermissions = (
    feature_access_groups: PrismicReturnType[PrismicDataType.CONFIG_ADMIN_GROUPS],
    user_groups: string[],
    locations: unknown[]
) => {
    const rendered_pages: KeyString = {};
    const feature_access: KeyString = {};

    user_groups?.forEach((group) => {
        feature_access_groups[group]?.forEach((key) => {
            if (locations.length === 0 && key === 'eterno-today-appointments') return;
            if (!key.startsWith('feature') && !rendered_pages[key]) rendered_pages[key] = key;
            if (key.startsWith('feature') && !feature_access[key]) feature_access[key] = key;
        });
    });

    return {
        rendered_pages,
        feature_access,
    };
};

export const getTextFromRichTextField = (textField: RTNode[] | RTNode | undefined): string => {
    if (!textField) return '';
    if (Array.isArray(textField)) {
        return (textField as RTParagraphNode[]).map((node) => node.text).join('');
    }

    return (textField as RTParagraphNode).text;
};

export const formatCustomerPrinters = (
    prismic_printers: ConfigDcInstanceDocument['data']['pcs_printers']
): Printer[] => {
    return prismic_printers.map((prismic_printer) => ({
        name: getTextFromRichTextField(prismic_printer.name),
        ip_address: getTextFromRichTextField(prismic_printer.address),
        certificate: getTextFromRichTextField(prismic_printer.certificate),
    }));
};
