import { Button, TextInput } from '@mantine/core';
import { useState } from 'react';

interface TicketNumberModalProps {
    onConfirm: (ticket: string) => Promise<void>;
    onCancel?: () => void;
}

const TicketInputModal = ({ onConfirm, onCancel }: TicketNumberModalProps) => {
    const [is_loading, setIsLoading] = useState(false);
    const [ticket, setTicket] = useState<string>('');

    const handleConfirm = async () => {
        if (!ticket) return;

        setIsLoading(true);
        await onConfirm(ticket);
        setIsLoading(false);
    };

    return (
        <div className="flex flex-col space-y-4">
            <TextInput
                label="Ticketnummer"
                type="string"
                value={ticket}
                onChange={(e) => setTicket(e.currentTarget.value)}
            />

            <div className="mt-6 flex space-y-2">
                <Button
                    variant="subtle"
                    color="gray"
                    className="w-full"
                    onClick={() => {
                        onCancel?.();
                    }}
                >
                    Abbrechen
                </Button>
                <Button
                    variant="filled"
                    className="w-full"
                    onClick={handleConfirm}
                    disabled={!ticket}
                    loading={is_loading}
                >
                    Weiter
                </Button>
            </div>
        </div>
    );
};

export default TicketInputModal;
