import { EnrichedLocation, Printer } from '@/models/EnrichedPrismicDocuments.ts';
import { EternoQuoteVariant } from '@/models/EternoQuoteVariant.ts';
import { Button } from '@mantine/core';
import { Pencil } from 'lucide-react';
import { FC, useEffect, useState } from 'react';

import { usePrinterStore } from '@/stores/printerStore.ts';

import BaseModal from './BaseModal.tsx';
import EternoQuote from './EternoQuote.tsx';
import RadioCard from './RadioCard.tsx';

interface Props {
    location: EnrichedLocation;
}

const CustomerPrinterSelection: FC<Props> = ({ location }) => {
    const [modal_opened, setModalOpened] = useState(false);
    const { selected_printer, setSelectedPrinter } = usePrinterStore();

    useEffect(() => {
        if (!selected_printer[location.key]) {
            setModalOpened(true);
        }
    }, [selected_printer, location.key]);

    const printers: Printer[] = location.config.pcs_printers;

    const handlePrinterSelect = (printerId: string) => {
        const printer = printers.find((printer) => printer.ip_address === printerId);
        if (printer) {
            setSelectedPrinter(location, printer);
        }
    };

    const handleSave = () => {
        setModalOpened(false);
    };

    const modal_footer = (
        <div className="flex w-full justify-between">
            <Button variant="outline" onClick={() => setModalOpened(false)}>
                Abbrechen
            </Button>
            <Button onClick={handleSave}>Speichern</Button>
        </div>
    );

    return (
        <>
            <Button
                className="color-slate-700 color-slate-500 border-slate-300"
                variant="outline"
                onClick={() => setModalOpened(true)}
                rightSection={<Pencil className="size-4" />}
            >
                {selected_printer[location.key] ? selected_printer[location.key]?.name : 'Select Printer'}
            </Button>
            <BaseModal
                opened={modal_opened}
                onClose={() => setModalOpened(false)}
                title="Drucker auswählen"
                size="lg"
                footer={modal_footer}
            >
                <div className="flex flex-col gap-4 p-2">
                    {!selected_printer[location.key] && (
                        <EternoQuote
                            variant={EternoQuoteVariant.WARNING}
                            text="Es sieht so aus, als háten Sie keinen Drucker ausgewählt."
                        />
                    )}
                    <div className="flex gap-4">
                        {printers.map((printer) => (
                            <RadioCard
                                key={printer.ip_address}
                                value={printer.ip_address}
                                label={printer.name}
                                checked={selected_printer[location.key]?.ip_address === printer.ip_address}
                                onChange={handlePrinterSelect}
                            />
                        ))}
                    </div>
                </div>
            </BaseModal>
        </>
    );
};

export default CustomerPrinterSelection;
