import { FuseResultMatch } from '@/models/Fuse.ts';
import { IndicatorStatus } from '@/models/IndicatorStatus.ts';
import { NormalizedPatient } from '@/models/Patient.ts';
import { Divider, Pill } from '@mantine/core';
import { clsx } from 'clsx';
import { FC, useMemo } from 'react';

import StatusLamp from '@/components/StatusLamp.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import { dayjs } from '@/utils/dayjsSetup.ts';

interface Props {
    patient: NormalizedPatient;
    matches: FuseResultMatch[];
}

const PatientRow: FC<Props> = ({ patient, matches }) => {
    const formatted_date = useMemo(() => dayjs(patient.account.date_of_birth).format('DD.MM.YYYY'), [patient]);

    const {
        is_first_name_highlighted,
        is_last_name_highlighted,
        is_dob_highlighted,
        is_patient_number_highlighted,
        is_email_highlighted,
    } = useMemo(
        () => ({
            is_first_name_highlighted: matches.some(
                (match: FuseResultMatch) => match.value === patient._normalized.first_name
            ),
            is_last_name_highlighted: matches.some(
                (match: FuseResultMatch) => match.value === patient._normalized.last_name
            ),
            is_dob_highlighted: matches.some(
                (match: FuseResultMatch) => match.value === patient._normalized.date_of_birth
            ),
            is_patient_number_highlighted: matches.some(
                (match: FuseResultMatch) => match.value === patient._normalized.patient_number
            ),
            is_email_highlighted: matches.some((match: FuseResultMatch) => match.value === patient.primary_email),
        }),
        [patient, matches]
    );

    return (
        <div className="flex flex-col gap-5" onMouseEnter={() => console.debug(matches)}>
            <div className="flex flex-row">
                <div className="w-1/4">
                    <Paragraph className="w-fit">
                        <span className={clsx('p-0.5', is_first_name_highlighted && 'bg-amber-100')}>
                            {patient.name.first_name}
                        </span>
                        <span className={clsx('p-0.5', is_last_name_highlighted && 'bg-amber-100')}>
                            {patient.name.last_name}
                        </span>
                    </Paragraph>
                </div>
                <div className="flex w-1/4 flex-col">
                    <StatusLamp status={IndicatorStatus.INACTIVE} text="asd" />
                    <StatusLamp status={IndicatorStatus.ACTIVE} text="asd" />
                </div>
                {patient.insurances && (
                    <div className="flex w-1/4 flex-col">
                        {/*TODO use id*/}
                        {patient.insurances.map((insurance) => (
                            <Paragraph key={insurance.id}>{insurance.name}</Paragraph>
                        ))}
                    </div>
                )}
                <div className="w-1/4">
                    <Paragraph className={clsx('w-fit p-0.5', is_dob_highlighted && 'bg-amber-100')}>
                        {formatted_date}
                    </Paragraph>
                </div>
            </div>
            <div className="flex flex-col gap-2">
                <Divider className="" />
                <div className="flex items-center justify-between">
                    <Pill className={clsx('text-xs', is_patient_number_highlighted && 'bg-amber-100')}>
                        {patient.account.patient_number}
                    </Pill>
                    {patient.primary_email && (
                        <Pill className={clsx('text-xs', is_email_highlighted && 'bg-amber-100')}>
                            {patient.primary_email}
                        </Pill>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PatientRow;
