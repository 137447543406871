import { EnrichedLocation, Printer } from '@/models/EnrichedPrismicDocuments.ts';
import { Appointment } from '@/models/appointment/Appointment.ts';
import { Practitioner } from '@/models/appointment/EnrichedPractitionerData.ts';
import { UserAppointmentDto } from '@/models/call-system/dtos/user-appointment-dto.ts';
import { CheckinStatus, CheckoutStatus } from '@/models/call-system/models/CallSystemUser.ts';

import { getExtAuthToken } from '@/services/callSystemService.ts';
import { printPcsTicket, updatePcsTicket } from '@/services/printerService.ts';

import { usePrinterStore } from '@/stores/printerStore.ts';

import { isEternoAppointment } from '@/utils/appointmentUtils.ts';

export const loadPrinterToken = async (location: EnrichedLocation) => {
    let { printer_token } = usePrinterStore.getState();

    if (
        !printer_token?.token ||
        printer_token.instanceId !== location.config.instance_id ||
        Date.now() > printer_token.validUntil
    ) {
        const [success, new_token] = await getExtAuthToken(location.config.instance_id);
        if (!success || !new_token) {
            return null;
        }

        printer_token = {
            token: new_token,
            validUntil: Date.now() + 3600000, // 1 hour
            instanceId: location.config.instance_id,
        };

        usePrinterStore.setState({ printer_token });
    }

    return printer_token?.token;
};

export const prepareCheckinData = (
    appointment: Appointment,
    location: EnrichedLocation,
    ticket: string,
    checkin_status: CheckinStatus,
    checkout_status?: CheckoutStatus
): UserAppointmentDto => {
    const is_eterno_appointment = isEternoAppointment(appointment);

    const checkin_data: UserAppointmentDto = {
        group: location.key,
        number: ticket,
        name: appointment.name,
        user_id: is_eterno_appointment ? appointment.user_id : (appointment.links.patient_id ?? appointment.user_id),
        location_key: location.key,
        appointment: {
            id: appointment.id,
            checkin_status: checkin_status,
            checkout_status: checkout_status,
        },
        instance_id: location.config.instance_id,
        appointment_id: appointment.id,
    };
    return checkin_data;
};

export const printTicket = async (
    location: EnrichedLocation,
    selected_printer: Printer
): Promise<[boolean, null | string]> => {
    const auth_token = await loadPrinterToken(location);
    if (!auth_token) return [false, null];

    const [success, ticket] = await printPcsTicket(
        auth_token,
        selected_printer.ip_address ??
            location.config.pcs_printers?.[0]?.ip_address ??
            location.config.patient_call_system_ip[0]?.text,
        selected_printer.certificate ?? location.config.pcs_printers?.[0]?.certificate
    );

    if (success) {
        return [success, ticket as string];
    } else {
        return [success, null];
    }
};

export const updatePcs = async (
    location: EnrichedLocation,
    selected_printer: Printer,
    ticket: string,
    practitioner?: Practitioner
): Promise<[boolean, undefined | string]> => {
    const auth_token = await loadPrinterToken(location);
    if (!auth_token) return [false, undefined];

    const update_pcs_response = await updatePcsTicket(
        auth_token,
        selected_printer.ip_address ??
            location.config.pcs_printers?.[0]?.ip_address ??
            location.config.patient_call_system_ip[0]?.text,
        practitioner?.pcs_stack_id || 'zimmer01',
        ticket
    );

    return [!!update_pcs_response, update_pcs_response ? ticket : undefined];
};
