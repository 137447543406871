import { Bell, Calendar, List } from 'lucide-react';
import { Navigate, Outlet } from 'react-router-dom';

import CalendarViewPage from '@/pages/CalendarViewPage.tsx';
import ListViewPage from '@/pages/ListViewPage.tsx';
import LoginPage from '@/pages/LoginPage.tsx';
import MagicLinkPage from '@/pages/MagicLinkPage.tsx';
import PlaygroundPage from '@/pages/PlaygroundPage.tsx';
import RoomViewPage from '@/pages/RoomViewPage.tsx';

import OfficeAppShell from './components/OfficeAppShell.tsx';

export const authenticated_routes = [
    {
        path: '/',
        element: (
            <OfficeAppShell>
                <Outlet />
            </OfficeAppShell>
        ),
        children: [
            {
                path: '',
                element: <RoomViewPage />,
                name: 'Room view',
                key: 'room-view',
                icon: <Bell />,
            },
            {
                path: 'list-view',
                element: <ListViewPage />,
                name: 'List View',
                key: 'list-view',
                icon: <List />,
            },
            {
                path: 'calendar-view',
                element: <CalendarViewPage />,
                name: 'Calendar View',
                key: 'calendar-view',
                icon: <Calendar />,
            },
            {
                path: 'playground',
                element: <PlaygroundPage />,
            },
            {
                path: '*',
                element: <Navigate to="/" replace />,
            },
        ],
    },
];

export const unauthenticated_routes = [
    {
        path: '/',
        element: <LoginPage />,
    },
    {
        path: '/magic-link',
        element: <MagicLinkPage />,
    },
    {
        path: '*',
        element: <Navigate to="/" replace />,
    },
];
